import React, { useState, useEffect } from "react";
import { Table, Tag, Space, Avatar, Input, Button, message } from "antd";
import { SearchOutlined, UserOutlined, CalendarOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import AdminLayout from "../AdminLayout/AdminLayout";
import { useGetHospitalIdFromUserIdQuery } from "../../../redux/api/hospitalApi";
import { useGetDoctorsQuery } from "../../../redux/api/doctorApi";
import { getUserIdFromToken } from "../../../utils/local-storage";

const Doctors = () => {
  const [doctors, setDoctors] = useState([]);
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const userId = getUserIdFromToken();

  const { data: hospitalData, isLoading: hospitalLoading } = useGetHospitalIdFromUserIdQuery(userId);
  const hospitalId = hospitalData?.id;

  const {
    data: doctorsData,
    isLoading: doctorsLoading,
    error: doctorsError,
  } = useGetDoctorsQuery({ id: hospitalId }, { skip: !hospitalId });

  useEffect(() => {
    if (doctorsData?.doctors) {
      setDoctors(doctorsData.doctors);
    }

    if (doctorsError) {
      message.error("Error fetching Providers. Please try again later.");
    }
  }, [doctorsData, doctorsError]);

  const columns = [
    {
      title: "Provider",
      dataIndex: "firstName",
      key: "name",
      render: (text, record) => (
        <Space>
          <Avatar
            size="large"
            icon={<UserOutlined />}
            src={record.img || "https://www.svgrepo.com/show/429791/doctor-human.svg"}
          />
          <span>{`${record.firstName} ${record.lastName}`}</span>
        </Space>
      ),
      filteredValue: [searchText],
      onFilter: (value, record) => {
        const searchLower = value.toLowerCase();
        return (
          `${record.firstName} ${record.lastName}`.toLowerCase().includes(searchLower) ||
          record.email.toLowerCase().includes(searchLower) ||
          record.specialization?.toLowerCase().includes(searchLower)
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Specialization",
      dataIndex: "specialization",
      key: "specialization",
      render: (specialization) => (
        <Tag color="geekblue">{specialization || "Not specified"}</Tag>
      ),
    },
    {
      title: "Experience",
      dataIndex: "experience",
      key: "experience",
      render: (experience) => `${experience} years`,
    },
    {
      title: "Status",
      key: "status",
      render: (_, record) => (
        <Tag color={record.verified ? "green" : "orange"}>
          {record.verified ? "Verified" : "Pending"}
        </Tag>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space size="middle">
          <Button type="primary" onClick={() => navigate(`/doctors/profile/${record.id}`)}>
            View Profile
          </Button>
          <Button type="default" icon={<CalendarOutlined />} onClick={() => navigate(`/admin/dashboard/schedule/${record.id}`)}>
            Appointments
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <AdminLayout>
      <div style={{ padding: "20px" }}>
        <h1 style={{ marginBottom: "20px" }}>Providers</h1>
        <Input
          placeholder="Search Providers.."
          onChange={(e) => setSearchText(e.target.value)}
          style={{ marginBottom: "20px", width: "300px" }}
          prefix={<SearchOutlined />}
        />
        <Table
          columns={columns}
          dataSource={doctors}
          loading={hospitalLoading || doctorsLoading}
          rowKey="id"
          pagination={{ pageSize: 10 }}
        />
      </div>
    </AdminLayout>
  );
};

export default Doctors;