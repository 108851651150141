import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaStar } from 'react-icons/fa';
import { useGetPatientAppointmentsQuery } from '../../../redux/api/appointmentApi';
import { useCreateReviewMutation } from '../../../redux/api/reviewsApi';
import { getUserInfo } from '../../../service/auth.service';
import './feedback.css';
import { message } from 'antd';

const Feedback = () => {
  const { appointmentId , id} = useParams();
  const [description, setDescription] = useState('');
  const [star, setStar] = useState(0);
  const [isRecommended, setIsRecommended] = useState(false);
  const user = getUserInfo();
const navigate = useNavigate()
  const { data: appointmentData, isLoading: isAppointmentLoading, isError: isAppointmentError } = useGetPatientAppointmentsQuery({
    patientId: user.userId,
    hospitalId: 'hospitalIdFromContext' // Replace with actual hospital ID from context
  });

  const [createReview, { isLoading: isReviewLoading, isSuccess, isError, error }] = useCreateReviewMutation();

  const currentAppointment = appointmentData?.find(app => app.id === appointmentId);

  useEffect(() => {
    if (isSuccess) {
      // Handle success (e.g., show a success message, redirect)
      navigate(`/dashboard/${id}`)
      message.success('Review submitted successfully!');
      // Reset form
      setDescription('');
      setStar(0);
      setIsRecommended(false);
    }
  }, [isSuccess]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!currentAppointment) return;

    const reviewData = {
      doctorId: currentAppointment.doctorId,
      patientId: currentAppointment.patientId,
      description,
      star: star.toString(),
      isRecommended
    };

    createReview({ data: reviewData });
  };

  if (isAppointmentLoading) return <div className="feedback-container">Loading appointment data...</div>;
  if (isAppointmentError) return <div className="feedback-container">Error loading appointment data. Please try again.</div>;
  if (!currentAppointment) return <div className="feedback-container">Appointment not found. Please check the appointment ID.</div>;

  return (
    <div className="feedback-container">
      <h2 className="feedback-title">Your Feedback Matters</h2>
      <form onSubmit={handleSubmit} className="feedback-form">
        <div className="form-group">
          <label htmlFor="description" className="form-label">Share your experience</label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="form-textarea"
            rows="4"
            placeholder="Tell us about your visit..."
            required
          />
        </div>

        <div className="form-group">
          <label className="form-label">Rate your experience</label>
          <div className="star-rating">
            {[1, 2, 3, 4, 5].map((value) => (
              <button
                key={value}
                type="button"
                onClick={() => setStar(value)}
                className={`star-btn ${star >= value ? 'active' : ''}`}
              >
                <FaStar />
              </button>
            ))}
          </div>
        </div>

        <div className="form-group checkbox-group">
          <input
            id="isRecommended"
            type="checkbox"
            checked={isRecommended}
            onChange={(e) => setIsRecommended(e.target.checked)}
          />
          <label htmlFor="isRecommended" className="form-label">
            I recommend this doctor
          </label>
        </div>

        <button
          type="submit"
          disabled={isReviewLoading}
          className="submit-btn"
        >
          {isReviewLoading ? 'Submitting...' : 'Submit Review'}
        </button>
      </form>

      {isError && (
        <div className="error-message">
          Error submitting review: {error.message}
        </div>
      )}
    </div>
  );
};

export default Feedback;