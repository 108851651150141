import { setUserInfo } from "../../utils/local-storage";
import { baseApi } from "./baseApi";

const AUTH_URL = "/auth";

export const authApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    userLogin: build.mutation({
      query: (loginData) => ({
        url: `${AUTH_URL}/login`,
        method: "POST",
        data: loginData,
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const result = (await queryFulfilled).data;
          console.log("result from react-query", result);
          setUserInfo({ accessToken: result.accessToken });
        } catch (error) {}
      },
    }),
    patientSignUp: build.mutation({
      query: (data) => ({
        url: `/patient`,
        method: "POST",
        data,
      }),
    }),
    verifyUser: build.mutation({
      query: (data) => ({
        url: `${AUTH_URL}/user/verify/${data.userId}/${data.token}`,
        method: "POST",
      }),
    }),
    authDoctor: build.mutation({
      query: (data) => ({
        url: `${AUTH_URL}/doctor/verify/${data.userId}/${data.token}`,
        method: "POST",
      }),
    }),
    doctorLogin: build.mutation({
      query: (data) => ({
        url: `${AUTH_URL}/verify/doctor`,
        method: "POST",
        data,
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const result = (await queryFulfilled).data;
          console.log("result from react-query", result);
          setUserInfo({ accessToken: result.accessToken });
        } catch (error) {}
      },
    }),

    adminSignUp: build.mutation({
      query: (data) => ({
        url: `/admin/signup`,
        method: "POST",
        data,
      }),
    }),
    resetPassword: build.mutation({
      query: (data) => ({
        url: `${AUTH_URL}/reset-password`,
        method: "POST",
        data,
      }),
    }),
    changePassword: build.mutation({
      query: (data) => ({
        url: `${AUTH_URL}/change-password`,
        method: "POST",
        data,
      }),
    }),
    resetConfirm: build.mutation({
      query: (data) => ({
        url: `${AUTH_URL}/reset-password/confirm`,
        method: "POST",
        data,
      }),
    }),
  }),
});

export const {
  useChangePasswordMutation,
  useUserLoginMutation,
  useVerifyUserMutation,
  useAuthDoctorMutation,
  useAdminSignUpMutation,
  usePatientSignUpMutation,
  useResetPasswordMutation,
  useResetConfirmMutation,
  useDoctorLoginMutation,
} = authApi;
