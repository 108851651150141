import { loadStripe } from "@stripe/stripe-js";

const getStripe = () => {
  console.log(
    "process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY",
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );
  return stripePromise;
};

export default getStripe;
