import { Outlet, useNavigate, useParams } from "react-router-dom";
import { getUserInfo, loggedOut } from "../../service/auth.service";
import { useEffect } from "react";
import { useGetDoctorQuery } from "../../redux/api/doctorApi";
import { message } from "antd";
import { useGetSingleHospitalQuery } from "../../redux/api/hospitalApi";
import { useGetPatientQuery } from "../../redux/api/patientApi";

const PrivateOutletCommon = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const localAuth = getUserInfo();

  useEffect(() => {
    if (!localAuth) {
      navigate(`hospital/${id}`, { replace: true });
      return;
    }

    if (localAuth.role !== "doctor" && localAuth.role !== "patient") {
      console.log("Unauthorized role, redirecting...");
      navigate(`hospital/${id}`, { replace: true });
      return;
    }

    if (localAuth.hospitalId === "HOSPITALID_NOT_NEEDED_FOR_ADMIN") {
      message.error("Admin cannot access this route");
      navigate(`/error`, { replace: true });
      return;
    }

    if (id !== localAuth.hospitalId) {
      message.error("Wrong Hospital URL");
      navigate(`/error`, { replace: true });
      return;
    }
  }, [navigate, id, localAuth]);

  return <Outlet />;
};

export default PrivateOutletCommon;
