import { useState, useEffect } from 'react';

export default function useSubdomain() {
  const [hospitalId, setHospitalId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [subdomain, setSubDomain] = useState(null);
  const apiurl = process.env.REACT_APP_API_BASE_URL;
  useEffect(() => {
    async function fetchHospitalId() {
      try {
        const sd = window.location.hostname.split('.')[0];
        // const sd = "chitkara";
        setSubDomain(sd);
        if(sd !== "averyshub"){
          const response = await fetch(`${apiurl}/hospital/getHospitalId/domain/${sd}`);
          const res = await response.json();
          setHospitalId(res.data.id);
        }
      } catch (error) {
        console.error(error);
        setError(error);
      } finally {
        setLoading(false);
      }
    }

    fetchHospitalId();
  }, []);

  return { hospitalId, loading, error, subdomain };
}