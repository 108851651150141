import moment from "moment";
import img from "../../../images/avatar.jpg";
import { Link } from "react-router-dom";
import "./BookingCheckout.css";
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useCreateAppointmentPaymentMutation } from "../../../redux/api/appointmentApi";
import { message, Spin } from "antd";
import { paymentConstants } from "../../../constant/payment";
import { useRef, useState } from "react";
import { Spinner } from "react-bootstrap";

const CheckoutPage = ({
  isCheck,
  setIsChecked,
  data,
  selectedDate,
  selectTime,
  onPaymentSuccess,
  selectValue,
  solutionPrice,
}) => {
  const {
    paymentAddress,
    paymentCountry,
    paymentCountryCode,
    paymentEmail,
    paymentName,
    paymentPostalCode,
    paymentState,
  } = selectValue;

  const stripe = useStripe();
  const elements = useElements();
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const [
    createAppointmentPayment,
    { isLoading, isError, isSuccess, data: paymentData },
  ] = useCreateAppointmentPaymentMutation();
  const payBtn = useRef(null);

  const price = solutionPrice || Number(data.price);
  const vat = (15 / 100) * price;
  const totalAmount = price + paymentConstants.bookingFee + vat;

  const handleCheck = () => {
    setIsChecked(!isCheck);
  };

  const submitHandler = async (e) => {
    setIsLoaderActive(true);
    e.preventDefault();
    try {
      if (!isCheck) {
        throw new Error("Please check the terms and conditions");
      }
      if (
        !paymentAddress ||
        !paymentCountry ||
        !paymentCountryCode ||
        !paymentEmail ||
        !paymentName ||
        !paymentPostalCode ||
        !paymentState
      ) {
        throw new Error("Insufficient Credentials");
      }
      const payload = {
        amount: totalAmount,
        currency: "usd",
        description: "An SaaS platform for Hospitals and Doctors and Patients",
      };

      const respData = await createAppointmentPayment(payload);

      if (
        !stripe ||
        !elements ||
        !respData.data.success ||
        !respData.data.client_secret
      )
        throw new Error("Failed to initiate payment");

      const client_secret = respData.data.client_secret;
      const result = await stripe.confirmCardPayment(client_secret, {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name: paymentName,
            email: paymentEmail,
            address: {
              line1: paymentAddress,
              state: paymentState,
              postal_code: paymentPostalCode,
              country: paymentCountryCode,
            },
          },
        },
      });

      if (result.error) {
        payBtn.current.disabled = false;
        message.error(result.error.message);
      } else if (result && result.paymentIntent.status === "succeeded") {
        const paymentDetails = {
          paymentMethod: result.paymentIntent.payment_method,
          paymentType: result.paymentIntent.payment_method_types[0],
          paymentDate: new Date(result.paymentIntent.created * 1000),
          transactionId: result.paymentIntent.id,
          paymentStatus: "paid",
          totalAmount: result.paymentIntent.amount,
          currency: result.paymentIntent.currency,
          doctorFee: price,
          bookingFee: paymentConstants.bookingFee,
          paymentIntentId: result.paymentIntent.id,
          vat: vat,
          created: new Date(
            result.paymentIntent.created * 1000
          ).toLocaleString(),
        };
        onPaymentSuccess(paymentDetails);
        message.success("Payment successful! Your appointment is confirmed.");
        setIsLoaderActive(false);
      } else {
        setIsLoaderActive(false);
        message.error("There's an issue while processing the payment.");
      }
    } catch (error) {
      if (error.message === "Please check the terms and conditions") {
        message.error(error.message);
        setIsLoaderActive(false);
        return;
      }
      setIsLoaderActive(false);
      console.log("error message", error.message, error);
      message.error("Invalid credential");
    }
  };

  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-md-7 col-sm-12">
          <form className="paymentForm" onSubmit={submitHandler}>
            <h2 className="text-center mb-4">Card Information</h2>
            <div className="form-group">
              <label>Card Number</label>
              <CardNumberElement className="paymentInput" />
            </div>
            <div className="form-group">
              <label>Expiry Date</label>
              <CardExpiryElement className="paymentInput" />
            </div>
            <div className="form-group">
              <label>CVC</label>
              <CardCvcElement className="paymentInput" />
            </div>
            <div className="terms-accept">
              <div className="custom-checkbox">
                <input
                  type="checkbox"
                  id="terms_accept"
                  className="me-2"
                  checked={isCheck}
                  onChange={handleCheck}
                />
                <label htmlFor="terms_accept">
                  {" "}
                  I have read and accept{" "}
                  <a
                    className="text-primary"
                    style={{ cursor: "pointer", textDecoration: "none" }}
                  >
                    Terms &amp; Conditions
                  </a>
                </label>
              </div>
            </div>
            <button
              type="submit"
              ref={payBtn}
              className={`paymentFormBtn ${!isCheck ? "faded" : ""}`}
              disabled={!isCheck || isLoading || isLoaderActive}
            >
              {isLoading || isLoaderActive ? (
                <Spinner animation="border" variant="info" />
              ) : (
                `Pay - $${totalAmount.toFixed(2)}`
              )}
            </button>
          </form>
        </div>

        <div className="col-md-5 col-sm-12">
          <div className="booking-summary">
            {data && (
              <Link
                to={`/doctors/profile/${data?.id}`}
                className="booking-doc-img d-flex justify-content-center mb-3"
              >
                <img
                  src={data?.img || img}
                  alt="Provider"
                  className="rounded-circle"
                />
              </Link>
            )}
            <div className="text-center">
              <h4 className="doc-title mb-1">
                Dr. {data?.firstName} {data?.lastName}
              </h4>
              <p className="form-text mb-1">{data?.designation}</p>
              <p className="form-text mb-3">{data?.clinicAddress}</p>
            </div>
            <div className="booking-item-wrap">
              <ul className="booking-date">
                <li>
                  Date: <span>{moment(selectedDate).format("LL")}</span>
                </li>
                <li>
                  Time: <span>{selectTime}</span>
                </li>
              </ul>
              <ul className="booking-fee">
                <li>
                  {solutionPrice ? "Solution" : "Consulting"} Fee:{" "}
                  <span>${price.toFixed(2)}</span>
                </li>
                <li>
                  Booking Fee: <span>${paymentConstants.bookingFee.toFixed(2)}</span>
                </li>
                <li>
                  Vat (15%): <span>${vat.toFixed(2)}</span>
                </li>
              </ul>
              <ul className="booking-total">
                <li>
                  Total: <span className="total-cost">${totalAmount.toFixed(2)}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutPage;