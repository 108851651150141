import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DoctorDashCard from "./doctor/DoctorDashCard";
import useAuthCheck from "../../../redux/hooks/useAuthCheck";
import DashboardLayout from "../DashboardLayout/DashboardLayout";
import DashboardPage from "./doctor/DashboardPage";
import PatientDashboard from "./PatientDashboard";

const Dashboard = () => {
  const auth = useAuthCheck();
  const { id } = useParams();
  const navigate = useNavigate();
  const [isAuthorized, setIsAuthorized] = useState(false);

  // useEffect(() => {
  //   if (!auth.isLoading) {
  //     if (!auth.role) {
  //       // User is not authenticated, redirect to login
  //       navigate(`/hospital/${id}`);
  //     } else {
  //       setIsAuthorized(true);
  //     }
  //   }
  // }, [auth.isLoading, auth.role, navigate, id]);

  // if (auth.isLoading || !isAuthorized) {
  //   return <div>Loading...</div>; // Or a more sophisticated loading component
  // }
console.log(auth);
  return (
    <DashboardLayout>
      {auth.role === "doctor" && <DoctorDashCard doctorId={auth.data?.id} />}
      <div className="row">
        {auth.role === "patient" && (
          <div
            className="col-md-12 rounded"
            style={{ background: "#f8f9fa" }}
          >
            <h5 className="text-title my-3">My Appointments </h5>
            <PatientDashboard />
          </div>
        )}
        {auth.role === "doctor" && (
          <div
            className="col-md-12 rounded"
            style={{ background: "#f8f9fa" }}
          >
            <h5 className="text-title py-3">Appointments</h5>
            <DashboardPage />
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};

export default Dashboard;