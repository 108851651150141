import {
  FaBriefcase,
  FaRegClock,
  FaLink,
} from "react-icons/fa";
import "./SelectDateAndTime.css";

// Helper function to format dates
const formatDate = (date, options) => {
  return new Intl.DateTimeFormat('en-US', options).format(date);
};

// Helper function to add days to a date
const addDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

// Helper function to get the current time in a specific timezone (approximate, not exact)
const getCurrentTimeInZone = (timeZone) => {
  const date = new Date();
  const options = {
    timeZone: timeZone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  };
  const formatter = new Intl.DateTimeFormat([], options);
  const parts = formatter.formatToParts(date);
  const timeZoneDate = new Date(`${parts.find(part => part.type === 'year').value}-${parts.find(part => part.type === 'month').value}-${parts.find(part => part.type === 'day').value}T${parts.find(part => part.type === 'hour').value}:${parts.find(part => part.type === 'minute').value}:${parts.find(part => part.type === 'second').value}`);
  return timeZoneDate;
};

const SelectDateAndTime = ({
  content,
  handleDateChange,
  selectedDate,
  selectTime,
  dContent,
  handleTimeChange,
}) => {
  console.log("selectedDate:", selectedDate);

  const nowInIST = getCurrentTimeInZone("Asia/Kolkata");

  return (
    <div style={{ marginTop: "5rem" }}>
      <div className="p-3" style={{ background: "#f8f9fa" }}>
        <div className="row">
          <div className="col-md-3 col-sm-12 mt-3 info-part border-end">
            <div>
              <h5 className="text-title">Selected Provider</h5>
              {content}
            </div>
            <p className="py-2 border-bottom info-head-date">
              Would you like to schedule an appointment? Pick a Date & Time
            </p>
            <div className="icon-box">
              <div className="d-flex gap-3">
                <FaBriefcase className="icon" />
                <p>With Provider</p>
              </div>
              <div className="d-flex gap-3">
                <FaRegClock className="icon" />
                <p>15 Min</p>
              </div>
              <div className="d-flex gap-3">
                <FaLink className="icon" />
                <p>Online Meeting</p>
              </div>
              <div className="d-flex gap-3">
                <p>
                  {selectedDate &&
                    selectTime &&
                    `${formatDate(new Date(selectedDate), { year: 'numeric', month: 'long', day: 'numeric' })} ${selectTime}`}
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-5 col-sm-12 mt-3 border-end">
            <p className="py-2 border-bottom info-head-date text-xl">
              {selectedDate
                ? `Selected - ${formatDate(new Date(selectedDate), { year: 'numeric', month: 'long', day: 'numeric' })}`
                : "Pick a Date"}
            </p>
            <div className="info-date-card row">
              {Array.from({ length: 7 }, (_, i) => {
                const item = addDays(nowInIST, i);
                return (
                  <div
                    key={i}
                    className="mb-3 col-md-6"
                    onClick={() => handleDateChange(item)}
                  >
                    <div
                      className={`p-3 mb-3 rounded text-center select-date ${
                        item.toISOString().split('T')[0] === (selectedDate ? new Date(selectedDate).toISOString().split('T')[0] : '')
                          ? "active"
                          : ""
                      }`}
                    >
                      <div className="select-month">
                        {formatDate(item, { year: 'numeric', month: 'long' })}
                      </div>
                      <div className="select-day-num">
                        {formatDate(item, { day: 'numeric' })}
                      </div>
                      <div className="select-month">
                        {formatDate(item, { weekday: 'long' })}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-md-4 col-sm-12 mt-3">
            {!selectedDate && <h5 className="text-title mb-3">Pick a Time</h5>}
            {selectedDate && (
              <h5 className="text-sm mb-3">
                Selected Date: {formatDate(new Date(selectedDate), { year: 'numeric', month: 'long', day: 'numeric' })}
                {selectTime && ` Time: ${selectTime}`}
              </h5>
            )}
            <div className="date-card rounded p-3">
              <div className="row text-center mt-3">
                {!selectedDate ? (
                  <h5 className="text-title d-flex justify-content-center align-items-center mt-5">
                    Please Select A Date First
                  </h5>
                ) : (
                  <>{dContent}</>
                )}
              </div>
            </div>
            <div className="row text-center mt-3">
              <div className="d-flex flex-wrap justify-content-center">
                {dContent &&
                  dContent.pmTimeSlot &&
                  dContent.pmTimeSlot
                    .filter((item) => {
                      const selectedDateInIST = new Date(`${formatDate(new Date(selectedDate), { year: 'numeric', month: '2-digit', day: '2-digit' })} ${item}`);
                      return selectedDateInIST > nowInIST;
                    })
                    .map((item, id) => (
                      <div className="p-2" key={id}>
                        <button
                          className={`btn ${
                            item === selectTime
                              ? "btn-primary"
                              : "btn-outline-secondary"
                          }`}
                          onClick={() => handleTimeChange(item)}
                          style={{ minWidth: "100px", marginBottom: "0.5rem" }}
                        >
                          {item}
                        </button>
                      </div>
                    ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectDateAndTime;
