import { FaCloudUploadAlt } from "react-icons/fa";
import "./ImageUpload.css";

const ImageUpload = ({ setSelectedImage, setFile }) => {
  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const reader = new FileReader();
      const file = files[0];
      setFile(file);
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="image-upload-container">
      <label htmlFor="upload-input" className="upload-button">
        <FaCloudUploadAlt className="icon" />
        Upload Photo
      </label>
      <input
        id="upload-input"
        type="file"
        className="upload-input"
        onChange={handleFileChange}
      />
      <div className="file-info">
        <small className="form-text text-muted">
          Allowed JPG, GIF or PNG. Max size of 2MB
        </small>
      </div>
    </div>
  );
};

export default ImageUpload;