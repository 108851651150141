import React from "react";
import { useForm } from "react-hook-form";
import CountryStateSelector from "../CountryStateSelector/CountryStateSelector";
import "./PaymentInfoComponent.css"; // Import the CSS file

const PaymentInfoComponent = ({ handleChange, selectValue }) => {
  const {
    paymentAddress,
    paymentCountry,
    paymentCountryCode,
    paymentEmail,
    paymentName,
    paymentPostalCode,
    paymentState,
  } = selectValue;


  return (
    <form className="payment-info-form">
      <div className="form-group">
        <label htmlFor="paymentName">Name</label>
        <input
          id="paymentName"
          name="paymentName"
          value={paymentName || ""}
          type="text"
          onChange={handleChange}
          placeholder="Enter your name"
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label htmlFor="paymentEmail">Email</label>
        <input
          id="paymentEmail"
          name="paymentEmail"
          value={paymentEmail || ""}
          type="text"
          onChange={handleChange}
          placeholder="Enter your email"
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label htmlFor="paymentAddress">Address</label>
        <input
          id="paymentAddress"
          name="paymentAddress"
          value={paymentAddress || ""}
          type="text"
          onChange={handleChange}
          placeholder="Enter your address"
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label htmlFor="paymentPostalCode">Postal Code</label>
        <input
          id="paymentPostalCode"
          name="paymentPostalCode"
          value={paymentPostalCode || ""}
          type="text"
          onChange={handleChange}
          placeholder="Enter your postal Code"
          className="form-control"
        />
      </div>
      <div className="form-group">
        <CountryStateSelector handleChange={handleChange} />
      </div>
    </form>
  );
};

export default PaymentInfoComponent;
