import React, { useState } from 'react';
import DashboardLayout from '../DashboardLayout/DashboardLayout';
import img from '../../../images/avatar.jpg';
import './RescheduleAppointments.css';
import { useApproveRescheduleMutation, useGetDoctorAppointmentsQuery, useRescheduleAppointmentMutation } from '../../../redux/api/appointmentApi';
import moment from 'moment';
import { Button, Empty, message, Tag, Tooltip, DatePicker, TimePicker, Spin } from 'antd';
import { FaCheck, FaTimes, FaClock, FaEnvelope, FaPhoneAlt, FaCalendarAlt } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { clickToCopyClipBoard } from '../../../utils/copyClipBoard';

const RescheduleAppointments = () => {
    const { data: appointments, isLoading, isError } = useGetDoctorAppointmentsQuery();
    const [approveReschedule] = useApproveRescheduleMutation();
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    console.log(appointments);

    const rescheduleRequests = appointments?.filter(appointment => appointment.rescheduleDate);

    const handleApproveReschedule = async (appointmentId, date) => {
        if (!selectedTime) {
            message.error('Please select Time!!');
            return;
        }

        try {
            console.log(date);
            await approveReschedule({
                id: appointmentId,
                data: {
                    scheduleDate: date,
                    scheduleTime: selectedTime.format('hh:mm A'),
                    status: 'rescheduled',
                    rescheduleDate: null
                }
            }).unwrap();
            message.success("Successfully approved rescheduling request");
        } catch (error) {
            console.error(error);
            message.error("Failed to approve rescheduling request");
        }
    };

    const handleRejectReschedule = async (appointmentId) => {
        try {
            await approveReschedule({
                id: appointmentId,
                data: {
                    rescheduleDate: null,
                    status: 'scheduled'
                }
            }).unwrap();
            message.success("Successfully rejected rescheduling request");
        } catch (error) {
            message.error("Failed to reject rescheduling request");
        }
    };

    const getInitPatientName = (patient) => {
        const fullName = `${patient?.firstName ?? ''} ${patient?.lastName ?? ''}`;
        return fullName.trim() || "Private Patient";
    };

    if (isLoading) {
        return (
            <DashboardLayout>
                <div className="text-center">
                    <Spin size="large" />
                </div>
            </DashboardLayout>
        );
    }

    if (isError) {
        return (
            <DashboardLayout>
                <div>Something Went Wrong!</div>
            </DashboardLayout>
        );
    }

    return (
        <DashboardLayout>
            <h2 className="mb-4">Rescheduling Requests</h2>
            {rescheduleRequests?.length === 0 ? (
                <Empty description="No rescheduling requests" />
            ) : (
                rescheduleRequests?.map((item) => (
                    <div className="rescheduled-appointment-card mb-3 rounded p-4" key={item.id}>
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center gap-4">
                                <Link to={`/`} className="patient-img">
                                    <img src={item.patient?.img || img} alt="" className="rounded-circle" style={{ width: '60px', height: '60px' }} />
                                </Link>
                                <div className="patients-info">
                                    <h5>{getInitPatientName(item.patient)}</h5>
                                    <Tooltip title="Copy Tracking Id">
                                        <Button type="text">
                                            <h6>Tracking <Tag color="#87d068" className='ms-2 text-uppercase' onClick={() => clickToCopyClipBoard(item?.trackingId)}>{item?.trackingId}</Tag></h6>
                                        </Button>
                                    </Tooltip>
                                    <div className="info mt-2">
                                        <p><FaClock className='icon' /> Current: {moment(item?.scheduleDate).format("MMM Do YY")} at {item?.scheduleTime}</p>
                                        <p><FaCalendarAlt className='icon' /> Requested: {moment(item?.rescheduleDate).format("MMM Do YY")}</p>
                                        {item?.patient?.email && <p><FaEnvelope className='icon' /> {item?.patient?.email}</p>}
                                        {item?.patient?.phone && <p><FaPhoneAlt className='icon' /> {item?.patient?.phone}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className='rescheduling-actions'>
                                <TimePicker 
                                    use12Hours 
                                    format="h:mm A"
                                    onChange={setSelectedTime} 
                                    className="mb-2" 
                                />
                                <Button type="primary" icon={<FaCheck />} onClick={() => handleApproveReschedule(item.id, item?.rescheduleDate)} block>
                                    Approve Reschedule
                                </Button>
                                <Button type="default" icon={<FaTimes />} onClick={() => handleRejectReschedule(item.id)} block className="mt-2">
                                    Reject
                                </Button>
                            </div>
                        </div>
                    </div>
                ))
            )}
        </DashboardLayout>
    );
};

export default RescheduleAppointments;