import React, { useState, useEffect } from "react";
import { FaEnvelope, FaLock } from "react-icons/fa";
import { useForm } from "react-hook-form";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate, useParams } from "react-router-dom";
import { message } from "antd";
import {
  useResetPasswordMutation,
  useDoctorLoginMutation,
} from "../../redux/api/authApi";
import { useUserLoginMutation } from "../../redux/api/authApi";
import { useSendOtpMutation } from "../../redux/api/doctorApi";

const HospitalSignIn = ({ userType }) => {
  const { id } = useParams();
  // const [userType, setUserType] = useState(null); // "doctor" or "patient"
  const [otpSent, setOtpSent] = useState(false); // Track if OTP is sent for doctors
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [
    userLogin,
    {
      isError: userError,
      isLoading: userLoading,
      isSuccess: userSuccess,
      error: userExtraErrors,
    },
  ] = useUserLoginMutation();
  const [infoError, setInfoError] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [doctorLogin, { isError, isLoading, isSuccess, error }] =
    useDoctorLoginMutation();
  const [forgotEmail, setForgotEmail] = useState("");
  const [
    resetPassword,
    {
      isError: resetIsError,
      isSuccess: resetIsSuccess,
      error: resetError,
      isLoading: resetIsLoading,
    },
  ] = useResetPasswordMutation();
  const [sendOtp, { isLoading: isSendingOtp }] = useSendOtpMutation(); // Mutation to send OTP

  const onSubmit = async (data) => {
    const currentUrl = window.location.href;
    try {
      if (userType === "doctor" && !otpSent) {
        // If user is a doctor and OTP hasn't been sent, send OTP
       const resp =  await sendOtp({ email: data.email, id }).unwrap();
       console.log("resp" , resp);
       
        setOtpSent(true);
        message.success("OTP sent successfully.");
      } else {
        // For both patients and doctors after OTP is sent
        if (userType === "doctor") {
          await doctorLogin({ ...data, currentUrl }).unwrap();
          message.success("Successfully logged in");
          navigate(`/doctor/dashboard/${id}`);
        } else if (userType === "patient") {
          await userLogin({ ...data, currentUrl }).unwrap();
          message.success("Successfully logged in");
          navigate(`/dashboard/${id}`);
        }
      }
    } catch (err) {
      const errorMessage =
        err?.data?.message || "An unexpected error occurred.";
      message.error(errorMessage);
      setInfoError(errorMessage);
    }
  };

  // const handleUserTypeSelection = (type) => {
  //   setUserType(type);
  // };

  const handleShowForgotPassword = () => {
    setShowForgotPassword(!showForgotPassword);
  };

  useEffect(() => {
    if (isError) {
      message.error(error?.data?.message);
      setInfoError(error?.data?.message);
    }
    if (isSuccess) {
      message.success("Successfully Logged in");
      if (userType === "doctor") {
        navigate(`/doctor/dashboard/${id}`);
      } else if (userType === "patient") {
        navigate(`/dashboard/${id}`);
      }
    }
  }, [isError, error, isSuccess, navigate, userType, id]);
  const onHandleForgotPassword = async (e) => {
    e.preventDefault();
    if (forgotEmail === "") {
      message.error("Please Enter Email");
    }
    if (forgotEmail !== "") {
      await resetPassword({ email: forgotEmail });
      message.success("Email Sent Reset your Password!!");
      setForgotEmail("");
      setShowForgotPassword(false);
    }
  };

  return (
    <>
      {userType && showForgotPassword ? (
        <form className="sign-in-form" onSubmit={onHandleForgotPassword}>
          <h2 className="title">Forgot Password</h2>
          <div>To reset your password, please enter your email</div>
          <div className="input-field">
            <span className="fIcon">
              <FaEnvelope />
            </span>
            <input
              value={forgotEmail}
              onChange={(e) => setForgotEmail(e.target.value)}
              placeholder="Enter Your Email"
              type="email"
              required
            />
          </div>
          <div
            onClick={handleShowForgotPassword}
            className="text-bold"
            style={{ cursor: "pointer", color: "#4C25F5" }}
          >
            Remember your password?
          </div>
          <button className="iBtn" type="submit">
            {resetIsLoading ? (
              <Spinner animation="border" variant="info" />
            ) : (
              "Submit"
            )}
          </button>
        </form>
      ) : (
        <form className="sign-in-form" onSubmit={handleSubmit(onSubmit)}>
          <h2 className="title">Sign in as {userType === "doctor" ? "provider" : "patient" }</h2>
          <div className="input-field">
            <span className="fIcon">
              <FaEnvelope />
            </span>
            <input
              {...register("email", { required: true })}
              placeholder="Enter Your Email"
              type="email"
              disabled={otpSent} // Disable email field after OTP is sent
            />
          </div>
          {errors.email && (
            <span className="text-danger">This field is required</span>
          )}
          {(userType === "patient" || otpSent) && (
            <div className="input-field">
              <span className="fIcon">
                <FaLock />
              </span>
              <input
                {...register("password", { required: true })}
                type="password"
                placeholder={
                  userType === "doctor" ? "Enter OTP" : "Enter Your Password"
                }
              />
            </div>
          )}
          {errors.password && (
            <span className="text-danger">This field is required</span>
          )}
          {infoError && <p className="text-danger">{infoError}</p>}
          {userType === "patient" && (
            <div
              onClick={handleShowForgotPassword}
              className="text-bold"
              style={{ cursor: "pointer", color: "#4C25F5" }}
            >
              Forgot Password?
            </div>
          )}
          {userType === "doctor" && (
            <button
              className="iBtn"
              type="submit"
              disabled={isSendingOtp || isLoading}
            >
              {isLoading || isSendingOtp ? (
                <Spinner animation="border" variant="info" />
              ) : otpSent ? (
                "Verify OTP"
              ) : (
                "Send OTP"
              )}
            </button>
          )}
          {userType === "patient" && (
            <button
              className="iBtn"
              type="submit"
              disabled={isSendingOtp || isLoading}
            >
              {userLoading ? (
                <Spinner animation="border" variant="info" />
              ) : (
                "Submit"
              )}
            </button>
          )}
        </form>
      )}
    </>
  );
};

export default HospitalSignIn;
