import React, { useState } from "react";
import moment from "moment";
import {
  useGetPatientAppointmentsQuery,
  useGetPatientInvoicesQuery,
  useRescheduleAppointmentMutation,
} from "../../../redux/api/appointmentApi";
import { useGetPatientPrescriptionQuery } from "../../../redux/api/prescriptionApi";
import { Button, Tabs, Tag, Tooltip, Card, Avatar, Table, Modal, Select, message } from "antd";
import { Link, useParams } from "react-router-dom";
import dayjs from "dayjs";
import {
  FaRegEye,
  FaUserMd,
  FaCalendarAlt,
  FaFileInvoiceDollar,
} from "react-icons/fa";
import { clickToCopyClipBoard } from "../../../utils/copyClipBoard";
import { getUserInfo } from "../../../service/auth.service";

const { TabPane } = Tabs;
const { Option } = Select;

const PatientDashboard = () => {
  const { id } = useParams();
  const userData = getUserInfo();
  const patientId = userData?.userId;
  const hospitalId = id;

  const [visible, setVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [currentAppointment, setCurrentAppointment] = useState(null);

  const { data: appointmentsData, isLoading: appointmentsLoading } = useGetPatientAppointmentsQuery({
    hospitalId,
    patientId,
  });

  const { data: prescriptionData, isLoading: prescriptionLoading } = useGetPatientPrescriptionQuery(patientId);

  const { data: invoicesData, isLoading: invoicesLoading } = useGetPatientInvoicesQuery({
    hospitalId,
    patientId,
  });

  const [rescheduleAppointment] = useRescheduleAppointmentMutation();

  const handleRescheduleClick = (appointmentDate, id) => {
    setVisible(true);
    setSelectedDate(null);
    setCurrentAppointment({ date: appointmentDate, id: id });
  };

  const getThreeConsecutiveDates = (date) => {
    const baseDate = moment(date);
    return [
      baseDate.add(1, 'days').format("YYYY-MM-DD"),
      baseDate.add(1, 'days').format("YYYY-MM-DD"),
      baseDate.add(1, 'days').format("YYYY-MM-DD"),
    ];
  };

  const handleCancel = async (appointmentId, scheduleDate) => {
    const currentDate = new Date();
    const appointmentDate = new Date(scheduleDate);
    const timeDifference = appointmentDate.getTime() - currentDate.getTime();
    const hoursDifference = timeDifference / (1000 * 3600);

    if (hoursDifference < 24) {
      Modal.error({
        title: 'Cannot Cancel Appointment',
        content: `This appointment is ${Math.round(hoursDifference)} hours away. Cancellations are only allowed for appointments more than 24 hours in advance.`,
      });
      return;
    }

    Modal.confirm({
      title: 'Are you sure you want to cancel this appointment?',
      content: 'This action cannot be undone.',
      onOk: async () => {
        try {
          const token = localStorage.getItem('accessToken');
          const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/appointment/cancel/${appointmentId}`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `${token}`,
            },
          });

          if (!response.ok) {
            throw new Error('Failed to cancel appointment');
          }

          message.success('Appointment cancelled successfully');
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } catch (error) {
          console.error('Error:', error);
          message.error('Failed to cancel appointment');
        }
      },
    });
  };

  const handleConfirm = () => {
    if (!selectedDate || !currentAppointment) {
      message.error('Please select a new date');
      return;
    }

    Modal.confirm({
      title: 'Are you sure you want to reschedule the appointment?',
      onOk: async () => {
        try {
          const result = await rescheduleAppointment({
            id: currentAppointment.id,
            date: selectedDate
          });

          if (result.data) {
            message.success('Appointment rescheduled successfully');
            // Optionally, refresh the appointments data here
          } else if (result.error) {
            throw new Error('Failed to reschedule appointment');
          }
        } catch (error) {
          console.error('Error:', error);
          message.error('Failed to reschedule appointment');
        } finally {
          setVisible(false);
          setCurrentAppointment(null);
        }
      },
    });
  };

  const invoiceColumns = [
    {
      title: "Invoice ID",
      dataIndex: "transactionId",
      key: "transactionId",
      render: (text) => <Tag color="blue">{text}</Tag>,
    },
    {
      title: "Provider",
      key: "doctor",
      render: (record) => `${record.appointment.doctor.firstName} ${record.appointment.doctor.lastName}`,
    },
    {
      title: "Appointment Date",
      dataIndex: ["appointment", "scheduleDate"],
      key: "appointmentDate",
      render: (date) => moment(date).format("MMM D, YYYY"),
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: (amount, record) => (
        <span>
          {record.currency.toUpperCase() + " "}
          {amount / 100}
        </span>
      ),
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      render: (status) => (
        <Tag color={status === "paid" ? "green" : "red"}>{status.toUpperCase()}</Tag>
      ),
    },
  ];

  const prescriptionColumns = [
    {
      title: "Provider",
      key: 11,
      width: 200,
      render: function (data) {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar src={data.doctor.img || "https://www.svgrepo.com/show/429791/doctor-human.svg"} size={40} style={{ marginRight: 10 }} />
            <div>
              <div style={{ fontWeight: "bold" }}>
                {data?.doctor?.firstName + " " + data?.doctor?.lastName}
              </div>
              <div style={{ fontSize: "0.8rem", color: "#888" }}>
                {data?.doctor?.designation}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Appointment Id",
      dataIndex: "appointment",
      key: 1,
      render: ({ trackingId }) => (
        <Tooltip title="Copy Tracking Id">
          <Button onClick={() => clickToCopyClipBoard(trackingId)}>
            <Tag color="#87d068" className="text-uppercase">
              {trackingId}
            </Tag>
          </Button>
        </Tooltip>
      ),
    },
    {
      title: "Appointment Date",
      key: 12,
      width: 150,
      render: function (data) {
        return (
          <div>
            {moment(data?.appointment?.scheduleDate).format("MMM D, YYYY")}
            <div style={{ color: "#1890ff" }}>
              {data?.appointment?.scheduleTime}
            </div>
          </div>
        );
      },
    },
    {
      title: "Follow-Update",
      dataIndex: "followUpdate",
      key: 4,
      render: function (data) {
        return (
          <Tag color="#87d068">{dayjs(data).format("MMM D, YYYY hh:mm A")}</Tag>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "isArchived",
      key: 5,
      render: function ({ isArchived }) {
        return (
          <Tag color={isArchived ? "#f50" : "#108ee9"}>
            {isArchived ? "Yes" : "Under Treatment"}
          </Tag>
        );
      },
    },
    {
      title: "Action",
      key: 14,
      width: 100,
      render: function (data) {
        return (
          <Link to={`/dashboard/prescription/view/${id}/${data.id}`}>
            <Button type="primary" icon={<FaRegEye />}>
              View
            </Button>
          </Link>
        );
      },
    },
  ];

  const isAppointmentModifiable = (status) => {
    const modifiableStatuses = ["pending", "scheduled", "confirmed", "rescheduled", "accept"];
    return modifiableStatuses.includes(status.toLowerCase());
  };

  const appointmentColumns = [
    {
      title: "Provider",
      key: 20,
      width: 200,
      render: function (data) {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar src={data?.doctor?.img || "https://www.svgrepo.com/show/429791/doctor-human.svg"} size={40} style={{ marginRight: 10 }} />
            <div>
              <div style={{ fontWeight: "bold" }}>
                {data?.doctor.firstName + " " + data?.doctor.lastName}
              </div>
              <div style={{ fontSize: "0.8rem", color: "#888" }}>
                {data?.designation}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "App Date",
      key: 22,
      width: 150,
      render: function (data) {
        return (
          <div>
            {moment(data?.scheduleDate).format("MMM D, YYYY")}
            <div style={{ color: "#1890ff" }}>{data?.scheduleTime}</div>
          </div>
        );
      },
    },
    {
      title: "Booking Date",
      key: 23,
      width: 120,
      render: function (data) {
        return <div>{moment(data?.createdAt).format("MMM D, YYYY")}</div>;
      },
    },
    {
      title: "Status",
      key: 24,
      width: 100,
      render: function (data) {
        return <Tag color="#f50">{data?.status}</Tag>;
      },
    },
    {
      title: "Feedback",
      key: 26,
      width: 100,
      render: function (data) {
        const isFeedbackEnabled = ["Completed", "FollowUp", "InProgress"].includes(data.status);
        return (
          <Link to={`/dashboard/appointments/feedback/${id}/${data.id}`}>
            <Button disabled={!isFeedbackEnabled} type="primary">
              Fill
            </Button>
          </Link>
        );
      },
    },
    {
      title: 'Cancel',
      key: 'cancel',
      width: 100,
      render: (record) => {
        const currentDate = new Date();
        const scheduleDate = new Date(record?.scheduleDate);
        const timeDifference = scheduleDate.getTime() - currentDate.getTime();
        const isLessThan24HoursAway = timeDifference < 86400000; // 24 hours in milliseconds

        return (
          <Button
            onClick={() => handleCancel(record.id, record.scheduleDate)}
            disabled={isLessThan24HoursAway || !isAppointmentModifiable(record.status) || record.status === "rescheduled"}
          >
            Cancel
          </Button>
        );
      },
    },
    {
      title: "Reschedule",
      key: "reschedule",
      width: 100,
      render: (record) => {
        const currentDate = new Date();
        const scheduleDate = new Date(record?.scheduleDate);
        const timeDifference = scheduleDate - currentDate;
        const isLessThan24HoursAway = timeDifference < 86400000;

        return (
          <Button
            onClick={() => handleRescheduleClick(record?.scheduleDate, record?.id)}
            disabled={isLessThan24HoursAway || !isAppointmentModifiable(record.status) || record.status === "rescheduled"}
          >
            Reschedule
          </Button>
        );
      },
    },
    {
      title: "Action",
      key: 25,
      width: 100,
      render: function (data) {
        return (
          <Link to={`/dashboard/appointments/${id}/${data.id}`}>
            <Button
              disabled={!isAppointmentModifiable(data.status)}
              type="primary"
              icon={<FaRegEye />}
            >
              View
            </Button>
          </Link>
        );
      },
    },
  ];

  return (
    <Card>
      <Tabs defaultActiveKey="1">
        <TabPane
          tab={
            <span>
              <FaCalendarAlt /> Appointments
            </span>
          }
          key="1"
        >
          <Table
            loading={appointmentsLoading}
            columns={appointmentColumns}
            dataSource={appointmentsData}
            pagination={{
              pageSize: 10,
              showSizeChanger: true,
            }}
          />
          <Modal
            title="Reschedule Appointment"
            open={visible}
            onCancel={() => {
              setVisible(false);
              setCurrentAppointment(null);
            }}
            footer={null}
          >
            {currentAppointment && (
              <>
                <Select onChange={setSelectedDate} placeholder="Select a new date">
                  {getThreeConsecutiveDates(currentAppointment.date).map((date, index) => (
                    <Option key={index} value={date}>{date}</Option>
                  ))}
                </Select>
                <Button type="primary" onClick={handleConfirm} disabled={!selectedDate}>
                  Confirm Reschedule
                </Button>
              </>
            )}
          </Modal>
        </TabPane>
        <TabPane
          tab={
            <span>
              <FaUserMd /> Prescriptions
            </span>
          }
          key="2"
        >
          <Table
            loading={prescriptionLoading}
            columns={prescriptionColumns}
            dataSource={prescriptionData}
            pagination={{
              pageSize: 10,
              showSizeChanger: true,
            }}
          />
        </TabPane>
        <TabPane
          tab={
            <span>
              <FaFileInvoiceDollar /> Billing
            </span>
          }
          key="3"
        >
          <Table
            loading={invoicesLoading}
            columns={invoiceColumns}
            dataSource={invoicesData}
            pagination={{
              pageSize: 10,
              showSizeChanger: true,
            }}
          />
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default PatientDashboard;