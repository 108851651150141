import React from 'react';
import { DatePicker, Space } from 'antd';

const { RangePicker } = DatePicker;


const AppointmentDateRangePicker = ({ onDateRangeChange }) => {
  return (
    <Space direction="vertical" size={12}>
      <RangePicker
        onChange={onDateRangeChange}
        size="large"
        style={{ width: '100%' }}
      />
    </Space>
  );
};

export default AppointmentDateRangePicker;