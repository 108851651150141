import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FaPlus, FaRegTrashAlt } from "react-icons/fa";
import { Button, DatePicker, Space, message, Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useForm, Controller } from "react-hook-form";
import DashboardLayout from "../DashboardLayout/DashboardLayout";
import {
  DatePickerSinglePresets,
  DiagnosisOptions,
  DiseaseOptions,
  DosageOptions,
  FrequencyOptions,
  MedicalCheckupOptions,
  PatientStatus,
  appointemntStatusOption,
} from "../../../constant/global";
import SelectForm from "../../UI/form/SelectForm";
import TextArea from "antd/es/input/TextArea";
import InputAutoCompleteForm from "../../UI/form/InputAutoCompleteForm";
import SelectFormForMedicine from "../../UI/form/SelectFormForMedicine";
import MedicineRangePickerForm from "../../UI/form/MedicineRangePickerForm";
import { useCreatePrescriptionMutation } from "../../../redux/api/prescriptionApi";
import { useGetSingleAppointmentQuery } from "../../../redux/api/appointmentApi";
import TreatmentOverview from "./TreatmentOverview";

const { Dragger } = Upload;

const Treatment = () => {
  const navigate = useNavigate();
  const { appointmentId, id } = useParams();
  const { data: appointmentData } = useGetSingleAppointmentQuery(appointmentId);
  const { control, handleSubmit, setValue, watch } = useForm();
  const [isDisable, setIsDisable] = useState(true);
  const [medicineList, setMedicineList] = useState([{ id: 1 }]);

  const [createPrescription, { isLoading }] = useCreatePrescriptionMutation();

  const watchedFields = watch();

  useEffect(() => {
    const isInputEmpty =
      !watchedFields.status ||
      !watchedFields.patientType ||
      !watchedFields.instruction ||
      !watchedFields.followUpdate ||
      watchedFields.diagnosis?.length === 0 ||
      watchedFields.disease?.length === 0 ||
      watchedFields.test?.length === 0;
    setIsDisable(isInputEmpty);
  }, [watchedFields]);

  const addField = (e) => {
    e.preventDefault();
    setMedicineList([...medicineList, { id: medicineList.length + 1 }]);
  };

  const removeFromAddTimeSlot = (id) => {
    setMedicineList(medicineList.filter((item) => item.id !== id));
  };

  const handleFollowUpChange = (date) => {
    if (date) {
      setValue("followUpdate", dayjs(date).format());
    }
  };
  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("status", data.status);
    formData.append("patientType", data.patientType);
    formData.append("diagnosis", data.diagnosis.join(","));
    formData.append("disease", data.disease.join(","));
    formData.append("test", data.test.join(","));
    formData.append("followUpdate", data.followUpdate);
    formData.append("instruction", data.instruction);
    formData.append("appointmentId", appointmentId);
    formData.append("medicine", JSON.stringify(medicineList));

    // Check if data.files exists and is an array
    if (Array.isArray(data.files)) {
      data.files.forEach((file) => {
        formData.append("files", file.originFileObj);
      });
    } else if (data.files && data.files.fileList) {
      // If it's not an array, it might be an object with a fileList property
      data.files.fileList.forEach((file) => {
        formData.append("files", file.originFileObj);
      });
    }

    try {
      await createPrescription({ data: formData }).unwrap();
      message.success("Successfully Saved!");
      navigate(`/doctor/dashboard/prescription/${id}`);
    } catch (err) {
      message.error("An error occurred while saving the prescription.");
    }
  };

  return (
    <DashboardLayout>
      <TreatmentOverview data={appointmentData} />
      <div className="w-100 mb-3 rounded p-3 bg-gray-g">
        <div className="text-center mb-2 d-flex justify-content-center">
          <h5 className="border-success border-bottom w-25 pb-2 border-5">
            Start Treatment
          </h5>
        </div>

        <form className="row form-row" onSubmit={handleSubmit(onSubmit)}>
          <div className="col-md-6">
            <div className="form-group mb-4">
              <div className="mb-2">
                <h6 className="card-title text-secondary">
                  Change Appointment Status
                </h6>
              </div>
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <SelectForm
                    {...field}
                    showSearch={true}
                    options={appointemntStatusOption}
                    setSelectData={(value) => field.onChange(value)}
                  />
                )}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group mb-4">
              <div className="mb-2">
                <h6 className="card-title text-secondary">
                  Change Patient Status
                </h6>
              </div>
              <Controller
                name="patientType"
                control={control}
                render={({ field }) => (
                  <SelectForm
                    {...field}
                    showSearch={true}
                    options={PatientStatus}
                    setSelectData={(value) => field.onChange(value)}
                  />
                )}
              />
            </div>
          </div>

          <div className="col-md-12">
            <div className="card p-3 mb-3">
              <h6 className="card-title text-secondary">
                Identify Disease & Symptoms
              </h6>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <div>
                      <label>Diagnosis</label>
                    </div>
                    <Controller
                      name="diagnosis"
                      control={control}
                      render={({ field }) => (
                        <SelectForm
                          {...field}
                          mode={true}
                          options={DiagnosisOptions}
                          setSelectData={(value) => field.onChange(value)}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <div>
                      <label>Disease</label>
                    </div>
                    <Controller
                      name="disease"
                      control={control}
                      render={({ field }) => (
                        <SelectForm
                          {...field}
                          mode={true}
                          options={DiseaseOptions}
                          setSelectData={(value) => field.onChange(value)}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12 mb-3">
            <div className="card mb-2 p-3 mt-2">
              <h6 className="card-title text-secondary">Medical Checkup</h6>
              <div className="row form-row">
                <div className="form-group mb-2 card-label">
                  <label>Medical Checkup</label>
                  <Controller
                    name="test"
                    control={control}
                    render={({ field }) => (
                      <SelectForm
                        {...field}
                        mode={true}
                        options={MedicalCheckupOptions}
                        setSelectData={(value) => field.onChange(value)}
                      />
                    )}
                  />
                  <small className="form-text text-muted">
                    Note : Type & Press enter to add new services
                  </small>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="card mb-2 p-3 mt-2">
              <h6 className="card-title text-secondary">Medicine</h6>
              {medicineList?.map((item, index) => (
                <div
                  className="row form-row mb-4 position-relative border border-success rounded m-2 p-3"
                  key={index + 1}
                >
                  <div className="col-md-6 mb-3">
                    <label>Medicine Name</label>
                    <div className="form-group mb-2">
                      <InputAutoCompleteForm
                        id={item.id}
                        medicineList={medicineList}
                        setMedicineList={setMedicineList}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 mb-3">
                    <label>Dosage</label>
                    <div className="form-group mb-2">
                      <SelectFormForMedicine
                        id={item.id}
                        keyName={"dosage"}
                        options={DosageOptions}
                        medicineList={medicineList}
                        setMedicineList={setMedicineList}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 mb-3">
                    <label>Frequency</label>
                    <div className="form-group mb-2">
                      <SelectFormForMedicine
                        id={item.id}
                        keyName={"frequency"}
                        options={FrequencyOptions}
                        medicineList={medicineList}
                        setMedicineList={setMedicineList}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 mb-3">
                    <label>Start Date / End Date</label>
                    <div className="form-group mb-2">
                      <Space direction="vertical" size={12}>
                        <MedicineRangePickerForm
                          id={item.id}
                          medicineList={medicineList}
                          setMedicineList={setMedicineList}
                        />
                      </Space>
                    </div>
                  </div>

                  <a
                    className="text-danger position-absolute end-0 top-10 translate-middle-y me-2"
                    onClick={() => removeFromAddTimeSlot(item.id)}
                    style={{ cursor: "pointer" }}
                  >
                    <FaRegTrashAlt />
                  </a>
                </div>
              ))}
              <button className="btn btn-primary" onClick={addField}>
                <FaPlus /> Add More Medicine
              </button>
            </div>
          </div>

          <div className="col-md-12">
            <div className="card mb-2 p-3 mt-2">
              <h6 className="card-title text-secondary">Instruction</h6>
              <Controller
                name="instruction"
                control={control}
                render={({ field }) => <TextArea rows={4} {...field} />}
              />
            </div>
          </div>

          <div className="col-md-12">
            <div className="card p-3 mb-3">
              <div className="form-group">
                <label>Follow Up</label>
                <Space direction="vertical" size={12}>
                  <DatePicker
                    format="YYYY-MM-DD"
                    onChange={handleFollowUpChange}
                  />
                </Space>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="card mb-2 p-3 mt-2">
              <h6 className="card-title text-secondary">Upload File</h6>
              <div className="form-group mb-2">
                <Controller
                  name="files"
                  control={control}
                  render={({ field }) => (
                    <Dragger {...field}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                    </Dragger>
                  )}
                />
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="text-center">
              <Button
                type="primary"
                htmlType="submit"
                disabled={isDisable || isLoading}
              >
                Save Prescription
              </Button>
            </div>
          </div>
        </form>
      </div>
    </DashboardLayout>
  );
};

export default Treatment;
