import React, { useEffect, useState } from 'react';
import Header from '../Shared/Header/Header';
import Footer from '../Shared/Footer/Footer';
import { Button, Form, Input, message } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useResetConfirmMutation } from '../../redux/api/authApi';

const ForgotPassword = () => {
    const [matchePass, setMatchPass] = useState(true);
    const { userId, uniqueString } = useParams();
    const navigate = useNavigate();
    const [resetConfirm, { isError, isLoading, isSuccess, error, data }] = useResetConfirmMutation();

    const onFinish = (values) => {
        const { password, rePassword } = values;
        if (password && password === rePassword) {
            setMatchPass(true);
            const obj = { password, userId, uniqueString };
            resetConfirm(obj);
        } else {
            setMatchPass(false);
        }
    };

    useEffect(() => {
        if (isError) {
            message.error(error?.data?.message);
        }
        if (isSuccess) {
            message.success('Password successfully changed');
            if(data.role === "admin"){
                navigate("/admin/login");
            }else if(data.role === "patient"){
                navigate(`/`);
            }
        }
    }, [isError, error, isSuccess, navigate]);

    return (
        <>
            <Header />
            <div className='d-flex justify-content-center'>
                <div style={{ marginTop: '8rem', marginBottom: '8rem', minWidth: "450px", maxWidth: "450px" }} >
                    <div className='card bg-white shadow p-3 border-0'>
                        <div>
                            <Form
                                layout="vertical"
                                onFinish={onFinish}
                                autoComplete="off"
                            >
                                <Form.Item 
                                    label="Password" 
                                    name="password" 
                                    rules={[
                                        { required: true, message: 'Please input your password!' },
                                        { 
                                            pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                                            message: 'Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, a number, and a special character.'
                                        }
                                    ]}
                                    style={{ whiteSpace: 'pre-wrap' }} // Ensures the error message wraps
                                >
                                    <Input.Password />
                                </Form.Item>

                                <Form.Item 
                                    label="Re-enter Password" 
                                    name="rePassword" 
                                    dependencies={['password']}
                                    rules={[
                                        { required: true, message: 'Please re-enter your password!' },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('Passwords do not match!'));
                                            },
                                        }),
                                    ]}
                                    style={{ whiteSpace: 'pre-wrap' }} // Ensures the error message wraps
                                >
                                    <Input.Password />
                                </Form.Item>

                                <Form.Item>
                                    <Button type="primary" disabled={isLoading} htmlType="submit" loading={isLoading}>
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                            {!matchePass && <p className='text-danger' style={{ whiteSpace: 'pre-wrap' }}>Passwords do not match</p>}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default ForgotPassword;
