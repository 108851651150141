import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import {
  LocalUser,
  RemoteUser,
  useJoin,
  useLocalCameraTrack,
  useLocalMicrophoneTrack,
  usePublish,
  useRemoteAudioTracks,
  useRemoteUsers,
} from "agora-rtc-react";
import { FaMicrophone, FaMicrophoneSlash, FaVideo, FaVideoSlash, FaPhoneSlash } from 'react-icons/fa';
import "./video.css";
import { getUserInfo } from "../../service/auth.service";

export const LiveVideo = () => {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const appId = process.env.REACT_APP_AGORA_APP_ID || "9f5b8f4046de4274bf6e9b7b7a929bc5";
  const { channelName } = useParams();
  const [activeConnection, setActiveConnection] = useState(true);
  const [micOn, setMic] = useState(true);
  const [cameraOn, setCamera] = useState(true);
  const [isRecording, setIsRecording] = useState(false);
  const [recordingData, setRecordingData] = useState(null);
  const [userInfo, setUserInfo] = useState({ role: null, id: null });
  const [uid, setUid] = useState(null);
  const { localMicrophoneTrack, isLoading: micLoading } = useLocalMicrophoneTrack();
  const { localCameraTrack, isLoading: cameraLoading } = useLocalCameraTrack();
  const navigate = useNavigate();

  useJoin(
    {
      appid: appId,
      channel: channelName,
      token: null,
    },
    activeConnection,
  );

  usePublish([micOn ? localMicrophoneTrack : null, cameraOn ? localCameraTrack : null]);
  const remoteUsers = useRemoteUsers();
  const { audioTracks } = useRemoteAudioTracks(remoteUsers);

  useEffect(() => {
    audioTracks.forEach((track) => track.play());
  }, [audioTracks]);

  useEffect(() => {
    if (localMicrophoneTrack) {
      micOn ? localMicrophoneTrack.setEnabled(true) : localMicrophoneTrack.setEnabled(false);
    }
  }, [micOn, localMicrophoneTrack]);

  useEffect(() => {
    if (localCameraTrack) {
      cameraOn ? localCameraTrack.setEnabled(true) : localCameraTrack.setEnabled(false);
    }
  }, [cameraOn, localCameraTrack]);

  useEffect(() => {
    const fetchUserInfo = async () => {
      const info = await getUserInfo();
      setUserInfo({ role: info.role, id: info.id });
    };
    fetchUserInfo();

    // Generate a random UID between 1 and 1000000 and convert to string
    const randomUid = Math.floor(Math.random() * 1000000) + 1;
    setUid(randomUid.toString());
  }, []);

  useEffect(() => {
    if (userInfo.role === 'doctor' && remoteUsers.length === 1 && uid) {
      startRecording();
    }
  }, [userInfo.role, remoteUsers, uid]);

  const startRecording = async () => {
    if (userInfo.role !== 'doctor' || !uid) return;

    try {
      const acquireResponse = await axios.post(`${apiUrl}/call/acquire`, {
        channelName,
        uid: uid
      });
      const resourceId = acquireResponse.data.resourceId;
      const startResponse = await axios.post(`${apiUrl}/call/start`, {
        channelName,
        uid: uid,
        resourceId,
        token: null,
      });
      const sid = startResponse.data.sid;
      setRecordingData({ resourceId, sid });
      setIsRecording(true);
    } catch (error) {
      console.error('Failed to start recording:', error);
    }
  };

  const stopRecording = async () => {
    if (userInfo.role !== 'doctor' || !uid) return;

    try {
      if (!recordingData) return;
      await axios.post(`${apiUrl}/call/stop`, {
        channelName,
        uid: uid,
        resourceId: recordingData.resourceId,
        sid: recordingData.sid
      });
      setRecordingData(null);
      setIsRecording(false);
    } catch (error) {
      console.error('Failed to stop recording:', error);
    }
  };

  const toggleMic = () => {
    if (!micLoading) {
      setMic(prev => !prev);
    }
  };

  const toggleCamera = () => {
    if (!cameraLoading) {
      setCamera(prev => !prev);
    }
  };

  const endCall = async () => {
    if (userInfo.role === 'doctor' && isRecording) {
      await stopRecording();
    }
    setActiveConnection(false);
    navigate('/');
  };

  return (
    <div className="video-call-container">
      <div className="remote-video-grid">
        {remoteUsers.map((user) => (
          <div key={user.uid} className="remote-video-container">
            <RemoteUser user={user} />
          </div>
        ))}
      </div>
      <div className="local-video-container">
        <LocalUser
          audioTrack={micOn ? localMicrophoneTrack : null}
          videoTrack={cameraOn ? localCameraTrack : null}
          cameraOn={cameraOn}
          micOn={micOn}
        />
      </div>
      <div className="controls-toolbar">
        <button className="control-btn" onClick={toggleMic} disabled={micLoading}>
          {micOn ? <FaMicrophone /> : <FaMicrophoneSlash />}
        </button>
        <button className="control-btn" onClick={toggleCamera} disabled={cameraLoading}>
          {cameraOn ? <FaVideo /> : <FaVideoSlash />}
        </button>
        <button className="control-btn end-call" onClick={endCall}>
          <FaPhoneSlash />
        </button>
      </div>
      {isRecording && userInfo.role === 'doctor' && <div className="recording-indicator">Recording in progress</div>}
    </div>
  );
};