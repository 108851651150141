import React from 'react';
import { Card, Avatar, Rate, Typography, List, Tag } from 'antd';
import { FaRegThumbsUp, FaRegThumbsDown } from "react-icons/fa";
import moment from 'moment';
import { useGetDoctorReviewsQuery } from '../../../redux/api/reviewsApi';
import { getUserInfo } from '../../../service/auth.service';
import DashboardLayout from '../DashboardLayout/DashboardLayout';

const { Title, Text } = Typography;

const Reviews = () => {
  const userData = getUserInfo();
  const id = userData.id;
  const { data, isError, isLoading } = useGetDoctorReviewsQuery(id);

  const ReviewItem = ({ item }) => (
    <Card className="mb-4" hoverable>
      <List.Item.Meta
        avatar={
          <Avatar 
            size={64} 
            src={item?.patient?.img || "https://www.svgrepo.com/show/532363/user-alt-1.svg"}
          />
        }
        title={
          <div className="d-flex justify-content-between align-items-center">
            <Title level={4} className="m-0">{`${item?.patient?.firstName} ${item?.patient?.lastName}`}</Title>
            <Rate disabled defaultValue={parseInt(item.star)} />
          </div>
        }
        description={
          <div>
            <Text type="secondary">
              Reviewed {moment(item?.createdAt).fromNow()}
            </Text>
            <br />
            <Tag color={item?.isRecommended ? "green" : "red"} icon={item?.isRecommended ? <FaRegThumbsUp /> : <FaRegThumbsDown />}>
              {item?.isRecommended ? 'Recommends' : 'Does not recommend'}
            </Tag>
          </div>
        }
      />
      <Text>{item?.description}</Text>
    </Card>
  );

  let content = null;

  if (isLoading) {
    content = <div>Loading...</div>;
  } else if (isError) {
    content = <div>Something Went Wrong!</div>;
  } else if (data?.length === 0) {
    content = <div>No reviews yet.</div>;
  } else {
    content = (
      <List
        itemLayout="vertical"
        dataSource={data}
        renderItem={(item) => (
          <ReviewItem item={item} />
        )}
      />
    );
  }

  return (
    <DashboardLayout>
      <Card className="reviews-container">
        <Title color='#2c4964' level={2}>Patient Reviews</Title>
        {content}
      </Card>
    </DashboardLayout>
  );
};

export default Reviews;