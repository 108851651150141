import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { createRoot } from "react-dom/client";
import Footer from "../../Shared/Footer/Footer";
import SubHeader from "../../Shared/SubHeader";
import Header from "../../Shared/Header/Header";
import "./solution.css"

export const SolutionPage = () => {
    const { id, sId } = useParams();
    const [solution, setSolution] = useState();
    const [markdown, setMarkDown] = useState("# Loading...");
    const [doctors, setDoctors] = useState();
    const navigate = useNavigate();
    useEffect(() => {
        const token = localStorage.getItem("accessToken");

        const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        };

        const api_url = process.env.REACT_APP_API_BASE_URL;

        axios
            .get(`${api_url}/solution/hospitalId/${id}`, {
                headers: headers,
            })
            .then((response) => {
                const md = response.data.response.filter((item) => item.id === sId);
                console.log(md);
                setSolution(md[0]);
                setDoctors(md[0].doctors);
                setMarkDown(md[0].content);
            })
            .catch((error) => {
                console.log("Error : " + error);
            });
    }, []);


    const handleSolutionClick = (drId) => {
        navigate(`/booking/${id}/${drId}?sId=${sId}`);
    };

    return (
        <>
            <Header />
            <SubHeader
                title={solution ? solution.title : "Solution curated for You!!"}
                subtitle="Search through our extensive list of personalized solutions to find the best fit for your needs."
            />
            <div className="markdown-body">
            <Markdown remarkPlugins={[[remarkGfm, { singleTilde: false }]]}>
                {markdown}
            </Markdown>
            </div>
            <div
                key={solution && solution.id}
                className="solution-card"
            >
                {doctors ? doctors.map((doctor) => (
                    <div
                        key={doctor.id}
                        className="solution-card"
                    >
                        <div className="card-content">
                            <h2 className="solution-title">{solution.title}</h2>
                            <p className="solution-price">Price: ${solution.price}</p>
                            <p className="solution-date">
                              {"Dr " + doctor.firstName +  " " + doctor.lastName}
                            </p>
                            <button
                                onClick={() => handleSolutionClick(doctor.id)}
                                className="view-details-btn"
                            >
                            Book Appointment
                            </button>
                        </div>
                    </div>
                )): ""}
            </div>
            <Footer />
        </>
    )
};