import { useParams } from "react-router-dom";
import logo from '../../../images/logo.png';
import Footer from "../../Shared/Footer/Footer";
import Header from "../../Shared/Header/Header";
import { useGetPrescriptionQuery } from "../../../redux/api/prescriptionApi";
import moment from "moment";
import { Empty, Table, Button, Card, Row, Col, Typography, Divider, Space } from "antd";
import './index.css';
import { useRef } from "react";
import ReactToPrint from "react-to-print";

const { Title, Text } = Typography;

const PrescriptionView = () => {
    const ref = useRef();
    const { prescriptionId } = useParams();
    const { data, isLoading, isError } = useGetPrescriptionQuery(prescriptionId);

    const columns = [
        {
            title: 'Medicine',
            dataIndex: 'medicine',
            key: 'medicine',
        },
        {
            title: 'Dosage',
            dataIndex: 'dosage',
            key: 'dosage',
        },
        {
            title: 'Frequency',
            dataIndex: 'frequency',
            key: 'frequency',
        },
    ];

    const renderFileDownloads = (fileUrls) => {
        return fileUrls.map((url, index) => {
            const fileName = url.split('/').pop();
            return (
                <Button
                    key={index}
                    type="link"
                    href={url}
                    target="_blank"
                    download
                >
                    {fileName}
                </Button>
            );
        });
    };

    let content = null;
    if (isLoading) content = <div>Loading ...</div>
    if (!isLoading && isError) content = <div>Something went Wrong!</div>
    if (!isLoading && !isError && !data) content = <Empty />
    if (!isLoading && !isError && data) content = (
        <Card>
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <img src={logo} alt="Logo" style={{ maxWidth: '200px' }} />
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                    <Text strong>Tracking Id:</Text> {data?.appointment?.trackingId} <br />
                    <Text strong>Issued:</Text> {moment(data.createdAt).format('LL')}
                </Col>
            </Row>

            <Divider />

            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Card>
                        <Title level={4}>Dr. {data?.doctor?.firstName} {data?.doctor?.lastName}</Title>
                        {data?.doctor?.designation && <Text>{data?.doctor?.designation}</Text>}
                        {data?.doctor?.college && <><br /><Text>{data?.doctor?.college}</Text></>}
                        {data?.doctor?.address && (
                            <>
                                <br />
                                <Text>
                                    {data?.doctor?.address}
                                    {data?.doctor?.state && `, ${data?.doctor?.state}`}
                                    {data?.doctor?.country && `, ${data?.doctor?.country}`}
                                </Text>
                            </>
                        )}
                    </Card>
                </Col>
            </Row>

            <Divider />

            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Card title="Patient Information">
                        <Row>
                            <Col span={12}>
                                <Title level={5}>{data?.patient?.firstName} {data?.patient?.lastName}</Title>
                                {data?.patient?.address && (
                                    <Text>
                                        Address: {data?.patient?.address}
                                        {data?.patient?.city && `, ${data?.patient?.city}`}
                                        {data?.patient?.country && `, ${data?.patient?.country}`}
                                    </Text>
                                )}
                            </Col>
                            <Col span={12}>
                                <Space direction="vertical">
                                    {data?.patient?.gender && <Text>Sex: {data?.patient?.gender}</Text>}
                                    {data?.patient?.dateOfBirth && <Text>Age: {moment().diff(data?.patient?.dateOfBirth, 'years')}</Text>}
                                    {data?.patient?.weight && <Text>Weight: {data?.patient?.weight}</Text>}
                                </Space>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>

            <Divider />

            <Row gutter={[16, 16]}>
                <Col span={8}>
                    <Card title="Medical Information">
                        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                            {data?.disease && (
                                <>
                                    <Title level={5}>SYMPTOMS</Title>
                                    <Text>{data?.disease}</Text>
                                </>
                            )}
                            {data?.daignosis && (
                                <>
                                    <Title level={5}>DIAGNOSIS</Title>
                                    <Text>{data?.daignosis}</Text>
                                </>
                            )}
                            {data?.test && (
                                <>
                                    <Title level={5}>TESTS</Title>
                                    <Text>{data?.test}</Text>
                                </>
                            )}
                            {data?.followUpdate && (
                                <>
                                    <Title level={5}>NEXT APPOINTMENT</Title>
                                    <Text>Date: {moment(data?.followUpdate).format('LL')}</Text>
                                    <Text>Time: {moment(data?.followUpdate).format('LT')}</Text>
                                </>
                            )}
                            {data?.instruction && (
                                <>
                                    <Title level={5}>ADVICE</Title>
                                    <Text>{data?.instruction}</Text>
                                </>
                            )}
                        </Space>
                    </Card>
                </Col>
                <Col span={16}>
                    <Card title="Prescribed Medicines">
                        <Table columns={columns} dataSource={data?.medicines} pagination={false} />
                    </Card>
                </Col>
            </Row>

            {data?.fileUrls && data.fileUrls.length > 0 && (
                <>
                    <Divider />
                    <Row>
                        <Col span={24}>
                            <Card title="Attached Files">
                                {renderFileDownloads(data.fileUrls)}
                            </Card>
                        </Col>
                    </Row>
                </>
            )}
        </Card>
    );

    return (
        <>
            <Header />
            <div className="content" style={{ margin: '6rem 2rem 2rem', maxWidth: '1200px', marginLeft: 'auto', marginRight: 'auto' }}>
                <div style={{ marginBottom: '1rem', textAlign: 'right', position: 'sticky', top: '80px', zIndex: 1000, backgroundColor: 'white', padding: '10px 0' }}>
                    <ReactToPrint
                        bodyClass="print-agreement"
                        content={() => ref.current}
                        trigger={() => (<Button type="primary">Print</Button>)}
                    />
                </div>
                <div ref={ref}>
                    {content}
                </div>
            </div>
            <Footer />
        </>
    )
}

export default PrescriptionView;