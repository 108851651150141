import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import "./home.css";
import useSubdomain from '../../../helpers/subdomain/subdomain';
import { getUserInfo } from '../../../service/auth.service';
import { Color } from 'antd/es/color-picker';

const LandingPage = () => {
    const { hospitalId, loading, error, subdomain } = useSubdomain();
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const checkAuth = async () => {
            const userInfo = await getUserInfo();
            console.log(userInfo);
            setIsAuthenticated(userInfo);
        };
        checkAuth();
    }, []);

    useEffect(() => {
        if(!loading && subdomain !== "averyshub"){
      if(hospitalId){
        console.log(isAuthenticated);
        if(isAuthenticated != null){
            if(isAuthenticated.role === "patient"){
                navigate(`/dashboard/${hospitalId}`);
            }
            else if(isAuthenticated.role === "doctor" ){
                navigate(`/doctor/dashboard/${hospitalId}`);
            }
            else if(isAuthenticated.role === "admin"){
                navigate(`/admin/dashboard`);
            }
        }
        else{
            navigate(`/hospital/${hospitalId}`);
        }
      }
      else{
        navigate("/error");
      }   
    }

    }, [hospitalId, loading, isAuthenticated]);



    if (loading && subdomain !== "averyshub") return <div className='subdomain-fetching'>Fetching {subdomain} Facility Details...</div>;
    if(error && subdomain !== "averyshub") {
        navigate("/error");
    }

  return (
    <div className="landing-page">
      <header>
        <nav>
          <div className="logo">AVERY'S HUB</div>
          <ul>
            {/* <li><a href="#features">Features</a></li>
            <li><a href="#pricing">Pricing</a></li>
            <li><a href="#testimonials">Testimonials</a></li> */}
            <li><Link to="/admin/login">Login</Link></li>
          </ul>
        </nav>
      </header>

      <main>
        <section className="hero">
          <h1>Transform Your Hospital Operations in Minutes</h1>
          <p style={{color: "white"}} className='para'>Get your hospital online with our all-in-one management platform</p>
          <Link to="/admin/login" className="cta-button">Get Started</Link>
        </section>

        <section id="features">
          <h2>Powerful Features for Modern Healthcare</h2>
          <div className="feature-grid">
            <div className="feature-item">
              <h3>Smart Scheduling</h3>
              <p>Effortlessly manage appointments and reduce no-shows with automated reminders</p>
            </div>
            <div className="feature-item">
              <h3>Patient Management</h3>
              <p>Maintain comprehensive patient records and history in one secure location</p>
            </div>
            <div className="feature-item">
              <h3>Billing & Invoicing</h3>
              <p>Streamline your financial operations with integrated billing and payment processing</p>
            </div>
            <div className="feature-item">
              <h3>Telemedicine</h3>
              <p>Conduct virtual consultations seamlessly within our platform</p>
            </div>
          </div>
        </section>

        <section id="pricing">
          <h2>Simple, Transparent Pricing</h2>
          <div className="pricing-plans">
            <div className="plan">
              <h3>Starter</h3>
              <p className="price">$99/month</p>
              <ul>
                <li>Up to 5 Provider</li>
                <li>1000 patients</li>
                <li>Basic reporting</li>
              </ul>
              <Link to="/signup" className="plan-button">Choose Plan</Link>
            </div>
            <div className="plan featured">
              <h3>Professional</h3>
              <p className="price">$199/month</p>
              <ul>
                <li>Up to 15 Providers</li>
                <li>Unlimited patients</li>
                <li>Advanced analytics</li>
                <li>Priority support</li>
              </ul>
              <Link to="/signup" className="plan-button">Choose Plan</Link>
            </div>
            <div className="plan">
              <h3>Enterprise</h3>
              <p className="price">Custom</p>
              <ul>
                <li>Unlimited Providers</li>
                <li>Custom integrations</li>
                <li>Dedicated account manager</li>
              </ul>
              <Link to="/contact" className="plan-button">Contact Us</Link>
            </div>
          </div>
        </section>

        <section id="testimonials">
          <h2>What Our Clients Say</h2>
          <div className="testimonial-grid">
            <div className="testimonial">
              <p>"Avery's Hub has revolutionized how we manage our hospital. It's intuitive and powerful."</p>
            </div>
            <div className="testimonial">
              <p>"The efficiency gains we've seen with Avery's Hub are remarkable. Highly recommended!"</p>
            </div>
          </div>
        </section>
      </main>

      <footer>
        <div className="footer-content">
          <div className="footer-section">
            <h4>Company</h4>
            <ul>
              <li><Link to="/about">About Us</Link></li>
              {/* <li><Link to="/careers">Careers</Link></li> */}
              {/* <li><Link to="/contact">Contact</Link></li> */}
            </ul>
          </div>
          <div className="footer-section">
            <h4>Legal</h4>
            <ul>
              <li><Link to="/privacy-policy">Privacy Policy</Link></li>
              {/* <li><Link to="/terms">Terms of Service</Link></li> */}
            </ul>
          </div>
          {/* <div className="footer-section">
            <h4>Connect</h4>
            <ul>
              <li><a href="#">Twitter</a></li>
              <li><a href="#">LinkedIn</a></li>
            </ul>
          </div> */}
        </div>
        <div className="copyright">
          © 2024 Avery'sHub. All rights reserved.
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;