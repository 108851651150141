import React from 'react';
import './index.css';
import { FaFacebookSquare, FaInstagramSquare, FaLinkedin, FaGithubSquare, FaPhoneAlt, FaEnvelope } from "react-icons/fa";

const TopHeader = () => {
    return (
        <div id="topbar" className="d-flex align-items-center fixed-top">
            {/* <div className="container d-flex justify-content-between">
                <div className="contact-info d-flex align-items-center">
                    Avery's Hub
                    {/* <Fahello className='contact-icon'/> <a href="mailto:ujjalzaman@example.com">Avery's Hub</a> */}
            {/* <FaPhoneAlt className='contact-icon'/> <a href="tel:+88 01751 040425">+91 9875699506</a>  */}
        {/* </div> */}
             
            {/* </div > */}
        </div >
    );
};
export default TopHeader;