import React, { useEffect, useState } from "react";
import { FaCheck, FaEnvelope, FaLock, FaPhone, FaTimes, FaUser } from "react-icons/fa";
import Spinner from "react-bootstrap/Spinner";
import swal from "sweetalert";
import { useAdminSignUpMutation } from "../../redux/api/authApi";
import { message } from "antd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./SignUp.css"
const SignUp = ({ setSignUp }) => {
  const [error, setError] = useState({});
  const [infoError, setInfoError] = useState("");
  const [loading, setLoading] = useState(false);
  const formField = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    role: "admin",
  };
  const [user, setUser] = useState(formField);
  const [userType, setUserType] = useState("admin");

  const [
    adminSignUp,
    { data: aData, isSuccess: aIsSuccess, isError: aIsError, error: aError, isLoading: aIsLoading },
  ] = useAdminSignUpMutation();

  const [passwordValidation, setPasswordValidation] = useState({
    carLength: false,
    specialChar: false,
    upperLowerCase: false,
    numeric: false,
  });

  const handleSignUpSuccess = () => {
    setLoading(false);
    setUser(formField);
  };

  useEffect(() => {
    if (aIsError && aError) {
      message.error("Email Already Exist !!");
      setLoading(false);
    }

    if (!aIsError && aIsSuccess) {
      message.success("Email Sent Successfully !!");
      handleSignUpSuccess();
      setLoading(false);
      swal({
        icon: "success",
        text: "Successfully Account Created. Please Verify Your Email.",
        timer: 5000,
      });
    }
  }, [aIsError, aError, aIsLoading, aData, setSignUp, setLoading, aIsSuccess]);

  const [emailError, setEmailError] = useState({
    emailError: false,
  });

  const handleEmailError = (name, value) => {
    if (name === "email") {
      setEmailError({
        emailError: /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
      });
    }
  };

  const handleValidation = (name, value) => {
    if (name === "password") {
      setPasswordValidation({
        carLength: value.length >= 8,
        specialChar: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(value),
        upperLowerCase: /(?=.*[a-z])(?=.*[A-Z])/.test(value),
        numeric: /\d/.test(value),
      });
    }
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));

    handleValidation(name, value);
    handleEmailError(name, value);
  };

  const handlePhoneChange = (value) => {
    console.log("phone no value" ,value);
    
    setUser((prevUser) => ({
      ...prevUser,
      phone: value,
    }));
  };

  const handleUserTypeChange = (e) => {
    const newRole = e.target.value;
    setUserType(newRole);
    setUser((prevUser) => ({
      ...prevUser,
      role: newRole,
    }));
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    const updatedUser = {
      ...user,
      phone: `${user.phone}`, // Merge country code and phone number
      role: "admin",
    };

    setLoading(true);
    console.log("updatedUser", updatedUser);

    adminSignUp(updatedUser);
  };

  return (
    <form className="sign-up-form" onSubmit={handleOnSubmit}>
      <h2 className="title">Sign Up</h2>
      <div className="input-field">
        <span className="fIcon">
          <FaUser />
        </span>
        <input
          placeholder="First Name"
          name="firstName"
          type="text"
          onChange={handleOnChange}
          value={user.firstName}
        />
      </div>
      <div className="input-field">
        <span className="fIcon">
          <FaUser />
        </span>
        <input
          placeholder="Last Name"
          name="lastName"
          type="text"
          onChange={handleOnChange}
          value={user.lastName}
        />
      </div>
      <div className="input-field">
        <span className="fIcon">
          <FaEnvelope />
        </span>
        <input
          placeholder="Email"
          name="email"
          type="email"
          onChange={handleOnChange}
          value={user.email}
        />
      </div>
      <div className="input-field">
        <span className="fIcon">
          <FaLock />
        </span>
        <input
          type="password"
          name="password"
          placeholder="Password"
          onChange={handleOnChange}
          value={user.password}
        />
      </div>
      <div className="country-code-field">
        <PhoneInput
          country={"in"} // Set default country
          value={user.phone}
          onChange={handlePhoneChange}
          inputProps={{
            name: "phone",
            required: true,
            autoFocus: false,
          }}
          containerStyle={{ width: "380px", display: "flex", alignItems: "center", borderRadius: "30px", }}
          inputStyle={{ width: "380px", display: "flex", justifyContent: "center", alignItems: "center", height: "50px", borderRadius: "30px", backgroundColor: "#F0F0F0" }}
          buttonStyle={{ borderTopLeftRadius: "20px", borderBottomLeftRadius: "20px", backgroundColor: "white" }}
        />
      </div>
      {/* <div className="input-field">
        <span className="fIcon">
          <FaPhone />
        </span>
        <input
          type="text"
          name="phone"
          placeholder="Phone No."
          onChange={(e) => handleOnChange(e)}
          value={user.phone}
        />
      </div> */}
      <div className="input-field d-flex align-items-center gap-2 justify-content-center">
        <div className="text-nowrap">I'M A</div>
        <select
          className="form-select w-50"
          aria-label="select"
          onChange={handleUserTypeChange}
          defaultValue="admin"
        >
          <option value="admin">Admin</option>
        </select>
      </div>
      {error.length && <h6 className="text-danger text-center">{error}</h6>}
      {infoError && <h6 className="text-danger text-center">{infoError}</h6>}
      <button
        type="submit"
        className="btn btn-primary btn-block mt-2 iBtn"
        disabled={
          !(passwordValidation.carLength &&
            passwordValidation.numeric &&
            passwordValidation.upperLowerCase &&
            passwordValidation.specialChar &&
            emailError.emailError)
        }
      >
        {loading ? <Spinner animation="border" variant="info" /> : "Sign Up"}
      </button>
      <div className="password-validatity mx-auto">
        <div
          style={emailError.emailError ? { color: "green" } : { color: "red" }}
        >
          <p>
            {passwordValidation.numeric ? <FaCheck /> : <FaTimes />}
            <span className="ms-2">Must Have Valid Email.</span>
          </p>
        </div>

        <div
          style={
            passwordValidation.carLength ? { color: "green" } : { color: "red" }
          }
        >
          <p>
            {passwordValidation.numeric ? <FaCheck /> : <FaTimes />}
            <span className="ms-2">Password Must Have atlast 8 character.</span>
          </p>
        </div>

        <div
          style={
            passwordValidation.numeric
              ? { color: "green" } : { color: "red" } 
          }
        >
          <p>
            {passwordValidation.numeric ? <FaCheck /> : <FaTimes />}
            <span className="ms-2">Password Must Have a special character.</span>
          </p>
        </div>

        <div
          style={
            passwordValidation.upperLowerCase
              ? { color: "green" }
              : { color: "red" }
          }
        >
          <p>
            {passwordValidation.numeric ? <FaCheck /> : <FaTimes />}
            <span className="ms-2">
              Password Must Have uppercase and lower case.
            </span>
          </p>
        </div>

        <div
          style={
            passwordValidation.numeric ? { color: "green" } : { color: "red" }
          }
        >
          <p>
            {passwordValidation.numeric ? <FaCheck /> : <FaTimes />}
            <span className="ms-2">Password Must Have Number.</span>
          </p>
        </div>
      </div>

      {/* <p className="social-text">Or Sign up with social account</p>
      <SocialSignUp /> */}
    </form>
  );
};

export default SignUp;
