import React, { useRef, useEffect, useState } from "react";
import AdminLayout from "../AdminLayout/AdminLayout";
import "./Dashboard.css";
import { useNavigate } from "react-router-dom";
import { getUserIdFromToken } from "../../../utils/local-storage";
import { useGetHospitalIdFromUserIdQuery } from "../../../redux/api/hospitalApi";
import { useGetAllPatientsQuery } from "../../../redux/api/patientApi";
import { useGetDoctorsQuery } from "../../../redux/api/doctorApi";
import { useGetAdminAppointmentsQuery, useGetAdminInvoicesQuery } from "../../../redux/api/appointmentApi";
import { Spin, Card } from "antd";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import moment from "moment";


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const options = {
  fill: true,
  responsive: true,
  scales: {
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: 'Transaction Amount'
      }
    },
    x: {
      title: {
        display: true,
        text: 'Date'
      },
      ticks: {
        maxTicksLimit: 10
      }
    }
  },
  plugins: {
    legend: {
      display: true,
    },
    tooltip: {
      callbacks: {
        title: (tooltipItems) => {
          return moment(tooltipItems[0].label).format('DD MMM YYYY');
        }
      }
    }
  },
};

const AdminDashboard = () => {
  const [patientsNo, setPatientsNo] = useState(0);
  const [doctorsNo, setDoctorsNo] = useState(0);
  const [appointmentsNo, setAppointmentsNo] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const navigate = useNavigate();
  const userId = getUserIdFromToken();
  const [isDisabled, setIsDisabled] = useState(false);
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState({
    datasets: [],
  });

  const { data: hospitalData, isLoading: hospitalLoading } = useGetHospitalIdFromUserIdQuery(userId);
  const hospitalId = hospitalData?.id;
  const id = hospitalId;

  const { data: patientsData } = useGetAllPatientsQuery(hospitalId);
  const { data: doctorsData } = useGetDoctorsQuery({ id }, { skip: !id });
  const { data: patientAppointments } = useGetAdminAppointmentsQuery(userId);
  const { data: transactionsData, isLoading: transactionsLoading } = useGetAdminInvoicesQuery(userId);

  useEffect(() => {
    if (hospitalData && Object.keys(hospitalData).length > 0) {
      setIsDisabled(true);
    }
  }, [hospitalData]);

  useEffect(() => {
    if (patientAppointments) {
      setAppointmentsNo(patientAppointments.length);
    }
  }, [patientAppointments]);

  useEffect(() => {
    if (doctorsData && doctorsData.doctors) {
      setDoctorsNo(doctorsData.doctors.length);
    }
    if (!id) {
      navigate("/admin/dashboard");
    }
  }, [doctorsData, id, navigate]);

  useEffect(() => {
    if (patientsData && patientsData.length > 0) {
      setPatientsNo(patientsData.length);
    }
    if (!hospitalId) {
      navigate("/admin/dashboard");
    }
  }, [patientsData, hospitalId, navigate]);

  useEffect(() => {
    if (!transactionsData || transactionsLoading) return;

    const chart = chartRef.current;
    if (!chart) return;

    const processedData = processTransactionData(transactionsData);
    const totalRev = processedData.amounts.reduce((sum, amount) => sum + amount, 0);
    setTotalRevenue(totalRev);

    function createGradientColor(color) {
      const ctx = chart.ctx;
      const gradient = ctx.createLinearGradient(0, 0, 0, 400);
      gradient.addColorStop(0, color);
      gradient.addColorStop(0.99, "rgba(255,255,255,0.6)");
      gradient.addColorStop(1, "rgba(255,255,255,0.6)");
      return gradient;
    }

    setChartData({
      datasets: [
        {
          key: 'transactions',
          label: "Daily Transaction Amount ($)",
          data: processedData.amounts,
          tension: 0.3,
          borderColor: "rgb(75, 192, 192)",
          pointRadius: 4,
          pointBackgroundColor: "rgb(75, 192, 192)",
          backgroundColor: createGradientColor("rgba(75, 192, 192, 0.3)"),
          segment: {
            borderColor: "#05caed",
            backgroundColor: "#05caed"
          },
        }
      ],
      labels: processedData.labels,
    });
  }, [transactionsData, transactionsLoading]);

  const processTransactionData = (transactions) => {
    if (!transactions || transactions.length === 0) {
      return { labels: [], amounts: [] };
    }

    const sortedTransactions = [...transactions]
      .filter(transaction => transaction.paymentStatus !== "refunded")
      .sort((a, b) => new Date(a.paymentDate) - new Date(b.paymentDate));
    const groupedData = sortedTransactions.reduce((acc, transaction) => {
      const day = moment(transaction.paymentDate).format('YYYY-MM-DD');
      acc[day] = (acc[day] || 0) + parseFloat(transaction.totalAmount) / 100;  // Divide amount by 100 here
      return acc;
    }, {});

    const labels = Object.keys(groupedData).map(date => moment(date).format('DD MMM YYYY'));
    const amounts = Object.values(groupedData);

    return { labels, amounts };
  };
  const websiteUrl = hospitalData?.website;

  return (
    <AdminLayout>
      <div className="row">
        <div className="col-xl-3 col-sm-6 col-12">
          <Card>
            <div className="dash-widget-header">
              <span className="dash-widget-icon text-primary">
                <i className="fe fe-users">
                  <img className="svg-rounded" src="https://www.svgrepo.com/show/429791/doctor-human.svg" alt="doctor-img" />
                </i>
              </span>
              <div className="dash-count">
                <h3>{hospitalLoading ? <Spin size="small" /> : doctorsNo}</h3>
              </div>
            </div>
            <div className="dash-widget-info">
              <h6 className="text-muted">Providers</h6>
            </div>
          </Card>
        </div>
        <div className="col-xl-3 col-sm-6 col-12">
          <Card>
            <div className="dash-widget-header">
              <span className="dash-widget-icon text-success">
                <i className="fe fe-users">
                  <img className="svg-rounded" src="https://www.svgrepo.com/show/284208/patient.svg" alt="patient-img" />
                </i>
              </span>
              <div className="dash-count">
                <h3>{hospitalLoading ? <Spin size="small" /> : patientsNo}</h3>
              </div>
            </div>
            <div className="dash-widget-info">
              <h6 className="text-muted">Patients</h6>
            </div>
          </Card>
        </div>
        <div className="col-xl-3 col-sm-6 col-12">
          <Card>
            <div className="dash-widget-header">
              <span className="dash-widget-icon text-danger">
                <i className="fe fe-calendar">
                  <img className="svg-rounded-ap" src="https://www.svgrepo.com/show/235668/appointment-dentist.svg" alt="appointment-img" />
                </i>
              </span>
              <div className="dash-count">
                <h3>{hospitalLoading ? <Spin size="small" /> : appointmentsNo}</h3>
              </div>
            </div>
            <div className="dash-widget-info">
              <h6 className="text-muted">Appointments</h6>
            </div>
          </Card>
        </div>
        <div className="col-xl-3 col-sm-6 col-12">
          <Card>
            <div className="dash-widget-header">
              <span className="dash-widget-icon text-warning">
                <i className="fe fe-money">
                  <img className="svg-rounded" src="https://www.svgrepo.com/show/250754/ecommerce-money.svg" alt="revenue-img" />
                </i>
              </span>
              <div className="dash-count">
                <h3>${totalRevenue.toFixed(2)}</h3>
              </div>
            </div>
            <div className="dash-widget-info">
              <h6 className="text-muted">Total Revenue</h6>
            </div>
          </Card>
        </div>
        <div>
            <div className="website-info-card">
              <div className="card-header">
                <h4 className="card-title">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <circle cx="12" cy="12" r="10" />
                    <line x1="2" y1="12" x2="22" y2="12" />
                    <path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z" />
                  </svg>
                  Website
                </h4>
              </div>
              <div className="card-content">
                {websiteUrl ? (
                  <a
                    href={`https://${websiteUrl}.averyshub.com`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="website-link"
                  >
                    {websiteUrl}.averyshub.com
                  </a>
                ) : (
                  <span className="no-website">No active site</span>
                )}
              </div>
            </div>
          </div>
      </div>
      <div className="row chart">
        <div className="col-12">
          <Card>
            <h4 className="card-title">Monthly Transactions</h4>
            {transactionsLoading ? (
              <Spin size="large" />
            ) : (
              <Line data={chartData} options={options} ref={chartRef} />
            )}
          </Card>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminDashboard;