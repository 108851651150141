import DashboardLayout from "../DashboardLayout/DashboardLayout";
import CustomTable from "../../UI/component/CustomTable";
import { Button, Tag, message } from "antd";
import { FaRegEye, FaEdit, FaRegTimesCircle } from "react-icons/fa";
import dayjs from "dayjs";
import { Link, useParams } from "react-router-dom";
import {
  useDeletePrescriptionMutation,
  useGetDoctorPrescriptionQuery,
  useGetPrescriptionQuery,
} from "../../../redux/api/prescriptionApi";

const AllDoctorPrescription = () => {
  const { id } = useParams();
  const { prescriptionId } = useParams();
  // const { data, isLoading } = useGetPrescriptionQuery(prescriptionId);
  const { data, isLoading, isError } = useGetDoctorPrescriptionQuery();
  const [deletePrescription] = useDeletePrescriptionMutation();
  console.log("data from AllDoctorPrescription", data);
  const columns = [
    {
      title: "PrescriptionId Id",
      dataIndex: "id",
      key: 1,
      render: (id) => (
        <Tag color="#f50">{id.slice(0, 15)}...</Tag>
      ),
    },
    {
      title: "Disease",
      dataIndex: "disease",
      key: 2,
    },
    {
      title: "Follow-Update",
      dataIndex: "followUpdate",
      key: 3,
      render: (followUpdate) => (
        <Tag color="#87d068">
          {dayjs(followUpdate).format("MMM D, YYYY hh:mm A")}
        </Tag>
      ),
    },
    {
      title: "Archived",
      dataIndex: "isArchived",
      key: 4,
      render: (isArchived) => (
        <Tag color={isArchived ? "#f50" : "#108ee9"}>
          {isArchived ? "Yes" : "Under Treatment"}
        </Tag>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: 5,
      render: (createdAt) => dayjs(createdAt).format("MMM D, YYYY hh:mm A"),
    },
    {
      title: "Action",
      key: 6,
      render: (record) => (
        <div className="d-flex">
          <Link to={`/doctor/dashboard/prescription/view/${id}/${record.id}`}>
            <Button
              type="primary"
              size="small"
              className="bg-primary"
              style={{ margin: "5px 5px" }}
            >
              <FaRegEye />
            </Button>
          </Link>
          <Link
            to={`/doctor/dashboard/appointment/treatment/edit/${id}/${record.id}`}
          >
            <Button
              type="primary"
              size="small"
              className="bg-primary"
              style={{ margin: "5px 5px" }}
            >
              <FaEdit />
            </Button>
          </Link>
          <Button
            onClick={() => deleteHandler(record.id)}
            size="small"
            type="primary"
            style={{ margin: "5px 5px" }}
            danger
          >
            <FaRegTimesCircle />
          </Button>
        </div>
      ),
    },
  ];

  const deleteHandler = async (id) => {
    message.loading("Deleting ...");
    try {
      const res = await deletePrescription({id});
      if (res?.data) {
        message.success("Successfully Deleted !!");
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <DashboardLayout>
      <div className="w-100 mb-3 rounded" style={{ background: "#f8f9fa" }}>
        <CustomTable
          loading={isLoading}
          columns={columns}
          dataSource={data || []} // Pass the fetched data directly
          showPagination={true}
          pageSize={20}
          showSizeChanger={true}
        />
      </div>
    </DashboardLayout>
  );
};

export default AllDoctorPrescription;
