import React from 'react';
import './PrivacyPolicy.css';
import { Header } from 'antd/es/layout/layout';
import SubHeader from '../Shared/SubHeader';

const PrivacyPolicy = () => {
  return (
    <>
    <Header />
    <SubHeader title="Our Privacy Policy" subtitle="This Privacy Policy explains how we, Avery's Hub protect the privacy of personal information" />
    <div className="privacy-policy-container">
      <h1 className="privacy-policy-title">Privacy Policy</h1>
      <p className="privacy-policy-paragraph">
        At Avery's Hub, we are committed to protecting the privacy and confidentiality of your personal information. This Privacy Policy explains how we collect, use, and safeguard the data you provide to us while using our hospital management system.
      </p>

      <h2 className="privacy-policy-subtitle">Information We Collect</h2>
      <p className="privacy-policy-paragraph">
        We collect two main types of information from our users:
      </p>
      <ul className="privacy-policy-list">
        <li className="privacy-policy-list-item">
          <strong>Personal Data</strong> - This includes information that can be used to identify an individual, such as name, email address, phone number, and medical records.
        </li>
        <li className="privacy-policy-list-item">
          <strong>Usage Data</strong> - This is information about how you use our platform, including page visits, device information, and other usage metrics.
        </li>
      </ul>

      <h2 className="privacy-policy-subtitle">How We Use Your Data</h2>
      <p className="privacy-policy-paragraph">
        We use the information we collect to provide and improve our hospital management system, communicate with you, and comply with legal and regulatory requirements. Specifically, we may use your data for the following purposes:
      </p>
      <ul className="privacy-policy-list">
        <li className="privacy-policy-list-item">
          Delivering our services and features
        </li>
        <li className="privacy-policy-list-item">
          Responding to your inquiries and providing customer support
        </li>
        <li className="privacy-policy-list-item">
          Enhancing the security and performance of our platform
        </li>
        <li className="privacy-policy-list-item">
          Complying with legal and regulatory requirements
        </li>
        <li className="privacy-policy-list-item">
          Improving and developing new features and services
        </li>
      </ul>

      <h2 className="privacy-policy-subtitle">Data Sharing and Transfers</h2>
      <p className="privacy-policy-paragraph">
        We may share your personal data with trusted third-party service providers who assist us in operating our platform, but we do not sell or rent your data to any third parties. If we are involved in a merger, acquisition, or asset sale, we will protect the confidentiality of your data.
      </p>
      <p className="privacy-policy-paragraph">
        Some of our service providers may be located in countries outside of your home country. When we transfer data internationally, we ensure that appropriate safeguards are in place to protect your personal information.
      </p>

      <h2 className="privacy-policy-subtitle">Your Rights and Choices</h2>
      <p className="privacy-policy-paragraph">
        You have the right to access, correct, and delete your personal data, as well as the right to object to or restrict the processing of your data. You can exercise these rights by contacting our Data Protection Officer at dpo@averyshub.com.
      </p>
      <p className="privacy-policy-paragraph">
        If you are located in the European Economic Area, the United Kingdom, or Switzerland, you also have the right to lodge a complaint with your local data protection authority.
      </p>

      <h2 className="privacy-policy-subtitle">Changes to this Policy</h2>
      <p className="privacy-policy-paragraph">
        We may update this Privacy Policy from time to time to reflect changes in our practices or applicable laws. We will notify you of any material changes by posting the updated policy on our website or by other means.
      </p>

      <h2 className="privacy-policy-subtitle">Contact Us</h2>
      <p className="privacy-policy-paragraph">
        If you have any questions or concerns about our privacy practices, please contact our Data Protection Officer at dpo@averyshub.com.
      </p>
    </div>
    </>
  );
};

export default PrivacyPolicy;