import { Popover } from "antd";
import { NavLink, useParams } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { Drawer, Button } from "antd";

const HeaderNav = ({ open, setOpen, isLoggedIn, data, avatar, content }) => {
  const { id } = useParams();

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const renderNavItems = () => (
    <>
      {isLoggedIn && data && data.role === "patient" && (
        <li>
          <NavLink
            to={`/about/${id}`}
            className={({ isActive }) =>
              isActive ? "nav-link scrollto active" : "nav-link scrollto"
            }
          >
            About
          </NavLink>
        </li>
      )}
      {!isLoggedIn && (
        <li>
          <NavLink
            to={id ? `/hospital/${id}` : `/admin/login`}
            className={({ isActive }) =>
              isActive ? "nav-link scrollto active" : "nav-link scrollto"
            }
          >
            Login
          </NavLink>
        </li>
      )}
      {isLoggedIn && (
        <li>
          <NavLink
            to={
              data && data.role === "patient"
                ? `/dashboard/${id}`
                : `/doctor/dashboard/${id}`

            }
            className={({ isActive }) =>
              isActive ? "nav-link scrollto active" : "nav-link scrollto"
            }
          >
            Dashboard
          </NavLink>
        </li>
      )}
      {isLoggedIn && data && data.role === "patient" && (
        <li>
          <NavLink
            to={`/doctors/${id}`}
            className={({ isActive }) =>
              isActive ? "nav-link scrollto active" : "nav-link scrollto"
            }
          >
            Providers
          </NavLink>
        </li>
      )}
      {isLoggedIn && data && data.role === "patient" && (
        <li>
          <NavLink
            to={`/solutions/${id}`}
            className={({ isActive }) =>
              isActive ? "nav-link scrollto active" : "nav-link scrollto"
            }
          >
            Solutions
          </NavLink>
        </li>
      )}
      {/* {isLoggedIn && data.role == "patient" (
        <li>
          <NavLink
            to={data && data.role === "admin" ? `/contact` : `/contact/${id}`}
            className={({ isActive }) =>
              isActive ? "nav-link scrollto active" : "nav-link scrollto"
            }
          >
            Contact
          </NavLink>
        </li>
      )} */}
    </>
  );

  return (
    <>
      <nav id="navbar" className="navbar order-last order-lg-0">
        <ul>{renderNavItems()}</ul>
        <div>
          {isLoggedIn && data && data?.role === "patient" ? (
            <div className="profileImage">
              <img
                src={data?.img ? data?.img : avatar}
                alt=""
                className="profileImage shadow img-fluid"
              />
            </div>
          ) : (
            <Popover content={content}>
              <div className="profileImage">
                <img
                  src={data?.img ? data?.img : avatar}
                  alt=""
                  className="profileImage shadow img-fluid"
                />
              </div>
            </Popover>
          )}
        </div>
        <FaBars className="mobile-nav-toggle" onClick={showDrawer} />
      </nav>
      <Drawer
        placement={"left"}
        width={500}
        onClose={onClose}
        open={open}
        size={"default"}
        extra={
          <Button type="primary" onClick={onClose}>
            Close
          </Button>
        }
      >
        <ul className="mobile-menu-nav">{renderNavItems()}</ul>
      </Drawer>
    </>
  );
};

export default HeaderNav;