import React from 'react'
import DoctorSchedule from './DoctorSchedule'
import DoctorAppointment from './DoctorAppointment'
import AdminLayout from '../AdminLayout/AdminLayout'
import "./ScheduleDoctor.css" 
const ScheduleDoctor = () => {
  return (
    <AdminLayout>
    <p className='scheduleText'>Provider's Time Slots</p>
    <DoctorSchedule />
    <p className='appointmentText'>Appointment Schedules</p>
    <DoctorAppointment />
    </AdminLayout>
  )
}

export default ScheduleDoctor