import { useGetDoctorAppointmentsByIdQuery } from "../../../redux/api/appointmentApi";
import React, { useEffect, useState } from "react";
import {
  DayPilot,
  DayPilotCalendar,
  DayPilotMonth,
  DayPilotNavigator
} from "@daypilot/daypilot-lite-react";
import "./DashboardLayout.css";
import { Spin } from "antd";
import { useNavigate, useParams } from "react-router-dom";

const DoctorAppointment = () => {
  const navigate = useNavigate();
  const [view, setView] = useState("Week");
  const [startDate, setStartDate] = useState(DayPilot.Date.today());
  const [events, setEvents] = useState([]);
  const { doctorId } = useParams();

  const { data, isLoading } = useGetDoctorAppointmentsByIdQuery(doctorId);
  useEffect(() => {
    if (data && data.length) {
      // Helper function to format time
      const formatTime = (timeStr) => {
        const [hour, minute] = timeStr.split("T")[1].split(":");
        const period = parseInt(hour) >= 12 ? "PM" : "AM";
        const formattedHour = parseInt(hour) % 12 || 12;
        return `${formattedHour}:${minute} ${period}`;
      };
  
      // Sort slots by start time
      const sortedSlots = data
        .map((slot) => {
          const datePart = slot.scheduleDate.includes("T")
            ? slot.scheduleDate.split("T")[0]
            : slot.scheduleDate;
  
          const [time, period] = slot.scheduleTime.split(" ");
          const [hours, minutes] = time.split(":");
          const adjustedHours =
            period.toUpperCase() === "PM" && hours !== "12"
              ? parseInt(hours) + 12
              : period.toUpperCase() === "AM" && hours === "12"
              ? "00"
              : hours.padStart(2, "0");
  
          const startTime = `${datePart}T${adjustedHours}:${minutes}:00`;
  
          let endHours = parseInt(adjustedHours);
          let endMinutes = parseInt(minutes) + 15;
  
          if (endMinutes >= 60) {
            endHours += 1;
            endMinutes = endMinutes % 60;
          }
  
          const formattedEndHours = endHours.toString().padStart(2, "0");
          const formattedEndMinutes = endMinutes.toString().padStart(2, "0");
          const endTime = `${datePart}T${formattedEndHours}:${formattedEndMinutes}:00`;
  
          return {
            id: slot.id,
            start: startTime,
            end: endTime,
            description: slot.description,
            text: `${formatTime(startTime)} - ${formatTime(endTime)}`,
          };
        })
        .sort((a, b) => new Date(a.start) - new Date(b.start));
  
      // Merge consecutive slots
      const mergedEvents = [];
      let currentSlot = null;
  
      sortedSlots.forEach((slot) => {
        if (currentSlot && new Date(currentSlot.end) >= new Date(slot.start)) {
          // Extend the end time of the current slot if overlapping or consecutive
          currentSlot.end = slot.end;
          currentSlot.text = `${formatTime(currentSlot.start)} - ${formatTime(currentSlot.end)}`;
        } else {
          if (currentSlot) {
            mergedEvents.push(currentSlot);
          }
          currentSlot = { ...slot };
        }
      });
  
      if (currentSlot) {
        mergedEvents.push(currentSlot);
      }
  
      setEvents(mergedEvents);
    }
  }, [data]);
  

  return (
    <>
      {isLoading ? (
        <Spin size="large" />
      ) : (
        <div className={"schedule-container"}>
          <div className={"schedule-navigator"}>
            <DayPilotNavigator
              selectMode={view}
              showMonths={1}
              skipMonths={1}
              onTimeRangeSelected={(args) => setStartDate(args.day)}
              events={events}
            />
          </div>
          <div className={"schedule-content"}>
            <div className={"schedule-toolbar"}>
              <div className={"schedule-toolbar-group"}>
                <button
                  onClick={() => setView("Day")}
                  className={view === "Day" ? "schedule-selected" : ""}
                >
                  Day
                </button>
                <button
                  onClick={() => setView("Week")}
                  className={view === "Week" ? "schedule-selected" : ""}
                >
                  Week
                </button>
                <button
                  onClick={() => setView("Month")}
                  className={view === "Month" ? "schedule-selected" : ""}
                >
                  Month
                </button>
              </div>
              <button
                onClick={() => setStartDate(DayPilot.Date.today())}
                className={"schedule-standalone"}
              >
                Today
              </button>
            </div>

            <div className={"schedule-calendar-container"}>
              <DayPilotCalendar
                viewType={"Day"}
                startDate={startDate}
                events={events}
                visible={view === "Day"}
                durationBarVisible={false}
                scale={"CellDuration"}
                cellDuration={15} // Sets 15-minute intervals for Day view
              />
              <DayPilotCalendar
                viewType={"Week"}
                startDate={startDate}
                events={events}
                visible={view === "Week"}
                durationBarVisible={false}
                scale={"CellDuration"}
                cellDuration={15} // Sets 15-minute intervals for Week view
              />
              <DayPilotMonth
                startDate={startDate}
                events={events}
                visible={view === "Month"}
                durationBarVisible={false}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DoctorAppointment;
