import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Select, Input, Button, message, Table, Spin } from "antd";
import AdminLayout from "../AdminLayout/AdminLayout";
import { Editor } from "./Editor";
import { getUserIdFromToken } from "../../../utils/local-storage";
import { useGetHospitalIdFromUserIdQuery } from "../../../redux/api/hospitalApi";
import { useGetDoctorsQuery } from "../../../redux/api/doctorApi";
import axios from "axios";

const { Option } = Select;

const AddSolution = () => {
  const siteTemplate = `# Renew Weight Medical Weight Loss
    ### Edit this template for your respective Solution

---

## Introduction

**Medical weight loss** is a program designed to help you lose weight under the supervision of healthcare experts. These professionals develop a personalized plan to suit your unique needs, offering motivation and support for successful weight loss and long-term maintenance.

---

## Overview

Medical weight loss programs include consultations, behavioral adjustments, and medications to aid weight loss. A healthcare professional assesses your weight loss history, current diet, and exercise habits to develop an effective plan.

## Common Struggles with Weight Loss

Popular diets often fail because they are quick fixes, and people revert to old habits after achieving their desired weight. This leads to the weight returning and can negatively impact health.

## Services and Programs

At Renew Weight, we offer comprehensive weight loss services, including:

- **Appetite Suppressants**: Pills prescribed to reduce appetite.
- **Weight Loss Injections**: Aids in losing and maintaining weight.
- **Forskolin Energy Injections**: Boosts metabolism, used for centuries.
- **Mega Injections**: Increase energy, promote weight loss.
- **B12 Injections**: Provides essential nutrients and energy.
- **Lipotropic Injections**: Helps shed the last few pounds.
- **HCG Diet**: HCG injections to accelerate metabolism.
- **AOD Diet Plan**: Peptide injections for fat burning and metabolism boost.

## Why Choose Renew Weight

Renew Weight offers personalized medical weight loss programs, designed by experienced healthcare professionals, to help you achieve your goals.

---

## Call to Action

Need a weight clinic? Request an appointment and start your weight loss journey today.

---

## Acknowledgments

This information is provided by the expert team at Renew Weight.

---

*Note: This is a template. Modify and customize it based on your specific needs.*
`;

  const [value, setValue] = useState(siteTemplate);
  const { control, handleSubmit, reset } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [solutions, setSolutions] = useState([]);
  const [solutionsLoading, setSolutionsLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  const userId = getUserIdFromToken();
  const { data: hospitalData } = useGetHospitalIdFromUserIdQuery(userId);
  const hospitalId = hospitalData?.id;

  const { data: doctorsData } = useGetDoctorsQuery({ id: hospitalId }, { skip: !hospitalId });

  useEffect(() => {
    const fetchSolutions = async () => {
      if (hospitalId) {
        try {
          setSolutionsLoading(true);
          const response = await axios.get(`${apiUrl}/solution/hospitalId/${hospitalId}`);
          console.log(response);
          setSolutions(Array.isArray(response.data.response) ? response.data.response : []);
        } catch (error) {
          console.error("Failed to fetch solutions:", error);
          message.error("Failed to fetch solutions");
        }
        finally{
          setSolutionsLoading(false);
        }
      }
    };

    fetchSolutions();
  }, [hospitalId]);

  const onSubmit = async (data) => {
    setIsLoading(true);
    const solutionData = {
      ...data,
      content: value,
      hospitalId: hospitalId,
    };

    try {
      const response = await axios.post(`${apiUrl}/solution/create`, solutionData);
      if (response.data) {
        message.success("Solution added successfully");
        reset();
        setValue(siteTemplate);
        // Refresh the solutions list
        const solutionsResponse = await axios.get(`${apiUrl}/solution/hospitalId/${hospitalId}`);
        setSolutions(Array.isArray(solutionsResponse.data) ? solutionsResponse.data : []);
      } else {
        message.error("Failed to add solution");
      }
    } catch (error) {
      console.error("Error adding solution:", error);
      message.error(error?.response?.data?.message || "Failed to add solution");
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (data) => {
    setValue(data);
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (price) => `$${price}`,
    },
    {
      title: 'Assigned Providers',
      dataIndex: 'providers',
      key: 'providers',
      render: (doctors) => Array.isArray(doctors) ? doctors.length : 0,
    },
  ];

  return (
    <AdminLayout>
      <div className="card mb-4">
        <div className="card-body">
          <h4 className="card-title">Existing Solutions</h4>
          {Array.isArray(solutions) && solutions.length > 0 ? (
            <Table dataSource={solutions} columns={columns} rowKey="id" />
          ) : (
            <p>{solutionsLoading ? <Spin /> : "No solutions found."}</p>
          )}
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">Add Solution</h4>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group mb-3">
              <label>Title</label>
              <Controller
                name="title"
                control={control}
                rules={{ required: "Title is required" }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <Input {...field} />
                    {error && <span className="text-danger">{error.message}</span>}
                  </>
                )}
              />
            </div>

            <div className="form-group mb-3">
              <label>Price (USD)</label>
              <Controller
                name="price"
                control={control}
                rules={{
                  required: "Price is required",
                  pattern: {
                    value: /^\d+(\.\d{1,2})?$/,
                    message: "Enter a valid price"
                  }
                }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <Input type="number" step="0.01" {...field} />
                    {error && <span className="text-danger">{error.message}</span>}
                  </>
                )}
              />
            </div>

            <div className="form-group mb-3">
              <label>Assign Providers</label>
              <Controller
                name="providerAssigned"
                control={control}
                rules={{ required: "Please assign at least one provider" }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <Select
                      {...field}
                      mode="multiple"
                      style={{ width: '100%' }}
                      placeholder="Select providers"
                    >
                      {Array.isArray(doctorsData?.doctors) && doctorsData.doctors.map(doctor => (
                        <Option key={doctor.id} value={doctor.id}>
                          {`${doctor.firstName} ${doctor.lastName}`}
                        </Option>
                      ))}
                    </Select>
                    {error && <span className="text-danger">{error.message}</span>}
                  </>
                )}
              />
            </div>

            <div className="form-group mb-3">
              <label>Content</label>
              <Editor value={value} handleChange={handleChange} />
            </div>

            <Button type="primary" htmlType="submit" loading={isLoading}>
              Add Solution
            </Button>
          </form>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AddSolution;