import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useCreateDoctorMutation } from "../../../redux/api/doctorApi";
import { message, DatePicker } from "antd";
import moment from "moment";
import ImageUpload from "../../UI/form/ImageUpload";
import "./AddDoctors.css";
import AdminLayout from "../AdminLayout/AdminLayout";
import { getUserIdFromToken } from "../../../utils/local-storage";
import { getUserInfo } from "../../../service/auth.service";
import { useGetHospitalIdFromUserIdQuery } from "../../../redux/api/hospitalApi";

const AddDoctor = () => {
  const userData = getUserInfo();
  console.log("userData from AddDoctor", userData);
  const { data: hospitalIdData, error: hospitalError } =
    useGetHospitalIdFromUserIdQuery(userData.userId);
  console.log("hospitalIdData", hospitalIdData);

  const { register, handleSubmit ,reset} = useForm({});
  const [date, setDate] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [file, setFile] = useState(null);
  //   const [URL, setURL] = useState("");
  const [createDoctor, { isLoading, error }] = useCreateDoctorMutation();

  const logFormData = (formData) => {
    for (const [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }
  };

  const onChange = (date, dateString) => {
    setDate(moment(dateString).format());
  };

  const onSubmit = async (data) => {
    // console.log(hospitalIdData);
    // console.log(!hospitalIdData?.hospitalId);
    
    // if (!hospitalIdData?.hospitalId) {
    //   message.error(
    //     "No hospital associated with this account. Please create a hospital first."
    //   );
    //   return;
    // }
    const formData = new FormData();
    if (file) {
      formData.append("file", file);
    }
    if (date) {
      data.dob = date;
    }
    const userId = getUserIdFromToken();
    if (userId) {
      data.adminId = userId;
      // data.hospitalId = hospitalIdData.hospitalId;
    }
    console.log("userId", userId);
    formData.append("data", JSON.stringify(data));

    try {
      console.log("data", data);

      logFormData(formData);

      const resp = await createDoctor(formData);
      console.log("resp", resp);
      if (resp.data) {
        reset();
        setDate(null);
        setSelectedImage(null);
        setFile(null);
        message.success("Provider added successfully");
        // setURL(resp.data);
      } else {
        message.error("Failed to add Provider");
      }
    } catch (error) {
      message.error(error?.data?.message || "Failed to add Provider");
    }
  };

  return (
    <AdminLayout>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Add Provider</h4>

              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-md-12 mb-4">
                    <div className="change-avatar d-flex gap-2 align-items-center">
                      <div className="doctor-img">
                        <img
                          src={
                            selectedImage || "https://via.placeholder.com/100"
                          }
                          alt="providers Avatar"
                          style={{
                            width: "100px",
                            height: "100px",
                            borderRadius: "50%",
                          }}
                        />
                      </div>
                      <div>
                        <ImageUpload
                          setSelectedImage={setSelectedImage}
                          setFile={setFile}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>
                        First Name <span className="text-danger">*</span>
                      </label>
                      <input
                        {...register("firstName", { required: true })}
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>
                        Last Name <span className="text-danger">*</span>
                      </label>
                      <input
                        {...register("lastName", { required: true })}
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>
                        Email <span className="text-danger">*</span>
                      </label>
                      <input
                        {...register("email", { required: true })}
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>Phone</label>
                      <input {...register("phone")} className="form-control" />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>Gender</label>
                      <select {...register("gender")} className="form-control">
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>Date of Birth</label>
                      <DatePicker
                        onChange={onChange}
                        format={"YYYY-MM-DD"}
                        style={{ width: "100%" }}
                        value={date ? moment(date) : null}
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label>Biography</label>
                      <textarea
                        {...register("biography")}
                        className="form-control"
                        rows="4"
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>Address</label>
                      <input
                        {...register("address")}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>City</label>
                      <input {...register("city")} className="form-control" />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>State</label>
                      <input {...register("state")} className="form-control" />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>Country</label>
                      <input
                        {...register("country")}
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>Postal Code</label>
                      <input
                        {...register("postalCode")}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>Services</label>
                      <input
                        {...register("services")}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>Charges (in USD) <span className="text-danger">*</span></label>
                      <input
                        {...register("price", {
                          required: true,
                          pattern: {
                            value: /^[0-9]+(\.[0-9]{1,2})?$/,
                            message: "Please enter a valid amount",
                          },
                        })}
                        type="number"
                        step="0.01"
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>Degree</label>
                      <input {...register("degree")} className="form-control" />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>College</label>
                      <input
                        {...register("college")}
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>Year of Completion</label>
                      <input
                        {...register("completionYear", {
                          pattern: {
                            value: /^[0-9]{4}$/,
                            message: "Please enter a valid year",
                          },
                        })}
                        type="number"
                        min="1900"
                        max={new Date().getFullYear()}
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>Experience (in years)</label>
                      <input
                        {...register("experience", {
                          required: true,
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Please enter a valid number",
                          },
                        })}
                        type="number"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={isLoading}
                    >
                      {isLoading ? "Adding..." : "Add Provider"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AddDoctor;
