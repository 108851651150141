export const appointStatusDsc = {
    payment: 'Payment Status: Set to "paid" to indicate that the payment has been successfully completed.',
    appointment: {
        FollowUp: 'Set to "Follow-up" to indicate that a follow-up appointment is needed with the doctor.',
        pending: 'Set to "pending" to show that the appointment has not yet been scheduled. The next step may involve scheduling the appointment.',
        scheduled: 'Set to "Scheduled" to indicate that the appointment has been officially scheduled.',
        Completed: 'Set to "Completed" to denote that the appointment has been successfully concluded.',
        Canceled: 'Set to "Canceled" to indicate that the appointment has been canceled by either the patient or the healthcare provider.',
        'Follow-up Scheduled': 'Set to "Follow-up Scheduled" to show that a follow-up appointment has been arranged.',
        cancel: 'Set to "Cancel" if the appointment cannot be completed for some reason.',
        archived: 'Set to "Archived" to denote that the appointment is closed and moved to historical records.',
        InProgress: 'Set to "In Progress" when the appointment is currently underway or ongoing.',
        confirmed: 'Set to "Confirmed" when both the patient and the doctor have agreed on the appointment details.',
    },
    followUpDate: 'Represents a specific date set for a follow-up appointment.',
    prescriptionStatus: {
        issued: 'Set to "issued" to indicate that a prescription has been created and given to the patient.',
        notIssued: 'Set to "not_issued" to indicate that a prescription has not been provided yet.'
    }
}
