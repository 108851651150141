import React, { useState, useEffect } from "react";
import AdminLayout from "../AdminLayout/AdminLayout";
import { useGetHospitalIdFromUserIdQuery } from "../../../redux/api/hospitalApi";
import { getUserIdFromToken } from "../../../utils/local-storage";
import { Table, Tag, Avatar, Spin } from "antd";
import { UserOutlined } from "@ant-design/icons";
import AppointmentDateRangePicker from "./AppointmentDateRangePicker";

const AdminAppointments = () => {
  const [loading, setLoading] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const userId = getUserIdFromToken();
  const { data: hospitalData, isLoading: hospitalLoading } = useGetHospitalIdFromUserIdQuery(userId);
  const apiurl = process.env.REACT_APP_API_BASE_URL;

  const fetchAppointments = async () => {
    try {
      setLoading(true);
      let url = `${apiurl}/appointment/admin/appointments/${userId}`;
      if (dateRange[0] && dateRange[1]) {
        url += `?startDate=${dateRange[0].toISOString()}&endDate=${dateRange[1].toISOString()}`;
      }
      const res = await fetch(url);
      const response = await res.json();
      if (response.success) {
        setAppointments(response.data);
      }
    } catch (error) {
      console.error("Error fetching appointments:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!hospitalLoading) {
      fetchAppointments();
    }
  }, [userId, hospitalLoading, dateRange]);

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
  };

  const columns = [
    {
      title: "Patient",
      dataIndex: "firstName",
      key: "patient",
      render: (_, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar icon={<UserOutlined />} style={{ marginRight: 8 }} />
          {`${record.firstName} ${record.lastName}`}
        </div>
      ),
    },
    {
      title: "Tracking ID",
      dataIndex: "trackingId",
      key: "trackingId",
    },
    {
      title: "Schedule",
      dataIndex: "scheduleDate",
      key: "schedule",
      render: (_, record) => {
        const date = new Date(record.scheduleDate).toISOString().split('T')[0];
        return (
          <>
            <div>{date}</div>
            <div style={{ color: "#1890ff" }}>{record.scheduleTime}</div>
          </>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag color={status === "scheduled" ? "green" : "blue"}>{status.toUpperCase()}</Tag>
      ),
    },
    {
      title: "Payment",
      dataIndex: "paymentStatus",
      key: "payment",
      render: (paymentStatus) => (
        <Tag color={paymentStatus === "paid" ? "green" : "red"}>{paymentStatus.toUpperCase()}</Tag>
      ),
    },
  ];

  return (
    <AdminLayout>
      <div style={{ padding: "24px" }}>
        <h2 style={{ marginBottom: "16px" }}>Appointments</h2>
        <AppointmentDateRangePicker onDateRangeChange={handleDateRangeChange} />
        {!loading ? (
          <Table
            columns={columns}
            dataSource={appointments}
            rowKey="id"
            pagination={{ pageSize: 10 }}
            scroll={{ x: true }}
          />
        ) : (
          <Spin size="large" />
        )}
      </div>
    </AdminLayout>
  );
};

export default AdminAppointments;

