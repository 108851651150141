import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './solution.css'; // Ensure you have the CSS file
import Footer from '../../Shared/Footer/Footer';
import Header from '../../Shared/Header/Header';
import SubHeader from '../../Shared/SubHeader';
import { Spin } from 'antd';

export const Solutions = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [solutions, setSolutions] = useState([]);

  useEffect(() => {
    const fetchSolutions = async () => {
      const token = localStorage.getItem('accessToken');
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };
      const api_url = process.env.REACT_APP_API_BASE_URL;

      try {
        const response = await axios.get(`${api_url}/solution/hospitalId/${id}`, { headers });
        setSolutions(response.data.response);
      } catch (error) {
        console.error('Error fetching solutions:', error);
      }
    };

    fetchSolutions();
  }, [id]);

  const handleSolutionClick = (solutionId) => {
    navigate(`/solution/${id}/${solutionId}`);
  };

  return (
    <div>
      <Header />
      <SubHeader
        title="Solutions curated for You!!"
        subtitle="Search through our extensive list of personalized solutions to find the best fit for your needs."
      />
      <div className="container">
        <h1 className="heading">Available Solutions</h1>
        <div className="solutions-grid">
          {solutions.length > 0 ? solutions.map((solution) => (
            <div
              key={solution.id}
              className="solution-card"
            >
              <div className="card-content">
                <h2 className="solution-title">{solution.title}</h2>
                <p className="solution-price">Price: ${solution.price}</p>
                <p className="solution-date">
                  Created: {new Date(solution.createdAt).toLocaleDateString()}
                </p>
                <button
                  onClick={() => handleSolutionClick(solution.id)}
                  className="view-details-btn"
                >
                  View Details
                </button>
              </div>
            </div>
          )) :  <Spin />}
        </div>
      </div>
      <Footer />
    </div>
  );
};
