import { tagTypes } from "../tag-types";
import { baseApi } from "./baseApi";

const APPOINTMENT_URL = "/appointment";

export const appointmentApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    createAppointment: build.mutation({
      query: (data) => ({
        url: `${APPOINTMENT_URL}/create`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: [tagTypes.appointments],
    }),
    createAppointmentPayment: build.mutation({
      query: (data) => ({
        url: `${APPOINTMENT_URL}/create/payment`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: [tagTypes.appointments],
    }),
    createAppointmentByUnauthenticateUser: build.mutation({
      query: (data) => ({
        url: `${APPOINTMENT_URL}/create-un-authenticate`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: [tagTypes.appointments],
    }),
    trackAppointment: build.mutation({
      query: (data) => ({
        url: `${APPOINTMENT_URL}/tracking`,
        method: "POST",
        data: data,
      }),
    }),
    updateAppointment: build.mutation({
      query: ({ id, data }) => ({
        url: `${APPOINTMENT_URL}/${id}`,
        method: "PATCH",
        data: data,
      }),
      invalidatesTags: [tagTypes.appointments],
    }),
    rescheduleAppointment: build.mutation({
      query: ({ id, date }) => (
        {
        url: `${APPOINTMENT_URL}/reschedule/${id}/${date}`,
        method: "PATCH",
      }),
      invalidatesTags: [tagTypes.appointments],
    }),
    approveReschedule: build.mutation({
      query: ({ id, data }) => ({
        url: `${APPOINTMENT_URL}/approve-reschedule/${id}`,
        method: "PATCH",
        data: data,
      }),
      invalidatesTags: [tagTypes.appointments],
    }),
    getPatientAppointments: build.query({
      query: (idData) => ({
        url: `${APPOINTMENT_URL}/getAllAppointments/${idData.patientId}/${idData.hospitalId}`,
        method: "GET",
      }),
      providesTags: [tagTypes.appointments],
    }),
    getAdminAppointments: build.query({
      query: (userId) => ({
        url: `${APPOINTMENT_URL}/admin/appointments/${userId}`,
        method: "GET",
      }),
      providesTags: [tagTypes.appointments],
    }),
    getSingleAppointment: build.query({
      query: (id) => ({
        url: `${APPOINTMENT_URL}/${id}`,
        method: "GET",
      }),
      providesTags: [tagTypes.appointments],
    }),
    getAppointmentedPaymentInfo: build.query({
      query: (id) => ({
        url: `${APPOINTMENT_URL}/patient-payment-info/${id}`,
        method: "GET",
      }),
      providesTags: [tagTypes.appointments],
    }),
    getDoctorAppointments: build.query({
      query: () => ({
        url: `${APPOINTMENT_URL}/doctor/appointments/`,
        method: "GET"
      }),
      providesTags: [tagTypes.appointments],
    }),
    getDoctorAppointmentsById: build.query({
      query: (id) => ({
        url: `${APPOINTMENT_URL}/doctor/appointments/${id}`,
        method: "GET"
      }),
      providesTags: [tagTypes.appointments],
    }),
    getDoctorPatients: build.query({
      query: (doctorId) => ({
        url: `${APPOINTMENT_URL}/doctor/patients/${doctorId}`,
        method: "GET",
      }),
      providesTags: [tagTypes.appointments],
    }),
    getPatientInvoices: build.query({
      query: (idData) => ({
        url: `${APPOINTMENT_URL}/patient/invoices/${idData.patientId}/${idData.hospitalId}`,
        method: "GET",
      }),
      providesTags: [tagTypes.appointments],
    }),
    getDoctorInvoices: build.query({
      query: () => ({
        url: `${APPOINTMENT_URL}/doctor/invoices`,
        method: "GET",
      }),
      providesTags: [tagTypes.appointments],
    }),
    getAdminInvoices: build.query({
      query: (id) => ({
        url: `${APPOINTMENT_URL}/admin/invoices/${id}`,
        method: "GET",
      }),
      providesTags: [tagTypes.appointments],
    }),
    
  }),
});

export const {
  useApproveRescheduleMutation,
  useGetDoctorAppointmentsQuery,
  useGetPatientAppointmentsQuery,
  useGetDoctorPatientsQuery,
  useCreateAppointmentMutation,
  useCreateAppointmentPaymentMutation,
  useGetSingleAppointmentQuery,
  useGetAppointmentedPaymentInfoQuery,
  useGetDoctorAppointmentsByIdQuery,
  useGetPatientInvoicesQuery,
  useGetDoctorInvoicesQuery,
  useUpdateAppointmentMutation,
  useCreateAppointmentByUnauthenticateUserMutation,
  useTrackAppointmentMutation,
  useGetAdminAppointmentsQuery,
  useGetAdminInvoicesQuery,
  useRescheduleAppointmentMutation,
} = appointmentApi;
