import React, { useState, useEffect } from 'react';
import { FaHospitalUser, FaCalendarAlt, FaHospital } from "react-icons/fa";
import { Card, Row, Col, Typography, Statistic, Spin } from 'antd';
import axios from 'axios';

const { Title, Text } = Typography;

const DoctorDashCard = ({ doctorId }) => {
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);
  const apiurl = process.env.REACT_APP_API_BASE_URL;
  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await axios.get(`${apiurl}/doctor/dashcard/${doctorId}`);
        setDashboardData(response.data.data);
        setLoading(false);
        console.log(response);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, [doctorId]);

  if (loading) {
    return <Spin size="large" />;
  }

  const cardData = [
    {
      icon: <FaHospital style={{ fontSize: '2rem', color: '#1890ff' }} />,
      title: 'Total Patients',
      amount: dashboardData?.totalPatients,
      date: new Date().toLocaleDateString(),
      color: '#e6f7ff'
    },
    {
      icon: <FaHospitalUser style={{ fontSize: '2rem', color: '#52c41a' }} />,
      title: 'Today\'s Patients',
      amount: dashboardData?.todayPatients,
      date: new Date().toLocaleDateString(),
      color: '#f6ffed'
    },
    {
      icon: <FaCalendarAlt style={{ fontSize: '2rem', color: '#faad14' }} />,
      title: 'Pending Appointments',
      amount: dashboardData?.appointments,
      date: new Date().toLocaleDateString(),
      color: '#fff7e6'
    }
  ];

  return (
    <Row gutter={[16, 16]} className="mb-4">
      {cardData.map((item, index) => (
        <Col xs={24} sm={12} lg={8} key={index}>
          <Card
            hoverable
            style={{ backgroundColor: item.color }}
            bodyStyle={{ padding: '20px' }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ marginRight: '20px' }}>
                {item.icon}
              </div>
              <div>
                <Title level={5} style={{ margin: 0 }}>{item.title}</Title>
                <Statistic value={item.amount} valueStyle={{ fontWeight: 'bold' }} />
                <Text type="secondary">{item.date}</Text>
              </div>
            </div>
          </Card>
        </Col>
      ))}
    </Row>
  );
}

export default DoctorDashCard;