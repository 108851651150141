import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import Home from "./components/Home/Home/Home";
import AddHospital from "./components/Admin/AddHospital/AddHospital";
import SignInForm from "./components/Login/SignInForm";
import DoctorBooking from "./components/Booking/DoctorBooking/DoctorBooking";
import BookingSuccess from "./components/Booking/BookingSuccess";
import BookingInvoice from "./components/Booking/BookingInvoice/BookingInvoice";
import DoctorProfile from "./components/Doctor/DoctorProfile/DoctorProfile";
import Appointments from "./components/Doctor/Appointments/Appointments";
import MyPatients from "./components/Doctor/MyPatients/MyPatients";
import Reviews from "./components/Doctor/Reviews/Reviews";
import Schedule from "./components/Doctor/Schedule/Schedule";
import ProfileSetting from "./components/Doctor/ProfileSetting/ProfileSetting";
import ChangePassword from "./components/Doctor/ChangePassword/ChangePassword";
import AdminDashboard from "./components/Admin/Dashboard/Dashboard";
import AdminAppointments from "./components/Admin/Appointments/Appointments";
import Doctors from "./components/Admin/Doctors/Doctors";
import Patients from "./components/Admin/Patients/Patients";
import Profile from "./components/Admin/Profile/Profile";
import Transactions from "./components/Admin/Transactions/Transactions";
import Specialites from "./components/Admin/Specialites/Specialites";
import AdminReviews from "./components/Admin/Reviews/Reviews";
import PatientFavouriteDoctor from "./components/Doctor/PatientFavourite/PatientFavourite";
import DoctorInvoice from "./components/Doctor/Invoice/DoctorInvoice";
import SearchDoctor from "./components/Doctor/SearchDoctor/SearchDoctor";
import Blogs from "./components/Doctor/Blogs/Blogs";
import BlogsEdit from "./components/Doctor/Blogs/BlogsEdit";
import AddBlog from "./components/Doctor/Blogs/AddBlog";
import Blog from "./components/Blog/Blog";
import BlogDetails from "./components/Blog/BlogDetails";
import Contact from "./components/Contact/Contact";
import About from "./components/About/About";
import Service from "./components/Service/Service";
import AppointmentPage from "./components/Appointment/AppointmentPage";
import TrackAppointment from "./components/TrackAppointment/TrackAppointment";
import Treatment from "./components/Doctor/Treatment/Treatment";
import Prescription from "./components/Doctor/Prescription/Prescription";
import PrescriptionView from "./components/Doctor/Prescription/PrescriptionView";
import TreatmentEdit from "./components/Doctor/Treatment/TreatmentEdit";
import ViewAppointment from "./components/Doctor/Appointments/ViewAppointment";
import ForgotPassword from "./components/Login/ForgotPassword";
import Dashboard from "./components/Doctor/Dashboard/Dashboard";
import PrivateOutlet from "./components/Shared/PrivateOutlet";
import NotFound from "./components/UI/NotFound";
import { ConnectForm } from "./components/VideoCall/ConnectForm";
import { LiveVideo } from "./components/VideoCall/LiveVideo";
import PrivateOutletPatient from "./components/Shared/PrivateOutletPatient";
import AgoraRTC, { AgoraRTCProvider, useRTCClient } from "agora-rtc-react";
import HospitalSignInForm from "./components/Login/HospitalSignInForm";
import AddDoctor from "./components/Admin/AddDoctors/AddDoctors";
import UserVerification from "./components/VerifyUser/UserVerification";
import PrivateOutletDoctor from "./components/Shared/PrivateOutletDoctor";
import PrivateOutletAdmin from "./components/Shared/PrivateOutletAdmin";
import DoctorVerification from "./components/VerifyUser/DoctorVerification";
import AllDoctorPrescription from "./components/Doctor/Prescription/AllDoctorPrescription";
import PrivateOutletCommon from "./components/Shared/PrivateOutletCommon";
import FeedbackForm from "./components/Admin/Appointments/Feedback";
import DoctorSchedule from "./components/Admin/Schedule/DoctorSchedule";
import ScheduleDoctor from "./components/Admin/Schedule/ScheduleDoctor";
import RescheduleAppointments from "./components/Doctor/Appointments/ReschedulingAppointments";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import AddSolution from "./components/Admin/addSolutions/AddSolutions";
import { Solutions } from "./components/Home/solutions/Solutions";
import { SolutionPage } from "./components/Home/solutions/SolutionPage";

function App() {
  const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

  return (
    <Router>
      <Routes>
        <Route element={<PrivateOutlet />}>
          <Route path="/dashboard/blogs" element={<Blogs />} />
          <Route path="/dashboard/my-patients" element={<Patients />} />
          <Route path="/dashboard/appointments" element={<Appointments />} />
          <Route
            path="/dashboard/appointments/feedback/:id/:appointmentId"
            element={<FeedbackForm />}
          />
          <Route
            path="/dashboard/appointments/:id/:appointmentId"
            element={<ViewAppointment />}
          />
        

          <Route path="/dashboard/invoices" element={<DoctorInvoice />} />
          {/* Admin Dashboard  */}
        </Route>

        <Route element={<PrivateOutletAdmin />}>
          <Route path="/admin/dashboard" element={<AdminDashboard />} />
          <Route path="/admin/appointments" element={<AdminAppointments />} />
          <Route path="/admin/doctors" element={<Doctors />} />
          <Route path="/admin/patients" element={<Patients />} />
          <Route path="/admin/profile" element={<Profile />} />
          <Route path="/admin/reviews" element={<AdminReviews />} />
          <Route path="/admin/transaction" element={<Transactions />} />
          <Route path="/admin/addHospital" element={<AddHospital />} />
          <Route path="/admin/addDoctor" element={<AddDoctor />} />
          <Route path="/admin/addSolution" element={<AddSolution />} />
          <Route
            path="/admin/dashboard/schedule/:doctorId"
            element={<ScheduleDoctor />}
          />
        </Route>
        {/* Protected routes for patients */}
        <Route element={<PrivateOutletPatient />}>
          <Route path="/dashboard/:id" element={<Dashboard />} />
          <Route
            path="/dashboard/profile-setting/:id"
            element={<ProfileSetting />}
          />
          <Route
            path="/dashboard/change-password/:id"
            element={<ChangePassword />}
          />
          <Route
            path="/dashboard/prescription/view/:id/:prescriptionId"
            element={<PrescriptionView />}
          />
          <Route path="/doctors/:id" element={<SearchDoctor />} />
          <Route path="/solutions/:id" element={<Solutions />} />
          <Route path="/solution/:id/:sId" element={<SolutionPage />} />
          <Route path="/about/:id" element={<About />} />
          <Route path="/contact/:id" element={<Contact />} />
        </Route>
        {/* Protected routes for doctors */}
        <Route element={<PrivateOutletDoctor />}>
          <Route path="doctor/dashboard/:id" element={<Dashboard />} />
          <Route
            path="doctor/dashboard/appointments/:id"
            element={<Appointments />}
          />
          <Route
            path="doctor/dashboard/reschedule/appointments/:id"
            element={<RescheduleAppointments />}
          />
          <Route
            path="doctor/dashboard/my-patients/:id"
            element={<MyPatients />}
          />
          <Route
            path="/doctor/dashboard/prescription/:id/:prescriptionId"
            element={<Prescription />}
          />
          <Route
            path="/doctor/dashboard/prescription/:id/"
            element={<AllDoctorPrescription />}
          />
          <Route
            path="/doctor/dashboard/prescription/view/:id/:prescriptionId"
            element={<PrescriptionView />}
          />
          <Route path="/doctor/dashboard/reviews/:id" element={<Reviews />} />
          <Route path="/doctor/dashboard/schedule/:id" element={<Schedule />} />
          <Route
            path="/doctor/dashboard/appointment/treatment/:appointmentId/:id"
            element={<Treatment />}
          />
          <Route
            path="/doctor/dashboard/appointment/treatment/edit/:id/:prescriptionId"
            element={<TreatmentEdit />}
          />
          <Route
            path="/doctor/dashboard/invoices/:id"
            element={<DoctorInvoice />}
          />
          <Route
            path="/doctor/dashboard/profile-setting/:id"
            element={<ProfileSetting />}
          />
          <Route path="/doctor/dashboard/blogs/:id" element={<Blogs />} />
          <Route
            path="doctor/dashboard/blogs/create/:id"
            element={<AddBlog />}
          />
        </Route>
        <Route element={<PrivateOutletCommon />}>
          <Route path="/booking/:id/:doctorId/" element={<DoctorBooking />} />
          <Route
            path="/booking/success/:id/:bookingId"
            element={<BookingSuccess />}
          />
          <Route path="/booking/invoice/:id" element={<BookingInvoice />} />
        </Route>
        <Route path="/track-appointment" element={<TrackAppointment />} />
        <Route path="/admin/login" element={<SignInForm />} />
        <Route path="/hospital/:id" element={<HospitalSignInForm />} />
        <Route path="/" element={<Home />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:id" element={<BlogDetails />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/service" element={<Service />} />
        <Route
          path="/reset-password/:userId/:uniqueString"
          element={<ForgotPassword />}
        />
        {/* <Route path='/doctors' element={<SearchDoctor />} /> */}
        <Route path="/doctors/profile/:doctorId" element={<DoctorProfile />} />
        <Route
          path="/doctors/profile/:id/:doctorId"
          element={<DoctorProfile />}
        />
        <Route path="/dashboard/blogs/:id" element={<BlogsEdit />} />
        <Route path="/dashboard/blogs/create" element={<AddBlog />} />
        <Route
          path="/user/verify/:userId/:token"
          element={<UserVerification />}
        />
        <Route
          path="/doctor/verify/:userId/:token"
          element={<DoctorVerification />}
        />
        {/* Video Calling */}
        <Route path="/call/test" element={<ConnectForm />} />
        <Route
          path="/call/via/:channelName"
          element={
            <AgoraRTCProvider client={client}>
              <LiveVideo />
            </AgoraRTCProvider>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}
export default App;
