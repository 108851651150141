import { useGetDoctorTimeSlotFromAdminQuery } from "../../../redux/api/timeSlotApi";
import React, { useEffect, useState } from "react";
import { DayPilot, DayPilotCalendar, DayPilotMonth, DayPilotNavigator } from "@daypilot/daypilot-lite-react";
import "./DashboardLayout.css";
import AdminLayout from "../AdminLayout/AdminLayout";
import { Spin } from "antd";
import { useNavigate, useParams } from "react-router-dom";

const DoctorSchedule = () => {
    const navigate = useNavigate()
  const [view, setView] = useState("Week");
  const [startDate, setStartDate] = useState(DayPilot.Date.today());
  const [events, setEvents] = useState([]);
  const {doctorId} = useParams()
  console.log("doctorId -->" , doctorId);
  const { data, refetch, isLoading, isError } = useGetDoctorTimeSlotFromAdminQuery(doctorId);
 
  // console.log("data from DoctorSchedule 2 ----> " , data);
  useEffect(() => {
      if (data && data.length) {
        console.log("data from DoctorSchedule ----> " , data);
      const transformedEvents = data.map((slot) => ({
        id: slot.id,
        start: slot.startDateAndTime,
        end: slot.endDateAndTime,
        description: slot.description,
        text: slot.description,
      }));
      setEvents(transformedEvents);
    }
  }, [data]);

  return (
        <>
      {isLoading ? (
        <Spin size="large" />
      ) : (<>
        <button className="schedule-standalone-go-back" onClick={() => {navigate("/admin/doctors")}}> Go back</button>
        <div className={"schedule-container"}>
          <div className={"schedule-navigator"}>
            <DayPilotNavigator
              selectMode={view}
              showMonths={1}
              skipMonths={1}
              onTimeRangeSelected={(args) => setStartDate(args.day)}
              events={events}
            />
          </div>
          <div className={"schedule-content"}>
            <div className={"schedule-toolbar"}>
              <div className={"schedule-toolbar-group"}>
                <button
                  onClick={() => setView("Day")}
                  className={view === "Day" ? "schedule-selected" : ""}
                >
                  Day
                </button>
                <button
                  onClick={() => setView("Week")}
                  className={view === "Week" ? "schedule-selected" : ""}
                >
                  Week
                </button>
                <button
                  onClick={() => setView("Month")}
                  className={view === "Month" ? "schedule-selected" : ""}
                >
                  Month
                </button>
              </div>
              <button
                onClick={() => setStartDate(DayPilot.Date.today())}
                className={"schedule-standalone"}
              >
                Today
              </button>
            </div>

            <div className={"schedule-calendar-container"}>
              <DayPilotCalendar
                viewType={"Day"}
                startDate={startDate}
                events={events}
                visible={view === "Day"}
                durationBarVisible={false}
              />
              <DayPilotCalendar
                viewType={"Week"}
                startDate={startDate}
                events={events}
                visible={view === "Week"}
                durationBarVisible={false}
              />
              <DayPilotMonth
                startDate={startDate}
                events={events}
                visible={view === "Month"}
                durationBarVisible={false}
              />
            </div>
          </div>
        </div></>
      )}
    </>
  );
};

export default DoctorSchedule;
