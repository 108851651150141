
import React from 'react';
import { useParams } from 'react-router-dom';
import { Tabs, Avatar, Card, Row, Col, Tag, Typography, Divider, message, Empty } from 'antd';
import { UserOutlined, MailOutlined, PhoneOutlined, HomeOutlined, CalendarOutlined, DollarOutlined, TrophyOutlined } from '@ant-design/icons';
import Header from '../../Shared/Header/Header';
import SubHeader from '../../Shared/SubHeader';
import Footer from '../../Shared/Footer/Footer';
import { useGetDoctorQuery } from '../../../redux/api/doctorApi';
import OverView from './OverView';
import Location from './Location';
import Review from './Review';
import Availibility from './Availibility';
import SearchContent from '../SearchDoctor/SearchContent';
import "./profile.css"

const { Title, Text } = Typography;


const DoctorProfile = () => {
    const { doctorId } = useParams();
    const { data, isLoading, isError } = useGetDoctorQuery(doctorId);

    if (isLoading) return <div>Loading...</div>;
    if (isError) return <div>Error loading doctor data</div>;
    let content = null;
    if (!isLoading && isError) content = <div>{message.error('Something went Wrong!')}</div>
    if (!isLoading && !isError && data?.id === undefined) content = <Empty />
    if (!isLoading && !isError && data?.id) content = <SearchContent profileBtn={false} data={data} />
console.log("data from doctorprofile" ,data);

    const { firstName, lastName, email, phone, gender, dob, biography, address, city, state, country, postalCode, price, services, degree, college, completionYear, experience } = data;

    const items = [
        {
            key: '1',
            label: 'Overview',
            children: <OverView />,
        },
        {
            key: '2',
            label: 'Locations',
            children: <Location />,
        },
        {
            key: '3',
            label: 'Reviews',
            children: <Review doctorId={doctorId} />,
        },
        {
            key: '4',
            label: 'Availability',
            children: <Availibility />,
        },
    ];

    console.log(data);

    return (
        <>
            <Header />
            <SubHeader title='Provider Details' subtitle='Detailed information about the provider.' />
            <div className="container" style={{ marginBottom: '4rem', marginTop: '6rem' }}>
                {content}
                <div className='p-4 rounded' style={{ marginBottom: '7rem', backgroundColor: '#f3f3f3' }}>
                    <div className="container" style={{ marginTop: '4rem', marginBottom: '4rem' }}>
                        <Card className="doctor-profile-card">
                            <Row gutter={[24, 24]}>
                                <Col xs={24} md={8}>
                                    <div className="doctor-profile-avatar">
                                        <Avatar size={200} icon={<UserOutlined />} />
                                        <Title className="doctor-name" level={2}>{`Dr. ${firstName} ${lastName}`}</Title>
                                        <Text className="doctor-degree">{degree}</Text>
                                    </div>
                                </Col>
                                <Col xs={24} md={16}>
                                    <Row gutter={[16, 16]}>
                                        <Col span={12}>
                                            <Text className="doctor-info-label"><MailOutlined /> Email:</Text> <Text className="doctor-info-value">{email}</Text>
                                        </Col>
                                        <Col span={12}>
                                            <Text className="doctor-info-label"><PhoneOutlined /> Phone:</Text> <Text className="doctor-info-value">{phone}</Text>
                                        </Col>
                                        <Col span={12}>
                                            <Text className="doctor-info-label"><HomeOutlined /> Address:</Text> <Text className="doctor-info-value">{`${address}, ${city}, ${state}, ${country} - ${postalCode}`}</Text>
                                        </Col>
                                        <Col span={12}>
                                            <Text className="doctor-info-label"><CalendarOutlined /> Date of Birth:</Text> <Text className="doctor-info-value">{new Date(dob).toLocaleDateString()}</Text>
                                        </Col>
                                        <Col span={12}>
                                            <Text className="doctor-info-label"><DollarOutlined /> Consultation Fee:</Text> <Text className="doctor-info-value">${price}</Text>
                                        </Col>
                                        <Col span={12}>
                                            <Text className="doctor-info-label"><TrophyOutlined /> Experience:</Text> <Text className="doctor-info-value">{experience} years</Text>
                                        </Col>
                                    </Row>
                                    <Divider className="doctor-divider" />
                                    <Title className="doctor-section-title" level={4}>Biography</Title>
                                    <Text className="doctor-biography">{biography}</Text>
                                    <Divider className="doctor-divider" />
                                    <Title className="doctor-section-title" level={4}>Education</Title>
                                    <Text className="doctor-education">{`${degree} from ${college} (${completionYear})`}</Text>
                                    <Divider className="doctor-divider" />
                                    <Title className="doctor-section-title" level={4}>Services</Title>
                                    <Text className="doctor-services">{services}</Text>
                                </Col>
                            </Row>
                        </Card>
                        {/* <Card style={{ marginTop: '2rem' }}>
                            <Tabs defaultActiveKey="1" items={items} />
                        </Card> */}
                    </div>

                </div>
            </div>
            <Footer />
        </>
    )
}

export default DoctorProfile;

