import React from 'react'
import logo from '../../images/logo.png';
import userImg from '../../images/avatar.jpg';
import './AdminHeader.css';

const AdminHeader = () => {
    return (
        <div className="header">
            <div className="header-left">
                <a href="/admin/dashboard" className="logo">
                    <img className='avery-logo' src={logo} alt="Logo" />
                </a>
            </div>


            {/* <div className="top-nav-search">
                <form>
                    <input type="text" className="form-control" placeholder="Search here" />
                    <button className="btn" type="submit"><i className="fa fa-search"></i></button>
                </form>
            </div> */}

            
            <ul className="nav user-menu">
                <li>
                    <a href="/admin/profile">
                        <span className="user-img"><img className="rounded-circle" src={userImg} width="31" alt="admin-img" /></span>
                    </a>
                </li>

            </ul>

        </div>
    )
}

export default AdminHeader