import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, message } from "antd";
import useAuthCheck from "../../../redux/hooks/useAuthCheck";
import TopHeader from "../TopHeader/TopHeader";
import HeaderNav from "./HeaderNav";
import { loggedOut } from "../../../service/auth.service";
import { getUserIdFromToken } from "../../../utils/local-storage";
import img from "../../../images/logo.png";
import avatar from "../../../images/avatar.jpg";
import "./index.css";

const Header = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { authChecked, data } = useAuthCheck();
  const [isLoggedIn, setIsLogged] = useState(false);
  const [show, setShow] = useState(true);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setShow(window.scrollY <= 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    setIsLogged(authChecked || data || getUserIdFromToken());
  }, [authChecked, data]);

  const handleSignOut = () => {
    loggedOut();
    message.success("Successfully Logged Out");
    setIsLogged(false);
    navigate(`/hospital/${id}`);
  };

  const renderUserInfo = () => (
    <div className="nav-popover">
      {data?.role === "doctor" && (
        <div className="my-2">
          <h5 className="text-capitalize">
            {`${data.firstName} ${data.lastName}`}
          </h5>
          <p className="my-0">{data.email}</p>
          <Link to="/doctor/dashboard">Dashboard</Link>
        </div>
      )}
      <Button
        variant="outline-danger"
        className="w-100"
        size="sm"
        onClick={handleSignOut}
      >
        Log Out
      </Button>
    </div>
  );

  return (
    <>
      <div className={`navbar navbar-expand-lg navbar-light ${!show && "hideTopHeader"}`}>
        <TopHeader />
      </div>
      <header id="header" className={`fixed-top ${!show && "stickyHeader"}`}>
        <div className="container d-flex align-items-center">
          <Link to="/" className="logo me-auto">
            <img src={img} alt="Logo" className="img-fluid avery-logo" />
          </Link>
          <HeaderNav
            isLoggedIn={isLoggedIn}
            data={data}
            avatar={avatar}
            open={open}
            setOpen={setOpen}
            userInfo={renderUserInfo()}
          />
        </div>
      </header>
    </>
  );
};

export default Header;