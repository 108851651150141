import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Card, Avatar, Typography, Rate, Space, Button } from "antd";
import { FaDollarSign, FaComment, FaStethoscope } from "react-icons/fa";
import { useGetDoctorReviewsQuery } from "../../../redux/api/reviewsApi";
import { getUserInfo } from "../../../service/auth.service";

const { Text, Title } = Typography;

const SearchContent = ({ data }) => {
  const [rating, setRating] = useState(0);
  const { id } = useParams();
  const { data: reviews, isLoading: isReviewsLoading } =
    useGetDoctorReviewsQuery(data?.id);
  const { role } = getUserInfo();
  console.log("userData from serchcontent", role);

  useEffect(() => {
    const averageRating =
      reviews && reviews.length > 0
        ? reviews.reduce((sum, review) => sum + Number(review.star), 0) /
          reviews.length
        : 0;
    const roundedRating = Math.round(averageRating * 2) / 2;
    setRating(roundedRating);
  }, [isReviewsLoading, reviews]);

  if (!data) return null;

  return (
    <Card
      hoverable
      className="mb-4"
      bodyStyle={{ padding: 20, background: "#f9f9f9" }}
    >
      <Space
        size="large"
        align="center"
        style={{ width: "100%", justifyContent: "space-between" }}
      >
        <Space size="large" align="center">
          <Avatar
            size={80}
            src={data.img || "/default-doctor-image.png"}
            icon={<FaStethoscope />}
            style={{ backgroundColor: "#d9d9d9" }}
          />
          <Space direction="vertical" size="small">
            <Title level={4} style={{ color: "#555", margin: 0 }}>
              <Link
                to={`/doctors/profile/${id}/${data.id}`}
                style={{ color: "#1890ff" }}
              >
                Dr. {data.firstName} {data.lastName}
              </Link>
            </Title>
            <Rate
              disabled
              allowHalf
              value={rating}
              defaultValue={0}
              style={{ fontSize: 16 }}
            />
            <Text style={{ color: "#888" }}>
              ({isReviewsLoading ? "..." : reviews ? reviews.length : 0}{" "}
              Feedback)
            </Text>
          </Space>
        </Space>
        <Space size="large">
          <Space direction="vertical" align="end" style={{ color: "#666" }}>
            <Text>
              <FaDollarSign /> {data.price || 43} per hour
            </Text>
            <Text>
              <FaComment />{" "}
              {isReviewsLoading ? "..." : reviews ? reviews.length : 0} Feedback
            </Text>
          </Space>
          {role !== "admin" && (
            <Space direction="vertical" size="middle">
              <Button
                type="primary"
                size="large"
                style={{
                  width: 150,
                  height: 40,
                  backgroundColor: "#4dabf7",
                  borderColor: "#4dabf7",
                }}
              >
                <Link to={`/doctors/profile/${id}/${data.id}`}>
                  View Profile
                </Link>
              </Button>
              <Button
                size="large"
                style={{
                  width: 150,
                  height: 40,
                  borderColor: "#d9d9d9",
                  color: "#555",
                }}
              >
                <Link to={`/booking/${id}/${data.id}`}>Book Appointment</Link>
              </Button>
            </Space>
          )}
        </Space>
      </Space>
    </Card>
  );
};

export default SearchContent;
