import {
  useCreateTimeSlotMutation,
  useGetDoctorTimeSlotQuery,
  useUpdateTimeSlotMutation,
  useDeleteTimeSlotMutation,
} from "../../../redux/api/timeSlotApi";
import React, { useEffect, useState, useRef } from "react";
import {
  DayPilot,
  DayPilotCalendar,
  DayPilotMonth,
  DayPilotNavigator,
} from "@daypilot/daypilot-lite-react";
import "./Schedule.css";
// import deleteIcon from 'icons/delete.svg';

// import deleteIcon from './delete.png';
import DashboardLayout from "../DashboardLayout/DashboardLayout";
import { getUserInfo } from "../../../service/auth.service";
import { Spin } from "antd";
const Schedule = () => {
  const [view, setView] = useState("Week");
  const [startDate, setStartDate] = useState(DayPilot.Date.today());
  const [events, setEvents] = useState([]);
  const [dayView, setDayView] = useState();
  const [weekView, setWeekView] = useState();
  const [monthView, setMonthView] = useState();
  const userData = getUserInfo();

  const throttlingTimeout = useRef(null); // Ref to manage throttling

  const [
    updateTimeSlot,
    {
      isError: uIsError,
      error: uError,
      isLoading: UIsLoading,
      isSuccess: uIsSuccess,
    },
  ] = useUpdateTimeSlotMutation();

  const [
    deleteTimeSlot, // Initialize delete mutation
    {
      isError: dIsError,
      error: dError,
      isLoading: dIsLoading,
      isSuccess: dIsSuccess,
    },
  ] = useDeleteTimeSlotMutation();

  const { data, refetch, isLoading, isError } = useGetDoctorTimeSlotQuery();

  const [
    createTimeSlot,
    { isError: AIsError, error, isLoading: AIsLoading, isSuccess },
  ] = useCreateTimeSlotMutation();

  console.log(data);

  // Set the events to the transformed dummy data
  useEffect(() => {
    if (data && data.length) {
      const transformedEvents = data.map((slot) => {
        // Convert UTC dates to local time zone
        const startLocal = new Date(slot.startDateAndTime);
        const endLocal = new Date(slot.endDateAndTime);
        
        return {
          id: slot.id,
          start: new DayPilot.Date(startLocal) , //  slot.startDateAndTime 
          end: new DayPilot.Date(endLocal) , //  slot.endDateAndTime 
          description: slot.description,
          text: slot.description,
        };
      });
      setEvents(transformedEvents);
    }
  }, [data, AIsLoading, isSuccess]);

  const onTimeRangeSelected = async (args) => {
    const dp = args.control;
    const modal = await DayPilot.Modal.prompt(
      "Create a new Slot:",
      "Availability Slot"
    );
    dp.clearSelection();
    if (modal.canceled) {
      return;
    }
  
    // Convert selected times to UTC
    const startUTC = args.start.toDate().toUTCString();
    const endUTC = args.end.toDate().toUTCString();
  
    // Generate a new event
    const newEvent = {
      id: DayPilot.guid(),
      start: args.start,
      end: args.end,
      description: modal.result,
      text: modal.result
    };
  
    // Add the new event to the list
    setEvents(prevEvents => [...prevEvents, newEvent]);
  
    console.log(
      "Creating new time slot:",
      "doctorId:", userData.id,
      "day:", args.start.getDayOfWeek(),
      "startTime:", startUTC,
      "endTime:", endUTC
    );
  
    // Throttle the createTimeSlot API call
    if (throttlingTimeout.current) {
      clearTimeout(throttlingTimeout.current);
    }
  
    throttlingTimeout.current = setTimeout(async () => {
      try {
        const timeslotObj = {
          doctorId: userData.id,
          dayIndex: args.start.getDayOfWeek(),
          startTime: startUTC,
          endTime: endUTC,
          description: modal.result,
          weekDay: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][args.start.getDayOfWeek()],
          status: "available",
          maximumPatient: 50 // You might want to make this configurable
        };
        console.log("Creating time slot:", timeslotObj);
  
        const response = await createTimeSlot(timeslotObj).unwrap();
        console.log("Time slot created:", response);
  
        // Update the event with the ID from the server response
        setEvents(prevEvents => prevEvents.map(event => 
          event.id === newEvent.id ? {...event, id: response.id} : event
        ));
  
        // Optionally, you can refetch all events here
        refetch();
      } catch (error) {
        console.error("Failed to create time slot:", error);
        // Remove the event if the API call failed
        setEvents(prevEvents => prevEvents.filter(event => event.id !== newEvent.id));
      } finally {
        throttlingTimeout.current = null;
      }
    }, 1000); // 1-second throttle time
  };

  const onEventMoved = async (args) => {
    const dp = args.control;
  
    
    const existingEvent = events.find(
      (event) => args.newStart < event.end && args.newEnd > event.start
    );
  
    if (existingEvent) {
      const modal = await DayPilot.Modal.prompt(
        "Edit event:",
        existingEvent.description
      );
      if (modal.canceled || !modal.result) {
        return;
      }
      dp.clearSelection();
  
      
      setEvents(
        events.map((event) =>
          event.id === existingEvent.id
            ? { ...event, description: modal.result }
            : event
        )
      );
  
      
      const startUTC = args.newStart.toDate().toUTCString();
      const endUTC = args.newEnd.toDate().toUTCString();
  
      console.log(
        "logging all data for update doctorId:",
        userData.id,
        "day:",
        args.newStart.dayOfWeek(),
        "startTime (UTC):", startUTC,
        "endTime (UTC):", endUTC,
        "description:", modal.result
      );
  
      
      if (throttlingTimeout.current) {
        clearTimeout(throttlingTimeout.current);
      }
  
      throttlingTimeout.current = setTimeout(async () => {
        try {
          await updateTimeSlot({
            id: existingEvent.id, 
            doctorId: userData.id,
            dayIndex: args.newStart.dayOfWeek(),
            startTime: startUTC,  
            endTime: endUTC,      
            description: modal.result,
          });
          refetch();
        } catch (error) {
          console.error("Failed to update time slot:", error);
        } finally {
          throttlingTimeout.current = null;
        }
      }, 1000); 
    }
  };
  
  const onEventResized = async (args) => {
    console.log(
      "onEventResized logging all data for update doctorId",
      userData.id,
      "day:",
      args.newStart.dayOfWeek(),
      "startTime:",
      args.newStart.toString(),
      "endTime:",
      args.newEnd.toString()
    );
  
    // Convert the new start and end times to UTC
    const startUTC = args.newStart.toDate().toUTCString();
    const endUTC = args.newEnd.toDate().toUTCString();
  
    // Find the existing event
    const existingEvent = events.find(
      (event) => args.newStart < event.end && args.newEnd > event.start
    );
    
    if (existingEvent) {
      const modal = await DayPilot.Modal.prompt(
        "Edit event:",
        existingEvent.description
      );
      args.control.clearSelection();
  
      if (modal.canceled) {
        return;
      }
  
      // Update the event state
      setEvents(
        events.map((event) =>
          event.id === existingEvent.id
            ? { ...event, description: modal.result }
            : event
        )
      );
  
      console.log(
        "logging all data for update doctorId:",
        userData.id,
        "day:",
        args.newStart.dayOfWeek(),
        "startTime (UTC):", startUTC,
        "endTime (UTC):", endUTC,
        "description:", modal.result
      );
  
      // Throttle the updateTimeSlot API call
      if (throttlingTimeout.current) {
        clearTimeout(throttlingTimeout.current);
      }
  
      throttlingTimeout.current = setTimeout(async () => {
        try {
          await updateTimeSlot({
            id: existingEvent.id,
            doctorId: userData.id,
            dayIndex: args.newStart.dayOfWeek(),
            startTime: startUTC,  // Send UTC start time
            endTime: endUTC,      // Send UTC end time
            description: modal.result,
          });
          refetch(); // Refetch data after a successful update
        } catch (error) {
          console.error("Failed to update time slot:", error);
        } finally {
          throttlingTimeout.current = null;
        }
      }, 1000); // 1-second throttle time
    }
  };
  


  const onBeforeEventRender = (args) => {
    args.data.areas = [
      {
        top: 5,
        right: 20,
        width: 7,
        height: 7, 
        padding: 2,
        image: '/icons/delete.svg',
        style: "cursor: pointer",
        fontColor: "#666",
        toolTip: "Delete",
        onClick: async () => {
          const confirmDelete = window.confirm(
            "Are you sure you want to delete this time slot?"
          );
          if (confirmDelete) {
            try {
              // Call the delete mutation
              console.log(
                "args.data.id from confirmDelete",
                args.data,
                args.data.id
              );
              await deleteTimeSlot(args.data.id);

              // Remove the event from the state
              setEvents(events.filter((event) => event.id !== args.data.id));

              // Refetch data after deletion
              refetch();
            } catch (error) {
              console.error("Failed to delete time slot:", error);
            }
          }
        },
      },
    ];
  };

  const onEventClick = async (args) => {
    console.log("logging all data for onEventClick:", args);

    const dp = args.control;

    // Prompt the user to update the event text
    const modal = await DayPilot.Modal.prompt(
      "Update event text:",
      args.e.text()
    );
    dp.clearSelection();

    if (modal.canceled || !modal.result) {
      return; // Exit if the user cancels the prompt or doesn't enter a value
    }

    console.log("modal from onEventClick", modal);

    // Update the event description in the state
    setEvents(
      events.map((event) =>
        event.id === args.e.data.id
          ? { ...event, description: modal.result }
          : event
      )
    );

    console.log(
      "logging all data for update:",
      "id:",
      args.e.data.id,
      "doctorId:",
      userData.id,
      "dayIndex:",
      args.e.data.start.dayOfWeek(),
      "startTime:",
      args.e.data.start.toString(),
      "endTime:",
      args.e.data.end.toString(),
      "description:",
      modal.result
    );

    // Throttle the updateTimeSlot API call
    if (throttlingTimeout.current) {
      clearTimeout(throttlingTimeout.current);
    }

    throttlingTimeout.current = setTimeout(async () => {
      try {
        await updateTimeSlot({
          id: args.e.data.id, // Use the event ID from args
          doctorId: userData.id, // Replace with the actual doctorId
          dayIndex: args.e.data.start.dayOfWeek(),
          startTime: args.e.data.start.toString(),
          endTime: args.e.data.end.toString(),
          description: modal.result,
        });
        // Refetch data after a successful update
        refetch();
      } catch (error) {
        console.error("Failed to update time slot:", error);
      } finally {
        throttlingTimeout.current = null;
      }
    }, 1000); // 1-second throttle time
  };

  return (
    <DashboardLayout>
      {isLoading || UIsLoading || AIsLoading || dIsLoading ? (
        <Spin size="large" />
      ) : (
        <div className={"schedule-container"}>
          <div className={"schedule-navigator"}>
            <DayPilotNavigator
              selectMode={view}
              showMonths={1}
              skipMonths={1}
              onTimeRangeSelected={(args) => setStartDate(args.day)}
              events={events}
            />
          </div>
          <div className={"schedule-content"}>
            <div className={"schedule-toolbar"}>
              <div className={"schedule-toolbar-group"}>
                <button
                  onClick={() => setView("Day")}
                  className={view === "Day" ? "schedule-selected" : ""}
                >
                  Day
                </button>
                <button
                  onClick={() => setView("Week")}
                  className={view === "Week" ? "schedule-selected" : ""}
                >
                  Week
                </button>
                <button
                  onClick={() => setView("Month")}
                  className={view === "Month" ? "schedule-selected" : ""}
                >
                  Month
                </button>
              </div>
              <button
                onClick={() => setStartDate(DayPilot.Date.today())}
                className={"schedule-standalone"}
              >
                Today
              </button>
            </div>

            <div className={"schedule-calendar-container"}>
              <DayPilotCalendar
                viewType={"Day"}
                startDate={startDate}
                events={events}
                visible={view === "Day"}
                durationBarVisible={false}
                onTimeRangeSelected={onTimeRangeSelected}
                controlRef={setDayView}
                onEventMoved={onEventMoved}
                onEventResized={onEventResized}
                onBeforeEventRender={onBeforeEventRender}
                onEventClick={onEventClick}
              />
              <DayPilotCalendar
                viewType={"Week"}
                startDate={startDate}
                events={events}
                visible={view === "Week"}
                durationBarVisible={false}
                onTimeRangeSelected={onTimeRangeSelected}
                onEventMoved={onEventMoved}
                onEventResized={onEventResized}
                onBeforeEventRender={onBeforeEventRender}
                onEventClick={onEventClick}
              />
              <DayPilotMonth
                startDate={startDate}
                events={events}
                visible={view === "Month"}
                eventBarVisible={false}
                onTimeRangeSelected={onTimeRangeSelected}
                onEventMoved={onEventMoved}
                onEventResized={onEventResized}
                onBeforeEventRender={onBeforeEventRender}
                onEventClick={onEventClick}
              />
            </div>
          </div>
        </div>
      )}
    </DashboardLayout>
  );
};

export default Schedule;
