import React, { useEffect, useState } from "react";
import "./AdminSidebar.css";
import { FaAccessibleIcon, FaAdversal, FaBars, FaBookMedical, FaHome } from "react-icons/fa";
import { FaListUl } from "react-icons/fa";
import { FaPeopleArrows } from "react-icons/fa";
import { FaRegUser } from "react-icons/fa";
import { FaUserAstronaut } from "react-icons/fa";
import { FaRegStar } from "react-icons/fa";
import { FaBriefcase } from "react-icons/fa";
import { FaHospital } from "react-icons/fa";
import { Link } from "react-router-dom";
import { HiOutlineLogout } from "react-icons/hi";
import { clearLocalStorage } from "../../utils/local-storage";
import { getUserInfo } from "../../service/auth.service";
import { useGetHospitalIdFromUserIdQuery } from "../../redux/api/hospitalApi";
const AdminSidebar = () => {
  const handleLogout = () => {
    clearLocalStorage();
  };
  const [isDisabled, setIsDisabled] = useState(true);

  const user = getUserInfo();

  const {
    data: hospitalData,
    isLoading: hospitalLoading,
    error: hospitalError,
  } = useGetHospitalIdFromUserIdQuery(user.userId);

  useEffect(()=>{
    if(!hospitalLoading){
      if(hospitalData?.id){
        setIsDisabled(true);
      }
      else{
        setIsDisabled(false);
      }
    }
  }, [hospitalLoading, hospitalData, hospitalError]);

console.log(" is diabled" + isDisabled);
  return (
    <div className="sidebar" id="sidebar">
      <div className="sidebar-inner slimscroll">
        <div id="sidebar-menu" className="sidebar-menu">
          <ul>
            <li className="menu-title">
              <span>Main</span>
            </li>
            <li className="active">
              <Link to={"/admin/dashboard"}>
                <FaHome /> <span>Dashboard</span>
              </Link>
            </li>
            <li>
              <Link
                to={isDisabled ? "#" : "/admin/addHospital"}
                onClick={(e) => isDisabled && e.preventDefault()}
                style={{
                  pointerEvents: isDisabled ? "none" : "auto",
                  color: isDisabled ? "gray" : "white",
                  textDecoration: isDisabled ? "none" : "underline",
                }}
              >
                <FaHospital /> <span>Add Facility</span>
              </Link>
            </li>
            <li>
              <Link to={"/admin/addDoctor"}>
                <FaBookMedical /> <span>Add Providers</span>
              </Link>
            </li>
            <li>
              <Link to={"/admin/addSolution"}>
                <FaRegStar /> <span>Add Solutions</span>
              </Link>
            </li>
            <li>
              <Link to={"/admin/appointments"}>
                <FaListUl /> <span>Appointments</span>
              </Link>
            </li>
            <li>
              <Link to={"/admin/doctors"}>
                <FaUserAstronaut /> <span>Providers</span>
              </Link>
            </li>
            <li>
              <Link to={"/admin/patients"}>
                <FaRegUser /> <span>Patients</span>
              </Link>
            </li>
            {/* <li>
                            <Link to={'/admin/reviews'}>
                                <FaRegStar /> <span>Reviews</span>
                            </Link>

                        </li> */}
            <li>
              <Link to={"/admin/transaction"}>
                <FaBriefcase />
                <span>Transactions</span>
              </Link>
            </li>

            <li className="submenu">
              {/* <a href="#">
                <i className="fe fe-document"></i> <span> Reports</span>{" "}
                <span className="menu-arrow"></span>
              </a> */}
              {/* <ul style={{ display: "none" }}>
                <li>
                  <a>Invoice Reports</a>
                </li>
              </ul> */}
            </li>
            {/* <li className="menu-title">
              <span>Pages</span>
            </li> */}
            <li className="text-white">
              <Link to={"/admin/profile"}>
                <FaRegUser /> <span>Profile</span>
              </Link>
            </li>
            <li className="text-white" onClick={handleLogout}>
              <Link to={"/admin/login"}>
                {" "}
                <HiOutlineLogout /> <span>Logout</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AdminSidebar;
