import React, { useState } from "react";
import DashboardLayout from "../DashboardLayout/DashboardLayout";
import { Button, message } from "antd";
import { getUserInfo } from "../../../service/auth.service";
import { useChangePasswordMutation } from "../../../redux/api/authApi";
import useAuthCheck from "../../../redux/hooks/useAuthCheck";

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const userInfo = useAuthCheck()
  const [changePassword, { isLoading, isSuccess, isError, error }] = useChangePasswordMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      message.error("New password and confirm password do not match.");
      return;
    }
const userEmail = userInfo?.data?.email
    try {
      const payload = { oldPassword, newPassword , userEmail };
      const response = await changePassword(payload);
      if (response) {
          message.success("Password changed successfully.");
      }
      else{
          message.success("Failed to change Password.");
      }
    } catch (error) {
      message.error(error?.data?.message || "Failed to change password.");
    }
  };

  return (
    <DashboardLayout>
      <div className="w-100 mb-3 rounded p-2" style={{ background: "#f8f9fa" }}>
        <h5 className="text-title mt-3">Change Your Password</h5>
        <form
          onSubmit={handleSubmit}
          className="container row form-row px-5 mx-auto my-5"
        >
          <div className="col-md-12">
            <div className="form-group mb-3 card-label">
              <label>Old Password</label>
              <input
                type="password"
                placeholder="Old Password"
                className="form-control"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group mb-3 card-label">
              <label>New Password</label>
              <input
                type="password"
                placeholder="New Password"
                className="form-control"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group mb-2 card-label">
              <label>Confirm Password</label>
              <input
                type="password"
                placeholder="Confirm Password"
                className="form-control"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="mt-5 text-center">
            <Button
              htmlType="submit"
              type="primary"
              size="large"
              loading={isLoading}
            >
              Save Changes
            </Button>
          </div>
        </form>
      </div>
    </DashboardLayout>
  );
};

export default ChangePassword;
