import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  useCreateHospitalMutation,
  useGetHospitalIdFromUserIdQuery,
} from "../../../redux/api/hospitalApi";
import { message, DatePicker } from "antd";
import moment from "moment";
import ImageUpload from "../../UI/form/ImageUpload";
import "./AddHospital.css";
import AdminLayout from "../AdminLayout/AdminLayout";
import { getUserIdFromToken } from "../../../utils/local-storage";
import { getUserInfo } from "../../../service/auth.service";

const AddHospital = () => {
  const apiurl = process.env.REACT_APP_API_BASE_URL;
  const { register, handleSubmit, getValues } = useForm({});
  const [date, setDate] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [file, setFile] = useState(null);
  const [subdomainLoading, setSubdomainLoading] = useState(false);
  const [isSubdomainAvailable, setIsSubdomainAvailable] = useState(null);
  const [URL, setURL] = useState("");
  const domain = process.env.REACT_APP_DOMAIN;
  const [createHospital, { isLoading, error }] = useCreateHospitalMutation();
  const userData = getUserInfo();
  console.log("userData from AddDoctor", userData);
  const { data: hospitalIdData, error: hospitalError } =
    useGetHospitalIdFromUserIdQuery(userData.userId);
  console.log("hospitalIdData", hospitalIdData);
  const logFormData = (formData) => {
    for (const [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }
  };
  const onChange = (date, dateString) => {
    setDate(moment(dateString).format());
  };

  const onSubmit = async (data) => {
    if (hospitalIdData?.hospitalId) {
      message.error("You can create one maximum hospital");
      return;
    }
    if (isSubdomainAvailable === false) {
      message.error(
        "The subdomain is not available. Please choose a different name."
      );
      return;
    }
    else if (isSubdomainAvailable === null) {
      message.error(
        "Please Check Subdomain Availablity."
      );
      return;
    }
    const formData = new FormData();
    if (file) {
      formData.append("file", file);
    }
    if (date) {
      data.ESTD = date;
    }
    const userId = getUserIdFromToken();
    if (userId) {
      data.adminId = userId;
    }
    console.log("userId", userId);
    formData.append("data", JSON.stringify(data));

    try {
      console.log("data", data);

      logFormData(formData);

      const resp = await createHospital(formData);
      //   console.log("after");

      //   logFormData(formData);
      console.log("resp", resp);
      if (resp.data) {
        message.success("Facility added successfully");
        setURL(resp.data);
      }
    } catch (error) {
      message.error(error?.data?.message || "Failed to add Facility");
    }
  };
  // check domain availibility
  const checkSubdomainAvailability = async (name) => {
    setSubdomainLoading(true);
    setIsSubdomainAvailable(null);
    const formatedDomainName = name.replace(/[^a-zA-Z0-9]/g, "")
      .toLowerCase();

    try {
      console.log("Formatted Domain Name:", formatedDomainName);
      const fullUrl = `${apiurl}/hospital/check-subdomain`;

      const token = localStorage.getItem("accessToken");
      console.log("API URL and token:", fullUrl, token);
      const response = await fetch(fullUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify({ formatedDomainName }), // ensure the format matches backend expectations
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      console.log("Response Data:", data);

      if (data.success) {
        setIsSubdomainAvailable(true);
      } else {
        setIsSubdomainAvailable(false);
      }
    } catch (error) {
      console.error("Error during subdomain check:", error);
      message.error("Failed to check subdomain availability.");
    } finally {
      setSubdomainLoading(false);
    }
  };

  return (
    <AdminLayout>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Add Facility</h4>
              {URL ? (
                <>
                  {" "}
                  Facility Added Successfully
                  <p>
                    URL: {URL + "."}
                    {domain || "averyshub.com"}
                  </p>{" "}
                </>
              ) : (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-md-12 mb-4">
                      <div className="change-avatar d-flex gap-2 align-items-center">
                        <div className="hospital-img">
                          <img
                            src={
                              selectedImage || "https://via.placeholder.com/100"
                            }
                            alt="Hospital Avatar"
                            style={{
                              width: "100px",
                              height: "100px",
                              borderRadius: "50%",
                            }}
                          />
                        </div>
                        <div>
                          <ImageUpload
                            setSelectedImage={setSelectedImage}
                            setFile={setFile}
                          />
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label>
                          Hospital Name <span className="text-danger">*</span>
                        </label>
                        <input
                          {...register("name", { required: true })}
                          className="form-control"
                        />
                      </div>
                    </div> */}

                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label>
                        Facility Name <span className="text-danger">*</span>
                        </label>
                        <input
                          {...register("name", { required: true })}
                          className="form-control"
                        />
                      </div>

                      <div className="form-group mb-3">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() =>
                            checkSubdomainAvailability(getValues("name"))
                          }
                          disabled={subdomainLoading}
                        >
                          {subdomainLoading
                            ? "Checking..."
                            : isSubdomainAvailable === null
                            ? "Check Subdomain Availability"
                            : isSubdomainAvailable === true
                            ? "✓ Available"
                            : "✗ Not Available"}
                        </button>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label>
                          Address <span className="text-danger">*</span>
                        </label>
                        <input
                          {...register("address", { required: true })}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label>
                          City <span className="text-danger">*</span>
                        </label>
                        <input
                          {...register("city", { required: true })}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label>
                          State <span className="text-danger">*</span>
                        </label>
                        <input
                          {...register("state", { required: true })}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label>
                          ZipCode <span className="text-danger">*</span>
                        </label>
                        <input
                          {...register("zipCode", { required: true })}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label>
                          Country <span className="text-danger">*</span>
                        </label>
                        <input
                          {...register("country", { required: true })}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label>
                          Contact Number <span className="text-danger">*</span>
                        </label>
                        <input
                          {...register("phone", { required: true })}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label>
                          Email <span className="text-danger">*</span>
                        </label>
                        <input
                          {...register("email", { required: true })}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label>
                          Established Date{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <DatePicker
                          onChange={onChange}
                          format={"YYYY-MM-DD"}
                          required={true}
                          style={{ width: "100%" }}
                          value={date ? moment(date) : null}
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group mb-3">
                        <label>Description</label>
                        <textarea
                          {...register("description")}
                          className="form-control"
                          rows="4"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={isLoading}
                    >
                      {isLoading ? "Adding..." : "Add Facility"}
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AddHospital;
