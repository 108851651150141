import React, { useState, useEffect } from "react";
import AdminLayout from "../AdminLayout/AdminLayout";
import { useForm } from "react-hook-form";
import { message, DatePicker } from "antd";
import moment from "moment";
import ImageUpload from "../../UI/form/ImageUpload";
import "./Profile.css";
import { useUpdateAdminMutation } from "../../../redux/api/adminApi";
import { useGetAdminQuery } from "../../../redux/api/adminApi";
import { getUserIdFromToken } from "../../../utils/local-storage";
import { UserOutlined } from '@ant-design/icons';

const Profile = () => {
  const { register, handleSubmit } = useForm({});
  const [profile, setProfile] = useState({});
  const [date, setDate] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [file, setFile] = useState(null);
  const adminId = getUserIdFromToken();

  const { data, error, isLoading: isFetching } = useGetAdminQuery(adminId);
  const [updateAdmin, { isLoading: isUpdating }] = useUpdateAdminMutation();

  useEffect(() => {
    if (data) {
      console.log("data" ,data);
      
      setProfile(data);
      setDate(data.dateOfBirth ? moment(data.dateOfBirth) : null);
    }
  }, [data]);

  const onChange = (date, dateString) => {
    setDate(moment(dateString).format());
  };

  //   const onSubmit = async (formData) => {
  //     // Merge existing profile data with form data
  //     const updatedProfile = {
  //       ...profile,
  //       ...formData,
  //       dateOfBirth: date || profile.dateOfBirth,
  //     };

  //     const updatedData = new FormData();
  //     if (selectedImage) {
  //       updatedData.append("file", file);
  //     }
  //     updatedData.append("data", JSON.stringify(updatedProfile));
  //     updatedData.append("adminId", JSON.stringify(adminId));

  //     try {
  //       await updateAdmin(updatedData);
  //       message.success("Profile updated successfully");
  //     } catch (err) {
  //       message.error("Failed to update profile");
  //       console.error("Error updating profile:", err);
  //     }
  //   };

  // const onSubmit = async (formData) => {
  //     // Merge existing profile data with form data
  //     const updatedProfile = {
  //         ...profile,
  //         ...formData,
  //         dateOfBirth: date || profile.dateOfBirth,
  //     };

  //     const updatedData = new FormData();
  //     if (selectedImage) {
  //         updatedData.append("file", file);
  //     }
  //     // Serialize the data as JSON string before appending
  //     updatedData.append("data", JSON.stringify(updatedProfile));
  //     updatedData.append("adminId", adminId);
  //     const logFormData = (formData) => {
  //         for (const [key, value] of formData.entries()) {
  //           console.log(`${key}:`, value);
  //         }
  //       };
  //       logFormData(updatedData)
  //     try {
  //         await updateAdmin(updatedData);
  //         message.success("Profile updated successfully");
  //     } catch (err) {
  //         message.error("Failed to update profile");
  //         console.error("Error updating profile:", err);
  //     }
  // };

  const onSubmit = async (formData) => {
    // Merge existing profile data with form data
    const updatedProfile = {
      ...profile,
      ...formData,
      dateOfBirth: date || profile.dateOfBirth,
    };

    const updatedData = new FormData();
    if (selectedImage) {
      updatedData.append("file", file);
    }

    updatedData.append("data", JSON.stringify(updatedProfile));

    updatedData.append("adminId", adminId);

    const logFormData = (formData) => {
      for (const [key, value] of formData.entries()) {
        console.log(`${key}:`, value);
      }
    };

    logFormData(updatedData);
    const requiredFields = [
      'firstName', 'lastName', 'city', 'state', 'country'
    ];

    const missingFields = requiredFields.filter(field =>
      updatedProfile[field] === undefined || updatedProfile[field] === ''
    );

    if (missingFields.length > 0) {
      message.error(`The following fields are required: ${missingFields.join(', ')}`);
      return;
    }
    try {
      await updateAdmin(updatedData);
      message.success("Profile updated successfully");
    } catch (err) {
      message.error("Failed to update profile");
      console.error("Error updating profile:", err);
    }
  };

  if (isFetching) {
    return <AdminLayout>
      Loading...
    </AdminLayout>;
  }

  if (error) {
    return <div>Error loading profile data</div>;
  }

  return (
    <AdminLayout>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Admin Profile</h4>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-md-12 mb-4">
                    <div className="change-avatar flex justify-around gap-2 ">
                      <div className="admin-img-container profile-img">
                        {(selectedImage || profile.img) ? (
                          <img
                            src={selectedImage || profile.img}
                            alt="Admin Avatar"
                            className="admin-img"
                          />
                        ) : (
                          <div className="admin-img admin-img-placeholder">
                            <UserOutlined style={{ fontSize: '60px', color: '#555' }} />
                          </div>
                        )}
                      </div>

                      <div className="mx-4">
                        <ImageUpload
                          setSelectedImage={setSelectedImage}
                          setFile={setFile}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>
                        First Name <span className="text-danger">*</span>
                      </label>
                      <input
                        defaultValue={profile.firstName || ""}
                        {...register("firstName", { required: true })}
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>
                        Last Name <span className="text-danger">*</span>
                      </label>
                      <input
                        defaultValue={profile.lastName || ""}
                        {...register("lastName", { required: true })}
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>
                        Email <span className="text-danger">*</span>
                      </label>
                      <input
                        defaultValue={profile.email || ""}
                        className="form-control"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>
                        Phone No <span className="text-danger">*</span>
                      </label>
                      <input
                        defaultValue={profile.phone || ""}
                        className="form-control"
                        disabled
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>Date of Birth</label>
                      <DatePicker
                        onChange={onChange}
                        format={"YYYY-MM-DD"}
                        style={{ width: "100%" }}
                        value={date ? moment(date) : null}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>City</label>
                      <input
                        defaultValue={profile.city || ""}
                        {...register("city")}
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>State</label>
                      <input
                        defaultValue={profile.state || ""}
                        {...register("state")}
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>Country</label>
                      <input
                        defaultValue={profile.country || ""}
                        {...register("country")}
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={isUpdating}
                    >
                      {isUpdating ? "Updating..." : "Save Changes"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default Profile;
