import React from 'react'
import { Slider, Button, Radio, Input } from 'antd';
import { FaRedoAlt } from "react-icons/fa";
import { doctorSpecialistOptions } from '../../../constant/global';

const SearchSidebar = ({
  setSearchTerm,
  setSorByGender,
  setSpecialist,
  setPriceRange,
  resetFilter,
  query,
}) => {
  const options = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
  ];

  const onSelectGender = (e) => setSorByGender(e.target.value)
  const onSelectSpecialist = (e) => setSpecialist(e.target.value)
  const onRangeChange = (range) => {
    const obj = {
      min: range[0],
      max: range[1]
    }
    setPriceRange(obj)
  }

  const onSearch = (e) => {
    setSearchTerm(e.target.value);
  }

  return (
    <div className="col-md-12 col-lg-4 col-xl-3">
      <div className="p-3 rounded" style={{ background: '#f3f3f3' }}>
        <h5 className='text-center mb-3' style={{ color: '#05335c' }}>Provider Filter</h5>
        <div className="mb-3">
          <Input 
            placeholder="Search..." 
            onChange={onSearch} 
            allowClear 
          />
        </div>
        <div className='mb-3'>
          <h6 style={{ color: '#05335c' }}>Gender</h6>
          <div className='d-flex flex-column'>
            <Radio.Group options={options} onChange={onSelectGender} />
          </div>
        </div>
        <div className='mb-3'>
          <h6 style={{ color: '#05335c' }}>Price Range</h6>
          <Slider range defaultValue={[0, 150]} onChange={onRangeChange} />
        </div>
        <div className='mb-3'>
          <h6 style={{ color: '#05335c' }}>Select Specialist</h6>
          <div className='d-flex flex-column'>
            <Radio.Group options={doctorSpecialistOptions} onChange={onSelectSpecialist} />
          </div>
        </div>
        {Object.keys(query).length > 4 && 
          <Button 
            className='w-100 mt-4 mb-2' 
            style={{ backgroundColor: '#1977cc' }} 
            onClick={resetFilter} 
            type="primary" 
            shape="round" 
            icon={<FaRedoAlt />} 
            size="sm"
          >
            Reset
          </Button>
        }
      </div>
    </div>
  )
}

export default SearchSidebar