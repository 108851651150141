import React, { useState } from "react";
// import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
// import { storage } from "../firebase/firebase";
import axios from "axios";
import { Image, Transformation } from 'cloudinary-react';
import { useNavigate } from "react-router-dom";
import {
    MDXEditor,
    toolbarPlugin,
    codeBlockPlugin,
    codeMirrorPlugin,
    KitchenSinkToolbar,
    listsPlugin,
    quotePlugin,
    headingsPlugin,
    linkPlugin,
    linkDialogPlugin,
    imagePlugin,
    tablePlugin,
    thematicBreakPlugin,
    frontmatterPlugin,
    directivesPlugin,
    AdmonitionDirectiveDescriptor,
    diffSourcePlugin,
    markdownShortcutPlugin,
} from "@mdxeditor/editor";
import "@mdxeditor/editor/style.css";

export function Editor({value, handleChange}) {
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState('');
    const cloud_name = process.env.REACT_APP_CLOUD_NAME;
    const upload_preset = process.env.REACT_APP_UPLOAD_PRESET;
    const url = `https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`;

    async function handleUpload(file) {
        if (!file) return; // Add a check to ensure there's a file
        const formData = new FormData();
        formData.append('file', file);
        formData.append('upload_preset', upload_preset);
        formData.append('cloud_name', cloud_name);
        try {

            const response = await fetch(
                url,
                {
                    method: 'POST',
                    body: formData,
                }
            );
            const data = await response.json();
            setImage(data.secure_url);
            return data.secure_url;
        }
        catch (error) {
            console.error("Error uploading file:", error);
        }
    }

    const allPlugins = (diffMarkdown) => [
        toolbarPlugin({ toolbarContents: () => <KitchenSinkToolbar /> }),
        listsPlugin(),
        quotePlugin(),
        headingsPlugin(),
        linkPlugin(),
        linkDialogPlugin(),
        imagePlugin({ imageUploadHandler: handleUpload }),
        tablePlugin(),
        thematicBreakPlugin(),
        frontmatterPlugin(),
        codeBlockPlugin({ defaultCodeBlockLanguage: "txt" }),
        codeMirrorPlugin({
            codeBlockLanguages: {
                js: "JavaScript",
                css: "CSS",
                txt: "text",
                tsx: "TypeScript",
            },
        }),
        directivesPlugin({ directiveDescriptors: [AdmonitionDirectiveDescriptor] }),
        diffSourcePlugin({ viewMode: "rich-text", diffMarkdown }),
        markdownShortcutPlugin(),
    ];

    
    const titleMatch = value.match(/^# (.+)$/m);
    const navigate = useNavigate();
    const title = titleMatch ? titleMatch[1] : "Untitled Blog";

    //   async function handlePublish() {
    //     const api_url = import.meta.env.VITE_REACT_APP_API_URL;

    //     const token = Cookies.get("token");

    //     const headers = {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + token,
    //     };

    //     const data = {
    //       title,
    //       content: value,
    //       published: true,
    //     };
    //     axios
    //       .post(`${api_url}/api/v1/blog/`, data, {
    //         headers: headers,
    //       })
    //       .then((response) => {
    //         alert(response.data.message);
    //         navigate(`/blog/${response.data.id}`);
    //       })
    //       .catch((error) => {
    //         console.log("Error : " + error);
    //       });
    //   }
    return (
        <div>
            <MDXEditor
                markdown={value}
                onChange={handleChange}
                className="full-demo-mdxeditor w-[80vw] mx-auto relative top-24"
                contentEditableClassName="prose max-w-full font-sans"
                plugins={allPlugins("# Hello World")}
            />
        </div>
    );
}