import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import log from "../../images/doc/info.svg";
import register from "../../images/doc/register.svg";
import "./SignInForm.css";
import HospitalSignUp from "./HospitalSignUp";
import HospitalSignIn from "./HospitalSignIn";
import useAuthCheck from "../../redux/hooks/useAuthCheck";
import { getUserInfo } from "../../service/auth.service";
import UserTypeSelection from "./UserTypeSelection";
const HospitalSignInForm = () => {
  const { id } = useParams();
  const data = useAuthCheck();
  const user = getUserInfo();
  const navigate = useNavigate();
  console.log("useAuthCheck data", data);
  const [isSignUp, setSignUp] = useState(false);
  const [userType, setUserType] = useState("");
  useEffect(() => {
    if (
      (data && data?.role === "patient") ||
      (user && user.role === "patient")
    ) {
      console.log("inside id", id);

      navigate(`/dashboard/${id}`);
    } else if (
      (data && data?.role === "doctor") ||
      (user && user.role === "doctor")
    ) {
      navigate(`/doctor/dashboard/${id}`);
    }
  }, []);
  const handleUserTypeSelection = (userType) => {
    // Handle the selection logic here
    setUserType(userType);
    console.log(`Selected user type: ${userType}`);
  };

  return (
    <>
      {" "}
      {!userType ? (
        <UserTypeSelection
          className="z-[500]"
          onSelectUserType={handleUserTypeSelection}
        />
      ) : (
        <div
          className={`${
            isSignUp
              ? "signin-signup-container sign-up-mode"
              : "signin-signup-container"
          }`}
        >
          {/* <Link to="/">
            <span className="pageCloseBtn">
              <FaTimes />
            </span>
          </Link> */}
          <div className="forms-container">
            <div className="signIn-singUp">
              <HospitalSignIn userType={userType} />
              <HospitalSignUp setSignUp={setSignUp} />
            </div>
          </div>

          <div className="panels-container">
            <div className="panel left-panel">
              {userType === "patient" && (
                <div className="content">
                  <h3 className="text-white">New here?</h3>
                  <p>
                    Join us today and connect with a network of trusted
                    healthcare professionals.
                  </p>
                  <button
                    className="iBtn transparent"
                    onClick={() => setSignUp(true)}
                  >
                    Sign Up
                  </button>
                </div>
              )}
              <img src={`${log}`} alt="" className="pImg" />
            </div>

            <div className="panel right-panel">
              <div className="content">
                <h3 className="text-white">One of us ?</h3>
                <p>
                  Welcome back! Sign in to continue accessing your hospital's
                  dashboard and services.
                </p>
                <button
                  className="iBtn transparent"
                  onClick={() => setSignUp(false)}
                >
                  Sign In
                </button>
              </div>
              <img src={`${register}`} alt="" className="pImg" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HospitalSignInForm;
