import React, { useEffect, useState } from "react";
import Footer from "../../Shared/Footer/Footer";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Empty, Button, message, Steps } from "antd";
import { useGetDoctorQuery } from "../../../redux/api/doctorApi";
import { useGetAppointmentTimeQuery } from "../../../redux/api/timeSlotApi";
import moment from "moment-timezone";
import SelectDateAndTime from "../SelectDateAndTime";
import PersonalInformation from "../PersonalInformation";
import CheckoutPage from "../BookingCheckout/CheckoutPage";
import { useCreateAppointmentMutation, useGetDoctorAppointmentsByIdQuery } from "../../../redux/api/appointmentApi";
import { useDispatch } from "react-redux";
import { addInvoice } from "../../../redux/feature/invoiceSlice";
import Header from "../../Shared/Header/Header";
import useAuthCheck from "../../../redux/hooks/useAuthCheck";
import { Elements } from "@stripe/react-stripe-js";
import getStripe from "../../../utils/getStripe";
import PaymentInfoComponent from "../PaymentInfoComponent/PaymentInfoComponent";

const DoctorBooking = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const stripePromise = getStripe();
  const { data: loggedInUser, role } = useAuthCheck();
  const [current, setCurrent] = useState(0);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectDay, setSelectDay] = useState("");
  const [selectTime, setSelectTime] = useState("");
  const [isCheck, setIsChecked] = useState(false);
  const [patientId, setPatientId] = useState("");
  const [paymentInfo, setPaymentInfo] = useState(null);
  const [searchParams] = useSearchParams();
  const solutionId = searchParams.get('sId');
  const [solution, setSolution] = useState(null);
  const [solutionPrice, setSolutionPrice] = useState(null);
  
  let initialValue = {
    paymentMethod: "stripe",
    paymentType: "creditCard",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    reasonForVisit: "",
    description: "",
    address: "",
    paymentAddress: "",
    paymentCountry: "",
    paymentCountryCode: "",
    paymentEmail: "",
    paymentName: "",
    paymentState: "",
    paymentPostalCode: "",
  };

  const [selectValue, setSelectValue] = useState(initialValue);
  const [isDisable, setIsDisable] = useState(true);
  const [isConfirmDisable, setIsConfirmDisable] = useState(true);
  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  const [
    createAppointment,
    {
      data: appointmentData,
      isSuccess: createIsSuccess,
      isError: createIsError,
      error: createError,
    },
  ] = useCreateAppointmentMutation();

  const { doctorId } = useParams();
  const navigation = useNavigate();
  const { data, isLoading, isError, error } = useGetDoctorQuery(doctorId);

  const handleChange = (e) => {
    if (e.target.name === "paymentCountry") {
      setSelectValue({
        ...selectValue,
        [e.target.name]: e.target.value.label,
        paymentCountryCode: e.target.value.value,
      });
    } else {
      setSelectValue({ ...selectValue, [e.target.name]: e.target.value });
    }
  };

  const [localTimes, setLocalTimes] = useState([]);

  const {
    data: time,
    refetch,
    isLoading: dIsLoading,
    isError: dIsError,
  } = useGetAppointmentTimeQuery({ day: selectDay, id: doctorId });

  const { data: appointments, isLoading: appntLoading, isError: appntError } = useGetDoctorAppointmentsByIdQuery(doctorId);

  useEffect(() => {
    if (time && time.length > 0) {
      const convertedTimes = time.map((item) => {
        const utcTime = `${item.slot.time}`;
        const utcTime2 = moment.utc(`${selectDay} ${item.slot.time}`);
        const localTime = utcTime2.local();
        return {
          ...item,
          localTime: utcTime,
        };
      });
      setLocalTimes(convertedTimes);
    }
  }, [time, selectDay]);

  useEffect(() => {
    if (solutionId) {
      fetch(`${apiUrl}/solution/solutionId/${solutionId}`)
        .then(response => response.json())
        .then(data => {
          if (data.response && data.response.price) {
            setSolution(data.response);
            setSolutionPrice(data.response.price);
          }
        })
        .catch(error => {
          console.error("Error fetching solution price:", error);
        });
    }
  }, [solutionId]);

  const handleDateChange = (date) => {
    const selected = moment(date).local();
    const formattedDate = selected.format("YYYY-MM-DD");
    setSelectedDate(selected.toDate());
    setSelectDay(formattedDate);
    refetch();
  };

  const handleSelectTime = (utcTime) => {
    setSelectTime(utcTime);
  };

  useEffect(() => {
    const {
      firstName,
      lastName,
      email,
      phone,
      reasonForVisit,
      paymentAddress,
      paymentCountry,
      paymentCountryCode,
      paymentEmail,
      paymentName,
      paymentState,
      paymentPostalCode,
    } = selectValue;

    const isInputEmpty =
      (current === 1 &&
        (!firstName || !lastName || !email || !phone || !reasonForVisit)) ||
      (current === 2 &&
        (!paymentAddress ||
          !paymentCountry ||
          !paymentCountryCode ||
          !paymentEmail ||
          !paymentName ||
          !paymentState ||
          !paymentPostalCode));

    setIsDisable(isInputEmpty);
    setIsConfirmDisable(current === 2 && isInputEmpty);
  }, [selectValue, current]);

  const isTimeSlotBooked = (timeSlot) => {
    return appointments?.some(
      (appointment) =>
        moment(appointment.scheduleDate).format("YYYY-MM-DD") === selectDay &&
        appointment.scheduleTime === timeSlot
    );
  };

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const handlePaymentSuccess = (paymentData) => {
    const obj = {
      patientInfo: {
        firstName: selectValue.firstName,
        lastName: selectValue.lastName,
        email: selectValue.email,
        phone: selectValue.phone,
        scheduleDate: selectedDate,
        scheduleTime: selectTime,
        doctorId: doctorId,
        hospitalId: loggedInUser.hospitalId,
        patientId: loggedInUser.id,
      },
      payment: {
        paymentType: paymentData.paymentType,
        paymentMethod: paymentData.paymentMethod,
        paymentDate: paymentData.paymentDate,
        paymentStatus: paymentData.paymentStatus,
        currency: paymentData.currency,
        transactionId: paymentData.transactionId,
        totalAmount: solutionPrice || paymentData.totalAmount,
        doctorFee: solutionPrice || paymentData.doctorFee,
        bookingFee: paymentData.bookingFee,
        vat: paymentData.vat,
        paymentIntentId: paymentData.paymentIntentId,
      },
    };

    setPaymentInfo(paymentData);
    createAppointment(obj);
  };

  let dContent = null;
  if (dIsLoading) dContent = <div>Loading ...</div>;
  if (!dIsLoading && dIsError) dContent = <div>Something went Wrong!</div>;
  if (!dIsLoading && !dIsError && time?.length === 0)
    dContent = <Empty description="Provider Is not Available" />;

  if (!dIsLoading && !dIsError && time?.length > 0)
    dContent = (
      <>
        {time
          .filter((item) => !isTimeSlotBooked(item.slot.time))
          .map((item, id) => (
            <div className="col-md-4" key={id + 155}>
              <Button
                type={item?.slot?.time === selectTime ? "primary" : "default"}
                shape="round"
                size="large"
                className="mb-3"
                onClick={() => handleSelectTime(item?.slot?.time)}
              >
                {item?.slot?.time}
              </Button>
            </div>
          ))}
      </>
    );

  let content = null;
  if (!isLoading && isError) content = <div>Something Went Wrong!</div>;
  if (!isLoading && !isError && !data) content = <Empty />;
  if (!isLoading && !isError && data?.id)
    content = (
      <>
        <div className="booking-doc-img my-3 mb-3 rounded">
          <Link to={`/doctors/${data?.id}`}>
            <img
              src="https://www.svgrepo.com/show/429791/doctor-human.svg"
              alt="provider-img"
            />
          </Link>
          <div className="text-start">
            <Link
              to={`/doctors/${data?.id}`}
              style={{ textDecoration: "none" }}
            >
              Dr. {data?.firstName + " " + data?.lastName}
            </Link>
          </div>
        </div>
      </>
    );

  const steps = [
    {
      title: "Select Appointment Date & Time",
      content: (
        <SelectDateAndTime
          content={content}
          handleDateChange={handleDateChange}
          selectedDate={selectedDate}
          dContent={dContent}
          selectTime={selectTime}
          handleTimeChange={handleSelectTime}
        />
      ),
    },
    {
      title: "Patient Information",
      content: (
        <PersonalInformation
          handleChange={handleChange}
          selectValue={selectValue}
          setPatientId={setPatientId}
        />
      ),
    },
    {
      title: "Payment Info",
      content: (
        <PaymentInfoComponent
          handleChange={handleChange}
          selectValue={selectValue}
        />
      ),
    },
    {
      title: "Card Info",
      content: (
        <CheckoutPage
          isCheck={isCheck}
          setIsChecked={setIsChecked}
          data={data}
          selectedDate={selectedDate}
          selectTime={selectTime}
          onPaymentSuccess={handlePaymentSuccess}
          selectValue={selectValue}
          solutionPrice={solutionPrice}
        />
      ),
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  useEffect(() => {
    if (createIsSuccess && appointmentData && appointmentData.id) {
      message.success("Successfully Appointment Scheduled");
      setSelectValue(initialValue);
      dispatch(addInvoice({ ...appointmentData }));
      if (appointmentData && appointmentData.id) {
        navigation(`/booking/success/${id}/${appointmentData.id}`);
      } else {
        message.error("failed to create appointment");
      }
    }
    if (createIsError) {
      message.error(createError?.data?.message);
    }
  }, [
    createIsSuccess,
    createIsError,
    createError,
    appointmentData,
    dispatch,
    id,
    navigation,
  ]);

  return (
    <>
      <Elements stripe={stripePromise}>
        <Header />
        <div
          className="container"
          style={{ marginBottom: "12rem", marginTop: "8rem" }}
        >
          <Steps current={current} items={items} />
          <div className="mb-5 mt-3 mx-3">{steps[current].content}</div>
          <div className="text-end mx-3">
            <div className="text-end mx-3">
              {current < steps.length - 1 && (
                <Button
                  type="primary"
                  disabled={
                    current === 0
                      ? !selectTime
                      : current === 1
                        ? isDisable
                        : current === 2
                          ? isDisable || !selectTime
                          : false
                  }
                  onClick={() => next()}
                >
                  Next
                </Button>
              )}
              {current > 0 && (
                <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
                  Previous
                </Button>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </Elements>
    </>
  );
};

export default DoctorBooking;