import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Country, State } from "country-state-city";

const CountryStateSelector = ({ handleChange }) => {
  const [PaymentCountries, setPaymentCountries] = useState([]);
  const [PaymentStates, setPaymentStates] = useState([]);
  const [selectedPaymentCountry, setSelectedPaymentCountry] = useState(null);
  const [selectedPaymentState, setSelectedPaymentState] = useState(null);

  useEffect(() => {
    // Fetch country data using country-state-city library
    const countryOptions = Country.getAllCountries().map((country) => ({
      value: country.isoCode,
      label: country.name,
    }));
    setPaymentCountries(countryOptions);
  }, []);

  useEffect(() => {
    if (selectedPaymentCountry) {
      // Fetch states data based on the selected country using country-state-city library
      const stateOptions = State.getStatesOfCountry(
        selectedPaymentCountry.value
      ).map((state) => ({
        value: state.isoCode,
        label: state.name,
      }));
      setPaymentStates(stateOptions);
    }
  }, [selectedPaymentCountry]);

  const handleCountryChange = (selectedOption) => {
    setSelectedPaymentCountry(selectedOption);
    setSelectedPaymentState(null);
    console.log("selectedOption.label", selectedOption.label);

    // onChange({ country: selectedOption, state: null });
    // handleChange({
    //     target: { name: "paymentCountryCode", value: selectedOption.value || "" },
    // });
    handleChange({
      target: { name: "paymentCountry", value: selectedOption || "" },
    });
  };

  const handleStateChange = (selectedOption) => {
    setSelectedPaymentState(selectedOption);
    console.log("for state ", selectedOption);
    
    // onChange({ country: selectedPaymentCountry, state: selectedOption });
    handleChange({
      target: {
        name: "paymentState",
        value: selectedOption.label || "",
      },
    });
  };

  return (
    <div style={{ paddingBottom: "20px", display: "flex", flexDirection: "column", gap: "10px" }}>
    <label>Country</label>
    <Select
      options={PaymentCountries}
      value={selectedPaymentCountry}
      onChange={handleCountryChange}
      placeholder="Select Country"
    />
    <label>State</label>
    <Select
      options={PaymentStates}
      value={selectedPaymentState}
      onChange={handleStateChange}
      placeholder="Select State"
      isDisabled={!selectedPaymentCountry}
    />
  </div>
  );
};

export default CountryStateSelector;
