import React from "react";
import AdminLayout from "../AdminLayout/AdminLayout";
import { getUserIdFromToken } from "../../../utils/local-storage";
import { useGetAdminInvoicesQuery } from "../../../redux/api/appointmentApi";
import { Table, Tag, Card, Typography, Spin, Alert } from 'antd';
import moment from 'moment';
import { useGetAllPaymentsQuery } from "../../../redux/api/paymentApi";

const { Title } = Typography;

const Payments = () => {
    const userId = getUserIdFromToken();
    const {
        data: paymentsData,
        isLoading,
        error
    } = useGetAdminInvoicesQuery(userId);

    // const {
    //     data: paymentsData,
    //     isLoading,
    //     error
    // } = useGetAllPaymentsQuery();

    // console.log("paymentsData" , paymentsData);


    const columns = [
        {
            title: 'Transaction ID',
            dataIndex: 'transactionId',
            key: 'transactionId',
        },
        {
            title: 'Patient Name',
            dataIndex: ['appointment', 'patient', 'firstName'],
            key: 'patientName',
            render: (firstName, record) => {
                const patient = record.appointment?.patient;
                return patient ? `${patient.firstName || ''} ${patient.lastName || ''}` : 'N/A';
            },
        },
        // {
        //     title: 'Doctor Name',
        //     dataIndex: ['appointment', 'doctor', 'firstName'],
        //     key: 'doctorName',
        //     render: (firstName, record) => {
        //         console.log(firstName);
        //         console.log(record);
        //         const doctor = record.appointment?.doctor;
        //         return doctor ? `Dr. ${doctor.firstName || ''} ${doctor.lastName || ''}` : 'N/A';
        //     },
        // },
        {
            title: 'Amount',
            dataIndex: 'totalAmount',
            key: 'amount',
            render: (amount, record) => `${(record.currency || '').toUpperCase()} ${amount/100 || 0}`,
        },
        {
            title: 'Payment Date',
            dataIndex: 'paymentDate',
            key: 'paymentDate',
            render: (date) => date ? moment(date).format('MMMM D, YYYY') : 'N/A',
        },
        {
            title: 'Payment Method',
            dataIndex: 'paymentMethod',
            key: 'paymentMethod',
            render: (method) => method ? method.charAt(0).toUpperCase() + method.slice(1) : 'N/A',
        },
        {
            title: 'Status',
            dataIndex: 'paymentStatus',
            key: 'status',
            render: (status) => (
                <Tag color={status === 'paid' ? 'green' : 'volcano'}>
                    {(status || 'UNKNOWN').toUpperCase()}
                </Tag>
            ),
        },
    ];

    if (error) {
        return (
            <AdminLayout>
                <Alert
                    message="Error"
                    description="Failed to load payment data. Please try again later."
                    type="error"
                    showIcon
                />
            </AdminLayout>
        );
    }

    return (
        <AdminLayout>
            <Card>
                <Title level={2}>Transaction History</Title>
                <Table
                    columns={columns}
                    dataSource={paymentsData || []}
                    rowKey="id"
                    loading={isLoading}
                    pagination={{
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                    }}
                />
            </Card>
        </AdminLayout>
    );
};

export default Payments;