import { Outlet, useNavigate, useParams } from "react-router-dom";
import { getUserInfo, loggedOut } from "../../service/auth.service";
import { useEffect, useState } from "react";
import { message, Spin } from "antd";

const PrivateOutletPatient = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [localAuth, setLocalAuth] = useState(null);

  useEffect(() => {
    const fetchUserInfo = async () => {
      const userInfo = await getUserInfo();  // Assuming getUserInfo is async
      setLocalAuth(userInfo);
    };

    fetchUserInfo();
  }, []);

  useEffect(() => {
    if (localAuth === null) {
      return; // Wait until localAuth is set
    }

    console.log("localAuth from patient", id, localAuth, localAuth?.role);

    if (!localAuth) {
      navigate(`/hospital/${id}`, { replace: true });
      return;
    }

    if (localAuth.role !== "patient") {
      navigate(`/hospital/${id}`, { replace: true });
      return;
    }

    if (localAuth.hospitalId === "HOSPITALID_NOT_NEEDED_FOR_ADMIN") {
      message.error("Admin cannot access this route");
      navigate(`/error`, { replace: true });
      return;
    }

    if (id !== localAuth.hospitalId) {
      message.error("Wrong Hospital URL");
      loggedOut();
      navigate(`/error`, { replace: true });
      return;
    }
  }, [id, localAuth, navigate]);

  if (localAuth === null) {
    return <div><Spin size="large" /></div>; 
  }

  return <Outlet />;
};

export default PrivateOutletPatient;
