import React from 'react';
import './UserTypeSelection.css';

const UserTypeSelection = ({ onSelectUserType }) => {
  return (
    <div className="user-type-selection-container">
      <div className="user-type-selection-content">
        <h1 className="user-type-selection-title">Choose Your Role</h1>
        <p className="user-type-selection-subtitle">Select how you'd like to use our platform</p>
        <div className="user-type-selection-options">
          <div className="user-type-option" onClick={() => onSelectUserType("doctor")}>
            <div className="user-type-image doctor-image"></div>
            <h2>I'm a Provider</h2>
            <p>Provide care and manage your practice</p>
          </div>
          <div className="user-type-option" onClick={() => onSelectUserType("patient")}>
            <div className="user-type-image patient-image"></div>
            <h2>I'm a Patient</h2>
            <p>Find care and manage your health</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserTypeSelection;