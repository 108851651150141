import React, { useEffect } from 'react';
import Footer from '../Shared/Footer/Footer';
import { useForm } from 'react-hook-form';
import { FaLocationArrow, FaEnvelope, FaPhoneAlt } from "react-icons/fa";
import Header from '../Shared/Header/Header';
import './index.css';
import SubHeader from '../Shared/SubHeader';
import { useContactMutation } from '../../redux/api/contactApi';
import { message } from 'antd';

const Contact = () => {
    const [contact, { isLoading, isError, error, isSuccess }] = useContactMutation();
    const { register, handleSubmit, reset } = useForm({});

    const onSubmit = (data) => {
        contact(data);
        reset();
    };

    useEffect(() => {
        if (isSuccess) {
            message.success("Message sent successfully!");
        }
        if (isError && error) {
            message.error(error?.data?.message || 'Something went wrong!');
        }
    }, [isSuccess, isError, error]);

    return (
        <>
            <Header />
            <SubHeader title="Contact Us" subtitle="We'd love to hear from you!" />
            <section id="contact" className="contact mt-5 mb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="info rounded p-3">
                                <div className="d-flex mb-2 gap-2">
                                    <FaLocationArrow className='icon' />
                                    <div>
                                        <h4>Location:</h4>
                                        <p>Victoria Memorial Hall, 1, Queens Way, Maidan, Kolkata, West Bengal 700071</p>
                                    </div>
                                </div>
                                <div className="d-flex mb-2 gap-2">
                                    <FaEnvelope className='icon' />
                                    <div>
                                        <h4>Email:</h4>
                                        <p>sayanmajumder0002@gmail.com</p>
                                    </div>
                                </div>
                                <div className="d-flex mb-2 gap-2">
                                    <FaPhoneAlt className='icon' />
                                    <div>
                                        <h4>Call:</h4>
                                        <p>+91 9875699506</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="mb-5 p-2 rounded">
                                <form className="row form-row" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="col-md-6">
                                        <div className="form-group mb-2 card-label">
                                            <label htmlFor="firstName">First Name</label>
                                            <input id="firstName" required {...register("firstName")} className="form-control" placeholder='First Name' />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-2 card-label">
                                            <label htmlFor="lastName">Last Name</label>
                                            <input id="lastName" required {...register("lastName")} className="form-control" placeholder='Last Name' />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-2 card-label">
                                            <label htmlFor="email">Email</label>
                                            <input id="email" required {...register("email")} type='email' className="form-control" placeholder="Email" />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-2 card-label">
                                            <label htmlFor="subject">Subject</label>
                                            <input id="subject" required {...register("subject")} className="form-control" placeholder="Enter your subject" />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor="message">Message</label>
                                            <textarea id="message" required {...register("text")} className="form-control mb-3" cols="30" rows="10" placeholder="Enter your message" />
                                        </div>
                                    </div>
                                    <div className="text-center mt-3 mb-5">
                                        <button disabled={isLoading} type='submit' className="appointment-btn">Send Message</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <iframe
                        style={{ border: 0, width: "100%", height: "350px" }}
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621"
                        frameBorder="0"
                        allowFullScreen
                        title="Google Maps"
                    ></iframe>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default Contact;
