import React from "react";
import AdminLayout from "../AdminLayout/AdminLayout";
import { useGetHospitalIdFromUserIdQuery } from "../../../redux/api/hospitalApi";
import { useGetAllPatientsQuery } from "../../../redux/api/patientApi";
import { getUserIdFromToken } from "../../../utils/local-storage";
import { Card, Table, Avatar, Tag, Spin } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const Patients = () => {
  const userId = getUserIdFromToken();
  const { data: hospitalData } = useGetHospitalIdFromUserIdQuery(userId);
  const { data: patientsData, isLoading, error } = useGetAllPatientsQuery(hospitalData?.id);

  if (isLoading) return<AdminLayout><Spin size="large" /></AdminLayout> ;
  if (error) return <div>Error: {error.message}</div>;

  const columns = [
    {
      title: 'Name',
      dataIndex: 'firstName',
      key: 'name',
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar icon={<UserOutlined />} src={record.img} />
          <span style={{ marginLeft: 8 }}>{`${record.firstName} ${record.lastName}`}</span>
        </div>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Blood Group',
      dataIndex: 'bloodGroup',
      key: 'bloodGroup',
      render: (bloodGroup) => bloodGroup || 'N/A',
    },
    {
      title: 'Status',
      key: 'status',
      render: () => (
        <Tag color="green">Active</Tag>
      ),
    },
  ];

  return (
    <AdminLayout>
      <Card title="Patients List" bordered={false}>
        <Table 
          columns={columns} 
          dataSource={patientsData} 
          rowKey="id"
          pagination={{ pageSize: 10 }}
        />
      </Card>
    </AdminLayout>
  );
};

export default Patients;