import { Outlet, useNavigate } from "react-router-dom";
import { getUserInfo } from "../../service/auth.service";
import { useEffect, useState } from "react";

const PrivateOutletAdmin = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserInfo = async () => {
      const localAuth = await getUserInfo();
      console.log("localAuth", localAuth);

      if (!localAuth) {
        navigate(`/admin/login`, { replace: true });
        return;
      }

      if (localAuth.role !== "admin") {
        navigate(`/admin/login`, { replace: true });
      }

      setLoading(false);
    };

    fetchUserInfo();
  }, [navigate]);

  if (loading) {
    return <div>Loading...</div>; // Optional loading state
  }

  return <Outlet />;
};

export default PrivateOutletAdmin;
